import React from 'react'
import './Section2.scss'
import { FaChevronRight } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
const lines = '/assets/redesign/images/lineas.png';
const chica = '/assets/images/aula-virtual-espacio-estudio.png';

export const Section2 = () => {
    const navigate = useNavigate();
    const navigateTo = () => {
        navigate('/academia')
    }
  return (
    <div className='section-two'>
        <div className='main-container'>
            <div className='left-side'>
                <h2>
                    CLASES DE <br className='br-norm'/>INGLÉS Y <br className='br-resp'/>FRANCÉS
                </h2>
                <p>¡Despierta tu potencial bilingüe ahora!</p>
                <button className='section-2' onClick={navigateTo}><p>Ver más</p><FaChevronRight/></button>
            </div>
            <img src={lines} className='lines' alt='lineas'/>
            <div className='right-side'>
                <img src={chica} className='girl' alt='chica-virtual'/>
            </div>
        </div>
    </div>
  )
}
