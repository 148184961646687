import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./SuccessModal.scss";

interface SuccessModalProps {
    success: boolean;
}
export function SuccessModal(props: SuccessModalProps) {
    const navigate = useNavigate();

    const action = () => {
        navigate("/academia")
    }
    return (
        <Modal
            className="success-modal-component"
            show={props.success}
            size="lg"
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header>
                <Modal.Title>Tu pago ha sido exitoso!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Bienvenido a mi academia!</p>
            </Modal.Body>
            <Modal.Footer>
                <button className="continue" onClick={action}>Continuar</button>
            </Modal.Footer>
        </Modal>
    )
}