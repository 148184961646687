import { createContext, useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase/FirebaseConfig";
import { Spinner } from "../components/spinner/Spinner";

export const UserContext = createContext<any>(null);

export const AuthContext = ({ children }: any) => {
	const [user, setUser] = useState<any>(null);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	useEffect(() => {
		const auth = getAuth();
		return onAuthStateChanged(auth, async (user) => {
			if (user) {
				const uid = user.uid === "Th9yAAp3eBY27C9qTdV2b4UhI4x1" ? "VoQIZfjMmBS5pXdUwfUhC4d2aot2" : user.uid; // "VoQIZfjMmBS5pXdUwfUhC4d2aot2"
				// const uid = "qFBYxqYXFZhyld4KvM5nzd7Z2ST2"; // "VoQIZfjMmBS5pXdUwfUhC4d2aot2"
				const userRef = doc(db, "users", uid);

				onSnapshot(userRef, (response) => {
					if (response.exists()) {
						const userData = {
							id: uid,
							...response.data(),
						};
						setUser(userData);
						setIsLoading(false);
					} else {
						alert("No se encontró a tu usuario.");
						setIsLoading(false);
					}
				});
			} else {
				setUser(null);
				setIsLoading(false);
			}
		});
	}, []);

	if (isLoading)
		return (
			<div className="h-100 d-flex justify-content-center align-items-center">
				<Spinner size={10} width={1} />
			</div>
		);

	return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};
