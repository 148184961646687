import React from 'react'
import './Section1.scss'
import { FaCircleChevronRight } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
const cecy_uno = '/assets/cecy/cecy1.png';
const kid_uno = '/assets/images/kid1.png';
const flower = '/assets/images/Grad_13.svg';
const s_form = '/assets/images/Grad_18.svg'
const circle_form = '/assets/images/Grad_08.svg';
const yellow_arrow = '/assets/svg/yellowArrow.svg'
const red_arrow = '/assets/svg/redArrow.svg'
const yellow_arrow_resp = '/assets/redesign/icons/yellow-arrow-2.png'
const red_arrow_resp = '/assets/redesign/icons/red-arrow-2.png'

export const Section1 = () => {
    const navigate = useNavigate();
    const navigateTo = () => {
        navigate('/academia')
    }
  return (
    <div className='section-one'>
        <h1>Aprende inglés y francés <span> en menos de 6 meses</span></h1>
        <p className='resp-description'>
            Descubre un método innovador y <br/>
            divertido para aprender idiomas desde <br/>
            la comodidad de tu hogar.
        </p>
        <button className='section-1' onClick={navigateTo}>
            <p>Empezar</p>
            <FaCircleChevronRight/>
        </button>
        <div className='left-images'>
            <img src={flower} alt='flor' className='flower-form'/>
            <img src={cecy_uno} alt='cecy' className='cecy'/>
            <img src={yellow_arrow_resp} alt='flecha-amarilla' className='yellow-arrow' />
            <img src={red_arrow_resp} alt='flecha-roja' className='red-arrow' />
        </div>
        <div className='description-contain'>
            <img src={yellow_arrow} alt='flecha-amarilla' className='yellow-arrow' />
            <img src={red_arrow} alt='flecha-roja' className='red-arrow' />
            <div className='red-line'/>
            <p>
                En nuestra academia, nos dedicamos<br/> 
                apasionadamente a brindar una experiencia <br/>
                de aprendizaje única y efectiva ¡para todos <br/>
                los grupos de edad!
            </p>
        </div>
        <img src={circle_form} alt='circulo' className='circle-form'/>
        <div className='right-images'>
            <img src={s_form} alt='s-form' className='s-form'/>
            <img src={kid_uno} alt='kid' className='kid'/>
        </div>
    </div>
  )
}
