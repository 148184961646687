import { useEffect, useState } from "react";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { HiTrash } from "react-icons/hi";
import { useOutletContext } from "react-router-dom";
import { Spinner } from "../../../../components/spinner/Spinner";
import { CommunityCards, powerCoach } from "../../../../interfaces/PowerCoaches";
import { addCommunityCard, deleteCommunityById, getCommunityCards, updatePowerCoachCommunityCard } from "../../../../services/PowerCoachService";
import "./PowerCoachCommunity.scss";

export function PowerCoachCommunity() {
    const powerCoach: powerCoach = useOutletContext();
    const [step, setStep] = useState(0);
    const [community, setCommunity] = useState({} as CommunityCards);
    const [communities, setCommunities] = useState<Array<CommunityCards>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const update = () => {
        setIsLoading(true);
        if (!community.description || !community.socialNetwork || !community.link) {
            alert("Por favor complete todos los campos!");
            return;
        }
        if (community.id) {
            updatePowerCoachCommunityCard(powerCoach.id, community).then(() => {
                alert("Comunidad editada!");
            })
        } else {
            addCommunityCard(powerCoach.id, community).then(() => {
                alert("Comunidad creada!");
            })
        }
        setIsLoading(false);
        retrieveCommunityCards();
        setStep(0);
    }

    const retrieveCommunityCards = () => {
        getCommunityCards(powerCoach.id).then((res) => {
            setCommunities(res);
        })
    }
    useEffect(() => {
        if (powerCoach) {
            retrieveCommunityCards();
        }
    }, [powerCoach])

    const deleteCommunity = async (value: any) => {
        const isConfirmed = window.confirm("Desea eliminar esta comunidad?");
        if (isConfirmed) {
            await deleteCommunityById(powerCoach.id, value.id);
            retrieveCommunityCards();
        }
    }

    return (
        <div className="powercoach-community-container">
            {step === 0 && <div className="page-one">
                <div className="top">
                    <h2>Tarjetas de comunidad Power Coach</h2>
                    <button onClick={() => { setStep(1); setCommunity({} as CommunityCards) }}>Nueva</button>
                </div>
                <p>Las tarjetas de tu comunidad de mostraran en tu perfil, para que de esta manera los usuarios puedan seguirte/unirse a cada una de ellas.</p>
                <div className="communities-container">
                    {communities.map((x: CommunityCards, index: number) => {
                        return (
                            <div className="communities-card" key={"community" + index}>
                                <h4>Comunidad: {x.socialNetwork}</h4>
                                <a onClick={() => { setStep(1); setCommunity(x); }}>Editar</a>
                                <HiTrash onClick={() => { deleteCommunity(x) }} />
                            </div>
                        )
                    })}
                </div>
            </div>}
            {step === 1 && <div className="page-two">
                <div className="return" onClick={() => { setStep(0); }}>
                    <MdOutlineArrowBackIosNew />
                    <p>Atras</p>
                </div>
                <div className="input-container">
                    <label>Red social:</label>
                    <select defaultValue={community.socialNetwork} onChange={(e) => { setCommunity({ ...community, socialNetwork: e.target.value }) }}>
                        <option value="">Selecciona una opción</option>
                        <option value="Youtube">Youtube</option>
                        <option value="Twitter">Twitter</option>
                        <option value="Discord">Discord</option>
                        <option value="Tiktok">Tiktok</option>
                        <option value="Instagram">Instagram</option>
                        <option value="Whatsapp">Whatsapp</option>
                        <option value="Telegram">Telegram</option>
                        <option value="Zoom">Zoom</option>
                    </select>
                </div>
                <div className="input-container">
                    <label>Descripción:</label>
                    <textarea defaultValue={community.description} id="" cols={2} rows={5} onChange={(e) => {
                        setCommunity({ ...community, description: e.target.value })
                    }}></textarea>
                </div>
                <div className="input-container">
                    <label>Link:</label>
                    <input type="text" defaultValue={community.link} onChange={(e) => {
                        setCommunity({ ...community, link: e.target.value })
                    }} />
                </div>
                {!isLoading ? <button onClick={update}>{community.id ? "Editar" : "Crear"}</button> :
                    <Spinner width={.5} size={4}></Spinner>}
            </div>}

        </div>
    )
}