import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";

import { CategoryCoursesPowerCoachI, powerCoach, powerCoachContext } from "../../../../interfaces/PowerCoaches";
import { CourseCard } from "../../../../components/courseCard/CourseCard";
import "./PremiumPowerCoach.scss";
import { Spinner } from "../../../../components/spinner/Spinner";
import { getCategories, getCoursesByCategory } from "../../../../services/PowerCoachService";

export function PremiumPowerCoach() {
	const { powerCoach, powerCoachSub }: powerCoachContext = useOutletContext();
	const [allCourses, setAllCourses] = useState<Array<CategoryCoursesPowerCoachI>>([]);
	const [loading, setLoading] = useState<boolean>(true);

	const getAllCoursesByCategories = async () => {
		const allCoursesTemp: Array<CategoryCoursesPowerCoachI> = [];

		await getCategories(powerCoach.id).then(async (res) => {
			await Promise.all(
				res.map(async (category) => {
					const data = {
						id: category.id,
						subTitle: category.subTitle,
						title: category.title,
						courses: await getCoursesByCategory(powerCoach.id, category.id),
					};

					allCoursesTemp.push(data);
				})
			);
		});
		setAllCourses(allCoursesTemp);
		setLoading(false);
	};

	useEffect(() => {
		getAllCoursesByCategories();
	}, []);

	return (
		<div className="premium-pc-main-container">
			<div className="card all-courses-card">
				<div className="main-title-container">
					<h1>Cursos del Power Coach</h1>
				</div>
				{loading ? (
					<Spinner size={10} width={1} color={powerCoach.buttonColor} />
				) : allCourses.length > 0 ? (
					allCourses.map((category, i) => {
						if (category.courses.length > 0) {
							return (
								<div className="courses-row" key={"courses-category-row-" + i}>
									<div className="title-subtitle-container">
										<h2>{category.title + " -"} </h2>
										<p>{" " + category.subTitle}</p>
									</div>
									<div className="courses-cards-container">
										{category.courses.map((course, j) => {
											return <CourseCard course={course} key={"course-card-" + j} />;
										})}
									</div>
									{allCourses.length - 1 !== i && <div className="line"></div>}
								</div>
							);
						} else {
							return <></>;
						}
					})
				) : (
					<div className="no-content-container">
						<h2>No hay cursos</h2>
					</div>
				)}
			</div>
		</div>
	);
}
