import { useEffect, useState } from "react";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { Spinner } from "../../../../../../components/spinner/Spinner";
import { modulesCourseComponentI } from "../../../../../../interfaces/ICourse";
import { getModulesByCourseId } from "../../../../../../services/CourseService";
import { createModule, updateModuleInfo } from "../../../../../../services/PowerCoachService";
import "./PowerCoachCourseModules.scss";
interface PowerCoachCourseModulesProps {
    setStep: any,
    courseId: string,
    powercoachId: string,
    setData: any
}
export function PowerCoachCourseModules(props: PowerCoachCourseModulesProps) {
    const [module, setModule] = useState({} as modulesCourseComponentI);
    const [modules, setModules] = useState<Array<modulesCourseComponentI>>([]);
    const [change, setChange] = useState(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const getModules = () => {
        getModulesByCourseId(props.courseId, props.powercoachId).then((res) => {
            setModules(res);
        })
    }

    useEffect(() => {
        if (props.courseId) {
            getModules();
        }
    }, [])

    const update = () => {
        if (!module.title || !module.number) {
            alert("Complete todos los campos!");
            return;
        }
        if (module.id) {
            updateModuleInfo(props.powercoachId, props.courseId, module).then(() => {
                alert("Modulo editado!");
                setChange(0);
            })
        } else {
            createModule(props.powercoachId, props.courseId, module).then(() => {
                alert("Modulo creado!");
                setChange(0);
            })
        }
        setModule({} as modulesCourseComponentI);
        getModules();
    }

    return (
        <div className="powercoach-course-modules-container">
            <div className="return"
                onClick={() => { props.setStep(0) }}
            >
                <MdOutlineArrowBackIosNew />
                <p>Atras</p>
            </div>
            <h2>Editar contenido</h2>
            <div className="top">
                <h2>Modulos</h2>
                <div className="buttons">
                    {(module.id || change === 1) && <button className="delete" onClick={() => { setChange(0); setModule({} as modulesCourseComponentI); }}>Cancelar</button>}
                    <button onClick={() => {
                        setChange(1); setModule({} as modulesCourseComponentI);
                    }}>{module.id ? "Editando" : "Agregar"} modulo</button>
                </div>
            </div>
            {change === 1 && <div className="modules-container">
                {!isLoading ? <div className="form">
                    <div className="input-container">
                        <label>Titulo del modulo:</label>
                        <input value={module.title} onChange={(e) => {
                            setModule({ ...module, title: e.target.value })
                        }} />
                    </div>
                    <div className="input-container">
                        <label>Número del módulo:</label>
                        <input value={module.number} onChange={(e) => {
                            setModule({ ...module, number: parseInt(e.target.value) })
                        }} />
                    </div>
                    <button onClick={update}>{module.id ? "Editar" : "Crear"} modulo</button>
                    {module.id && <button className="delete">Eliminar modulo</button>}
                </div> :
                    <Spinner width={.5} size={4}></Spinner>
                }
            </div>}
            {modules.map((x: modulesCourseComponentI, index: number) => {
                return (
                    <div className="module-card" key={"module" + index}>
                        <p>Módulo: {x.number} {x.title}</p>
                        <div className="edit">
                            <a onClick={() => { setModule({ ...x }); setChange(1); }}>Editar módulo</a>
                            <a onClick={() => { props.setData(x); props.setStep(3); }}>Editar contenido</a>
                        </div>
                    </div>
                )
            })}
        </div>
    )

}