import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { addDoc, collection, doc, getDoc, getDocs, query, setDoc, updateDoc, where } from "firebase/firestore";
import { db } from "../firebase/FirebaseConfig";
import { PowerCoachSubI } from "../interfaces/IUser";
import { getUser } from "./ProfileService";

export async function getPowerCoachSubByUser(userId: string, powerCoachId: string) {
	const docRef = doc(db, "users", userId, "powerCoachesSub", powerCoachId);
	const docSnap = await getDoc(docRef);
	if (docSnap.exists()) {
		const subData = {
			id: docSnap.id,
			...docSnap.data(),
		} as PowerCoachSubI;
		return subData;
	} else {
		const subData: PowerCoachSubI = {
			stripe_id: "",
			final_date: null,
			initial_date: null,
			active: false,
			firstTimeSub: true,
			cancel_at_period_end: false,
			days: 0,
			id: powerCoachId,
		};
		await setDoc(docRef, subData);

		return subData;
	}
}

export async function getUserImageByEmail(email: string) {
	const q = query(collection(db, "users"), where("email", "==", email));
	const docSnap = await getDocs(q);
	let image = "/assets/images/avatar_ph.png";
	if (docSnap.size > 0) {
		image = docSnap.docs[0].data().photo;
	}

	return image;
}

export const updateUserPowerCoachSubscription = async (userId: string, powercoachId: string, subscription: any) => {
	const docRef = doc(db, "users", userId, "powerCoachesSub", powercoachId);
	await setDoc(docRef, subscription, { merge: true });
};

export const addPaymentMethod = async (userId: string, paymentMethodId: string, card: any) => {
	const docRef = doc(db, "users", userId, "payment_method", paymentMethodId);
	await setDoc(docRef, card);
};

export const resetPassword = async (email: string) => {
	const auth = getAuth();
	return await sendPasswordResetEmail(auth, email)
		.then(() => {
			return "success";
		})
		.catch((error) => {
			return error.code;
		});
};

export const updateUserInfo = async (user: any) => {
	const userRef = doc(db, "users", user.id);
	await updateDoc(userRef, {
		paypalEmail: user.paypalEmail,
		paypalSub: user.paypalSub,
	});
};

export const updatePowerCoachCount = async (powerCoach: any) => {
	const userRef = doc(db, "powerCoaches", powerCoach.id);
	await updateDoc(userRef, { students: powerCoach.students + 1 });
};

export const retrieveUserSubs = async (userId: string) => {
	let data: any = [];
	const docRef = collection(db, "users", userId, "powerCoachesSub");
	const querySnapshot = await getDocs(docRef);
	querySnapshot.forEach((doc) => {
		data.push({ ...doc.data(), id: doc.id });
	});
	return data;
};

export async function getUserByEmail(email: string) {
	const q = query(collection(db, "users"), where("email", "==", email));
	const docSnap = await getDocs(q);
	let data;
	if (docSnap.size > 0) {
		data = docSnap.docs[0].data();
		return { ...data, id: docSnap.docs[0].id };
	}
	return "";
}

export async function createUserCollection(data: any) {
	let user = await addDoc(collection(db, "users"), data);
	return user.id;
}
