import { useCallback, useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { Spinner } from "../components/spinner/Spinner";
import { UserContext } from "../context/UserContext";
import { modulesCourseComponentI } from "../interfaces/ICourse";
import { IForum } from "../interfaces/IForum";
import { PowerCoachSubI } from "../interfaces/IUser";
import { CourseI, powerCoach } from "../interfaces/PowerCoaches";
import { NoSubscription } from "../pages/noSubscription/NoSubscription";
import { getForumById, getModulesByCourseId } from "../services/CourseService";
import { getCourseById, getPowerCoachByName } from "../services/PowerCoachService";
import { getPowerCoachSubByUser } from "../services/UserService";

export function AuthRoutePC() {
	const params = useParams();
	const [powerCoach, setPowerCoach] = useState<powerCoach>();
	const [powerCoachSub, setPowerCoachSub] = useState<PowerCoachSubI | null>(null);
	const [loading, setLoading] = useState<boolean>(true);
	const navigate = useNavigate();
	const user = useContext(UserContext);
	const [course, setCourse] = useState<CourseI | null>(null);
	const [modules, setModules] = useState<Array<modulesCourseComponentI>>([]);
	const location = useLocation();
	const [forum, setForum] = useState<IForum | null>();

	const getPowerCoachSub = useCallback(async () => {
		const pcName = params.powercoach;
		const courseId = params.courseId;
		const forumId = params.foroId;
		if (pcName && user !== null) {
			await getPowerCoachByName(pcName).then(async (powerCoachData) => {
				if (
					powerCoachData === null ||
					(location.pathname.indexOf("curso") !== -1 && !courseId) ||
					(location.pathname.indexOf("foro") !== -1 && !forumId)
				) {
					navigate("/");
				} else {
					let pcSub: PowerCoachSubI | null = await getPowerCoachSubByUser(user.id, powerCoachData.id);
					if (pcSub.final_date === null || (pcSub?.final_date && pcSub.final_date.seconds < today)) {
						pcSub = await getPowerCoachSubByUser(user.id, "ambos");
					}
					setPowerCoachSub(pcSub);
					setPowerCoach(powerCoachData);
					if (location.pathname.indexOf("curso") !== -1 && courseId) {
						const courseData = await getCourseById(powerCoachData.id, courseId);
						if (courseData === null) navigate("/");
						await getModulesByCourseId(courseId, powerCoachData.id).then((res) => {
							setModules(res);
						});
						setCourse(courseData);
						setLoading(false);
					} else if (location.pathname.indexOf("foro") !== -1 && forumId) {
						await getForumById(powerCoachData.id, forumId).then((res) => {
							if (res === null) navigate("/");
							setForum(res);
							setLoading(false);
						});
					}
				}
			});
		} else {
			// navigate("/login");
			navigate("/");
		}
	}, [location.pathname,]);

	useEffect(() => {
		getPowerCoachSub();
	}, [getPowerCoachSub]);

	let today = new Date().getTime() / 1000;

	if (loading) return <Spinner size={10} width={1} />;
	// if (
	// 	(powerCoachSub !== null &&
	// 		((location.pathname.indexOf("foro") !== -1 && powerCoachSub.final_date !== null && powerCoachSub.final_date.seconds < today && forum !== null) ||
	// 			(location.pathname.indexOf("curso") !== -1 && powerCoachSub.final_date !== null && powerCoachSub.final_date.seconds < today && course !== null && course.type === "premium"))) ||
	// 	powerCoachSub === null
	// )

	if (location.pathname.indexOf("curso") !== -1 && course !== null && course.type === "free") {
		return <Outlet context={{ powerCoach: powerCoach, course: course, modules: modules }} />;
	}
	if (powerCoachSub === null || powerCoachSub !== null && powerCoachSub.final_date === null || powerCoachSub !== null && powerCoachSub.final_date !== null && powerCoachSub.final_date.seconds < today)
		return <NoSubscription />;
	if (location.pathname.indexOf("curso") !== -1) {
		return <Outlet context={{ powerCoach: powerCoach, course: course, modules: modules }} />;
	} else {
		return <Outlet context={{ powerCoach: powerCoach, forum: forum }} />;
	}
}
