
import { useEffect, useState } from "react";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { useOutletContext } from "react-router-dom";
import { CategoryCoursesPowerCoachI, powerCoach } from "../../../../interfaces/PowerCoaches";
import { createCategory, getCategories, updateCategoryInfo } from "../../../../services/PowerCoachService";
import "./PowerCoachCategories.scss";

export function PowerCoachCategories() {
    const powerCoach: powerCoach = useOutletContext();
    const [step, setStep] = useState(0);
    const [category, setCategory] = useState({} as CategoryCoursesPowerCoachI);
    const [categories, setCategories] = useState<Array<CategoryCoursesPowerCoachI>>([]);

    const updateCategory = () => {
        if (!category.title || !category.subTitle) {
            alert("Por favor complete todos los campos!");
            return;
        }
        if (category.id) {
            updateCategoryInfo(powerCoach.id, category).then(() => {
                alert("Categoria editada!");
            })
        } else {
            createCategory(powerCoach.id, category).then(() => {
                alert("Categoria creada!");
            })
        }
        getAllCategories();
        setStep(0);
    }

    const getAllCategories = () => {
        getCategories(powerCoach.id).then((res: any) => {
            delete res.courses
            setCategories(res);
        })
    }

    useEffect(() => {
        if (powerCoach) {
            getAllCategories();
        }
    }, [powerCoach])

    return (
        <div className="powercoach-categories-container">
            {step === 0 && <div className="page-one">
                <div className="top">
                    <h2>Categorias Power Coach</h2>
                    <button onClick={() => { setStep(1); setCategory({} as CategoryCoursesPowerCoachI); }}>Nuevo</button>
                </div>
                <div className="categories-container">
                    {categories.map((x: CategoryCoursesPowerCoachI, index: number) => {
                        return (
                            <div className="categories-card" key={"category" + index}>
                                <h4>Categoría: {x.title}</h4>
                                <a onClick={() => { setStep(1); setCategory(x); }}>Editar</a>
                            </div>
                        )
                    })}
                </div>
            </div>}
            {step === 1 && <div className="page-two">
                <div className="return" onClick={() => { setStep(0); setCategory({} as CategoryCoursesPowerCoachI); }}>
                    <MdOutlineArrowBackIosNew />
                    <p>Atras</p>
                </div>
                <div className="top">
                    <h2>Nueva categoría</h2>
                    <button onClick={updateCategory}>{category.id ? "Editar" : "Crear"} categoría</button>
                </div>
                <div className="input-container">
                    <label>Titulo categoría:</label>
                    <input defaultValue={category.title} onChange={(e) => {
                        category.title = e.target.value
                    }} />
                </div>
                <div className="input-container">
                    <label>Subtitulo categoría:</label>
                    <input defaultValue={category.subTitle} onChange={(e) => {
                        category.subTitle = e.target.value
                    }} />
                </div>
            </div>}
        </div>
    )
}