import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { Spinner } from "../../components/spinner/Spinner";
import { modulesCourseComponentI } from "../../interfaces/ICourse";
import { CourseI, powerCoach } from "../../interfaces/PowerCoaches";
import { LectureCard } from "./components/LessonCard/LessonCard";
import "./Course.scss";

interface courseOutletContext {
	powerCoach: powerCoach;
	course: CourseI;
	modules: Array<modulesCourseComponentI>;
}

export function Course() {
	const { powerCoach, course, modules }: courseOutletContext = useOutletContext();
	const [view, setView] = useState<number>(1);
	const [loadingModules, setLoadingModules] = useState<boolean>(true);
	const [selectedModule, setSelectedModule] = useState<number>(0);

	useEffect(() => {
		document.documentElement.style.setProperty("--power-coach-color", powerCoach.buttonColor);
		document.documentElement.style.setProperty("--power-coach-bg-color", powerCoach.buttonColor + 27);
		setLoadingModules(false);
	}, []);

	return (
		<div className="course-main-container">
			<div className="banner">
				<img src={course.bannerImage} alt={`Aprende con cecy: ${course.title}`} />
				<div className="title-container">
					<h1>{course.title}</h1>
				</div>
			</div>
			<div className="content-container">
				<div className="course-nav">
					<ul>
						<li className={view === 1 ? "active" : ""} onClick={() => setView(1)}>
							Lecciones
						</li>
						<li className={view === 2 ? "active" : ""} onClick={() => setView(2)}>
							Sobre el curso
						</li>
					</ul>
				</div>
				{view === 1 ? (
					loadingModules ? (
						<Spinner size={10} width={1} color={powerCoach.buttonColor} />
					) : (
						<div className="course-content-container">
							<div className="modules-container">
								{modules.map((module, i) => {
									return (
										<div className={"module " + (selectedModule === i ? "active" : "")} key={"module-" + i} onClick={() => setSelectedModule(i)}>
											{module.title}
										</div>
									);
								})}
							</div>
							{modules[selectedModule].lectures.map((lecture, i) => {
								return <LectureCard moduleId={modules[selectedModule].id} lecture={lecture} key={"lecture-card-" + i} />;
							})}
						</div>
					)
				) : (
					<div className="course-about-container">
						<p>{course.description}</p>
						<p>{course.about}</p>
					</div>
				)}
			</div>
		</div>
	);
}
