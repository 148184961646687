import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { CommunityCards, powerCoach, powerCoachContext } from "../../../../../../interfaces/PowerCoaches";
import "./CommunityCard.scss";

interface CommunityCardProps {
	community: CommunityCards;
	premium?: boolean;
}

export function CommunityCard(props: CommunityCardProps) {

	const { powerCoach, powerCoachSub }: powerCoachContext = useOutletContext();
	const navigate = useNavigate();
	let today = new Date().getTime() / 1000;
	function getCommunityImage(network: string) {
		switch (network) {
			case "Youtube":
				return (
					<div className="image-container">
						<img
							src="/assets/images/youtube-icon.svg"
							style={{
								filter: "invert(21%) sepia(65%) saturate(6350%) hue-rotate(348deg) brightness(92%) contrast(101%)",
							}}
							alt="youtube icon"
						/>
					</div>
				);
				break;
			case "Twitter":
				return (
					<div className="image-container">
						<img src="/assets/images/twitter-icon.svg" alt="twitter icon" />
					</div>
				);
				break;
			case "Discord":
				return (
					<div className="image-container">
						<img
							src="/assets/images/discord-icon.svg"
							style={{
								filter: "invert(21%) sepia(65%) saturate(6350%) hue-rotate(348deg) brightness(92%) contrast(101%)",
							}}
							alt="discord icon"
						/>
					</div>
				);
				break;
			case "Tiktok":
				return (
					<div className="image-container">
						<img src="/assets/images/tiktok-icon.svg" alt="tiktok icon" />
					</div>
				);
				break;
			case "Whatsapp":
				return (
					<div className="image-container">
						<img src="/assets/images/whatsapp-icon.svg" alt="whatsapp icon" />
					</div>
				);
				break;
			case "Telegram":
				return (
					<div className="image-container">
						<img src="/assets/images/telegram-icon.svg" alt="telegram icon" />
					</div>
				);
				break;
			case "Instagram":
				return (
					<div className="image-container">
						<img src="/assets/images/insta-icon.svg" alt="instagram icon" />
					</div>
				);
				break;
			case "Zoom":
				return (
					<div className="image-container">
						<img src="/assets/images/zoom-icon.svg" alt="zoom icon" />;
					</div>
				);
				break;

			default:
				return (
					<div className="image-container w-100">
						<img className="w-100 rounded" src={props.community.imageCommunity} alt="imagen del contenido" />
					</div>
				);
				break;
		}
	}

	const goTo = () => {
		let tempFinalDate: any = powerCoachSub.final_date?.seconds

		let diff = 0;
		diff = (tempFinalDate - today) / (60 * 60 * 24);
		if ("link" in props.community && tempFinalDate > today) {
			if (diff > 30) {
				let link: any = props.community.link
				window.location.href = link
			} else {
				alert("Actualiza a membresía anual para este beneficio.");
			}
		}
		if (props.premium && tempFinalDate > today) {
			navigate(`/foro/${powerCoach.name}/${props.community.id}`)
		}
	}

	return (
		<a onClick={goTo}>
			<div className={"card community-card " + (props.premium ? "new-order" : "")}>
				{getCommunityImage(props.community.socialNetwork)}
				<div className="description-container">
					<h3>{props.community.description.slice(0, 100) + (props.community.description.length > 100 ? "..." : "")}</h3>
				</div>
				<div className="social-network-name-container">
					<h3>{props.community.socialNetwork}</h3>
				</div>
			</div>
		</a>
	);
}
