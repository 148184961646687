import { IForum } from "../../../../../../interfaces/IForum";
import "./ForumCard.scss";
interface ForumCardProps {
    forum: IForum,
    setStep: any,
    index: number,
    handleClick: any
}

export function ForumCard(props: ForumCardProps) {
    return (
        <div className="admin-powercoach-forum-card">
            <img src={props.forum.imageCommunity} alt="" />
            <p>{props.forum.socialNetwork}</p>
            <a onClick={() => {
                props.setStep(1);
                props.handleClick(props.index);
            }}>Editar</a>
        </div>
    )
}