import { Link, useOutletContext } from "react-router-dom";
import { CourseI, CoursesPowercoachI, powerCoach, powerCoachContext } from "../../interfaces/PowerCoaches";
import "./CourseCard.scss";

interface CourseCardProps {
	course: CoursesPowercoachI;
}

export function CourseCard(props: CourseCardProps) {
	const { powerCoach, powerCoachSub }: powerCoachContext = useOutletContext();
	return (
		<Link to={`/curso/${powerCoach.name}/${props.course.id}`}>
			<div className="card course-card-component">
				<img src={props.course.courseImage} alt="imagen del curso" />
				<div className="info-container">
					<div className="title-container">
						<h3>{props.course.title}</h3>
					</div>
					<div className="modules-container">
						<h3>{props.course.modulesNumber} Módulos</h3>
					</div>
				</div>
				<div className={"course-type-container"} style={props.course.type === "premium" ? { backgroundColor: powerCoach.buttonColor } : {}}>
					<p>{props.course.type === "free" ? "Gratis" : "Premium"}</p>
				</div>
			</div>
		</Link>
	);
}
