import {
  collection,
  doc,
  deleteDoc,
  getDocs,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase/FirebaseConfig";

export const updateUserInfo = async (user: any) => {
  const userRef = doc(db, "users", user.id);
  await updateDoc(userRef, {
    name: user.name,
    birthdate: user.birthdate,
  });
};

export const getPaymentMethods = async (userId: string) => {
  let data: any = [];
  const docRef = collection(db, "users", userId, "payment_method");
  const querySnapshot = await getDocs(docRef);
  querySnapshot.forEach((doc) => {
    data.push({ ...doc.data(), id: doc.id });
  });
  return data;
};
export const deleteUserCard = async (cardId: string, userId: string) => {
  await deleteDoc(doc(db, "users", userId, "payment_method", cardId));
};

export const getUser = async (userId: string) => {
  const docRef = doc(db, "users", userId);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return { ...docSnap.data(), id: docSnap.id };
  }
};
