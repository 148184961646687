import { Modal } from "react-bootstrap";
import { useNavigate, useOutletContext } from "react-router-dom";
import { powerCoach, powerCoachContext } from "../../interfaces/PowerCoaches";
import "./ConfirmModal.scss";

interface confirmModalProps {
	show: boolean;
	onHide: () => void;
	title: string;
	message: string;
	type?: string;
	donation?: number;
	powerCoach?: powerCoach;
}

export function ConfirmModal(props: confirmModalProps) {

    const navigate = useNavigate();


    const action = () => {
		if(props.title === "Error con el pago - tarjeta predeterminada") {
			if(props.type === "donation") {
				navigate(`/checkout/${props.powerCoach?.id}?tipo=donacion&donacion=${props.donation}`)
			} else {
				navigate(`/checkout/${props.powerCoach?.id}`)
			}
		}
    }

	return (
		<Modal
			className="confirm-modal-component"
			show={props.show}
			onHide={props.onHide}
			size="lg"
			backdrop="static"
			keyboard={false}
		>
			<Modal.Header closeButton>
				<Modal.Title>{props.title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>{props.message}</p>
			</Modal.Body>
			<Modal.Footer>
				<button className="cancel" onClick={props.onHide}>Cancelar</button>
				<button className="continue" onClick={action}>Continuar</button>
			</Modal.Footer>
		</Modal>
	);
}
