import "./App.scss";
import { createBrowserRouter, RouterProvider, Route, createRoutesFromElements } from "react-router-dom";
import { MainLayout } from "./layouts/MainLayout";
import { Home } from "./pages/home/Home";
import { NoMatchRoute } from "./components/noMatchRoute/NoMatchRoute";
import { Questions } from "./pages/questions/Questions";
import { Academy } from "./pages/academy/Academy";
import { Login } from "./pages/auth/login/Login";
import { PowerCoach } from "./pages/powerCoach/PowerCoach";
import { GeneralPowerCoach } from "./pages/powerCoach/pages/general/GeneralPowerCoach";
import { PremiumPowerCoach } from "./pages/powerCoach/pages/premium/PremiumPowerCoach";
import { Register } from "./pages/auth/register/Register";
import { Profile } from "./pages/profile/Profile";
import { Forums } from "./pages/powerCoach/pages/forums/Forums";
import { Free } from "./pages/powerCoach/pages/free/Free";
import { Admin } from "./pages/admin/Admin";
import { UsersAdmin } from "./pages/admin/pages/users/UsersAdmin";
import { UserEditAdmin } from "./pages/admin/pages/users/pages/UserEditAdmin";
import { AdminPowerCoaches } from "./pages/admin/pages/powercoaches/AdminPowerCoaches";
import { Course } from "./pages/course/Course";
import { AuthRoutePC } from "./utils/AuthRoutePC";
import { AdminPowerCoach } from "./pages/adminPowerCoach/AdminPowerCoach";
import { PowerCoachProfile } from "./pages/adminPowerCoach/pages/profile/PowerCoachProfile";
import { Lecture } from "./pages/course/pages/Lecture/Lecture";
import { PowerCoachForums } from "./pages/adminPowerCoach/pages/forums/PowerCoachForums";
import { PowerCoachCourses } from "./pages/adminPowerCoach/pages/courses/PowerCoachCourses";
import { PowerCoachCategories } from "./pages/adminPowerCoach/pages/categories/PowerCoachCategories";
import { PowerCoachSubscriptions } from "./pages/adminPowerCoach/pages/subscriptions/PowerCoachSubscriptions";
import { Forum } from "./pages/forum/Forum";
import { PowerCoachSales } from "./pages/adminPowerCoach/pages/sales/PowerCoachSales";
import { PowerCoachCommunity } from "./pages/adminPowerCoach/pages/community/PowerCoachCommunity";
import { PowerCoachUsers } from "./pages/adminPowerCoach/pages/users/PowerCoachUsers";
import { Checkout } from "./pages/checkout/Checkout";
import { PowerCoachSocialNetworks } from "./pages/adminPowerCoach/pages/socialnetworks/PowerCoachSocialNetworks";
import { CheckoutV2 } from "./pages/checkoutV2/CheckoutV2";
import { Thanks } from "./pages/thanks/Thanks";

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route path="/" element={<MainLayout />}>
			<Route index element={<Home />} />
			<Route path="/academia" element={<Academy />}></Route>
			<Route path="academia/:idioma/:tipo" element={<CheckoutV2 />}>
				<Route index element={<CheckoutV2 />}></Route>
			</Route>
			<Route path="academia/:powercoach" element={<PowerCoach />}>
				<Route index element={<GeneralPowerCoach />}></Route>
				<Route path="premium" element={<PremiumPowerCoach />}></Route>
				<Route path="foros" element={<Forums />}></Route>
				<Route path="gratis" element={<Free />}></Route>
			</Route>
			<Route path="curso/:powercoach/:courseId" element={<AuthRoutePC />}>
				<Route index element={<Course />}></Route>
				<Route path={":moduleId/:lectureId"} element={<Lecture />}></Route>
			</Route>
			<Route path="foro/:powercoach/:foroId" element={<AuthRoutePC />}>
				<Route index element={<Forum />}></Route>
			</Route>
			<Route path="/admin" element={<Admin />}>
				<Route index element={<UsersAdmin />}></Route>
				<Route path="usuarios/:userId" element={<UserEditAdmin />}></Route>
				<Route path="powercoaches" element={<AdminPowerCoaches />}></Route>
			</Route>
			<Route path="/checkout/:powerCoachId" element={<Checkout />}></Route>
			<Route path="/preguntas" element={<Questions />}></Route>
			<Route path="/perfil" element={<Profile />}></Route>
			{/* <Route path="/login" element={<Login />}></Route> */}
			{/* <Route path="/Registro" element={<Register />}></Route> */}
			<Route path="*" element={<NoMatchRoute />}></Route>
			<Route path="/powercoach" element={<AdminPowerCoach />}>
				<Route index element={<PowerCoachProfile />}></Route>
				<Route path="foros" element={<PowerCoachForums />}></Route>
				<Route path="categorias" element={<PowerCoachCategories />}></Route>
				<Route path="cursos" element={<PowerCoachCourses />}></Route>
				<Route path="suscripciones" element={<PowerCoachSubscriptions />}></Route>
				<Route path="ventas" element={<PowerCoachSales />}></Route>
				<Route path="comunidad" element={<PowerCoachCommunity />}></Route>
				<Route path="usuarios" element={<PowerCoachUsers />}></Route>
				<Route path="red-social" element={<PowerCoachSocialNetworks />}></Route>
			</Route>
			<Route path="/preguntas" element={<Questions />}></Route>
			<Route path="/thankyou" element={<Thanks />}></Route>

			<Route path="/perfil" element={<Profile />}></Route>
			{/* <Route path="/login" element={<Login />}></Route> */}
			{/* <Route path="/Registro" element={<Register />}></Route> */}
			<Route path="*" element={<NoMatchRoute />}></Route>
		</Route>
	)
);

function App() {
	return <RouterProvider router={router} />;
}

export default App;
