import { httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { useOutletContext } from "react-router-dom";
import { Spinner } from "../../../../components/spinner/Spinner";
import { functions } from "../../../../firebase/FirebaseConfig";
import { IAdminPowercoachSubscription, IPowercoachSubscription, powerCoach } from "../../../../interfaces/PowerCoaches";
import {
	getPowerCoachById,
	retrieveSubscriptions,
	updatePowerCoach,
	updatePowercoachInfo,
	updatePowerCoachSubscriptionMonth,
	updatePowerCoachSubscriptionYear,
} from "../../../../services/PowerCoachService";
import "./PowerCoachSubscriptions.scss";

export function PowerCoachSubscriptions() {
	const powerCoach: powerCoach = useOutletContext();
	const [powercoach, setPowerCoach] = useState<powerCoach>();
	const [step, setStep] = useState(0);
	const [subscription, setSubscription] = useState<any>({ type: "Mensual", currency: "mxn" });
	const [subscriptions, setSubscriptions] = useState<Array<IAdminPowercoachSubscription>>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const getSubscriptions = () => {
		retrieveSubscriptions(powerCoach.id).then((res) => {
			setSubscriptions(res);
		});
	};
	const getPowerCoach = () => {
		getPowerCoachById(powerCoach.id).then((res: any) => {
			setPowerCoach(res);
		});
	};

	useEffect(() => {
		if (powerCoach) {
			getSubscriptions();
			getPowerCoach();
		}
	}, [powerCoach]);

	const update = () => {
		setIsLoading(true);
		let recurring = "month";
		if (!powerCoach.price_id_array) {
			powerCoach.price_id_array = [];
		}
		if (subscription.type === "Semestral") {
			recurring = "year";
		}
		if (!subscription.name || !subscription.price) {
			alert("Complete los campos por favor!");
			setIsLoading(false);
			return;
		}
		let createProduct = httpsCallable(functions, "createPowerCoachProduct_ACC");
		console.log(subscription);
		createProduct({
			name: subscription.name,
			price: parseFloat(subscription.price) * 100,
			recurring: 'month',
			currency: subscription.currency,
			interval: recurring === "month" ? 1 : 6

		})
			.then((res: any) => {
				let paypalSuscription = httpsCallable(functions, "createSubPaypalCoach");
				paypalSuscription({
					name: subscription.name,
					price: parseFloat(subscription.price),
					frequency: subscription.type === "Mensual" ? "MONTH" : "YEAR",
					currency: subscription.currency,
					interval: recurring === "month" ? 1 : 6
				}).then((sub: any) => {
					powerCoach.price_id_array.push(res.data.id);
					let data = {};
					// if (subscription.type === "Mensual") {
					// 	data = {
					// 		priceId: res.data.id,
					// 		precioCard: res.data.unit_amount,
					// 		paypalPriceId: sub.data.id,
					// 		price_id_array: powerCoach.price_id_array,
					// 	};
					// } else {
					// 	data = {
					// 		priceId_year: res.data.id,
					// 		precioCard_year: res.data.unit_amount,
					// 		paypalPriceId_year: sub.data.id,
					// 		price_id_array: powerCoach.price_id_array,
					// 	};
					// }
					let tempSubscription = {
						name: subscription.name,
						precio: res.data.unit_amount,
						priceId: res.data.id,
						paypalPriceId: sub.data.id,
						type: subscription.type,
						currency: subscription.currency,
					};
					updatePowercoachInfo(powerCoach.id, tempSubscription, data).then(() => {
						getPowerCoach();
						getSubscriptions();
						alert("Suscripción creada!");
						setStep(0);
						setIsLoading(false);
					});
				});
			})
			.catch((error: any) => {
				alert("Se produjo un error!");
				setIsLoading(false);
			});
	};

	const toggle = async (x: IPowercoachSubscription, type: string, currency: "mxn" | "usd") => {
		let data;
		if (type === "month" && currency === "mxn") {
			data = {
				priceId: x.priceId,
				precioCard: x.precio,
				paypalPriceId: x.paypalPriceId,
			};
		} else if (type === "semestral" && currency === "mxn") {
			data = {
				priceId_year: x.priceId,
				precioCard_year: x.precio,
				paypalPriceId_year: x.paypalPriceId,
			};
		} else if (type === "month" && currency === "usd") {
			data = {
				priceId_usd: x.priceId,
				precioCard_usd: x.precio,
				paypalPriceId_usd: x.paypalPriceId,
			};
		} else if (type === "semestral" && currency === "usd") {
			data = {
				priceId_year_usd: x.priceId,
				precioCard_year_usd: x.precio,
				paypalPriceId_year_usd: x.paypalPriceId,
			};
		}

		if (data) {
			try {
				const response = await updatePowerCoach(powerCoach.id, data);
			} catch (error) {
				console.error();
			} finally {
				getPowerCoach();
			}
		}
	};

	return (
		<div className="powercoach-subscriptions-container">
			{step === 0 && (
				<div className="page-one">
					<div className="top">
						<h2>Tus Suscripciones de Power Coach</h2>
						<button
							onClick={() => {
								setStep(1);
								setSubscription({ type: "Mensual", currency: "mxn" });
							}}
						>
							Nuevo
						</button>
					</div>
					{subscriptions.map((x, index: number) => {
						return (
							<div className="subscription-card" key={"subscription" + index}>
								<p>{x.name}</p>
								<p>
									${x.precio / 100} {x.currency ? x.currency.toUpperCase() : "MXN"}
								</p>
								{x.type === "Mensual" &&
									((x.currency !== "usd" && powercoach?.priceId === x.priceId) ||
										(x.currency === "usd" && powercoach?.priceId_usd === x.priceId)) && <p>Predeterminado mensual</p>}
								{x.type === "Mensual" &&
									((x.currency !== "usd" && powercoach?.priceId !== x.priceId) ||
										(x.currency === "usd" && powercoach?.priceId_usd !== x.priceId)) && (
										<p
											onClick={() => {
												toggle(x, "month", x.currency ? x.currency : "mxn");
											}}
										>
											Hacer predeterminado mensual
										</p>
									)}
								{x.type === "Semestral" &&
									((x.currency !== "usd" && powercoach?.priceId_year === x.priceId) ||
										(x.currency === "usd" && powerCoach.priceId_year_usd === x.priceId)) && <p>Predeterminado Semestral</p>}

								{x.type === "Semestral" &&
									((x.currency !== "usd" && powercoach?.priceId_year !== x.priceId) ||
										(x.currency === "usd" && powerCoach.priceId_year_usd !== x.priceId)) && (
										<p
											onClick={() => {
												toggle(x, "semestral", x.currency ? x.currency : "mxn");
											}}
											role="button"
										>
											Hacer predeterminado Semestral
										</p>
									)}
							</div>
						);
					})}
				</div>
			)}
			{step === 1 && (
				<div className="page-two">
					<div
						className="return"
						onClick={() => {
							setStep(0);
						}}
					>
						<MdOutlineArrowBackIosNew />
						<p>Atras</p>
					</div>
					<h2>Nueva Suscripción</h2>
					<div className="input-container">
						<label>Nombre:</label>
						<input
							defaultValue={subscription.name}
							onChange={(e) => setSubscription({ ...subscription, name: e.target.value })}
						/>
					</div>
					<div className="input-container">
						<label htmlFor="currency">Moneda:</label>
						<select
							name="currency"
							id="currency"
							value={subscription.currency}
							onChange={(e) => setSubscription({ ...subscription, currency: e.target.value })}
						>
							<option value="mxn">MXN</option>
							<option value="usd">USD</option>
						</select>
					</div>
					<div className="input-container">
						<label>Precio:</label>
						<input
							type="number"
							defaultValue={subscription.price}
							onChange={(e) =>
								setSubscription({
									...subscription,
									price: parseFloat(e.target.value),
								})
							}
						/>
					</div>
					<div className="input-container">
						<label>Recurrencia:</label>
						<select
							defaultValue={subscription.type}
							onChange={(e) =>
								setSubscription({
									...subscription,
									type: e.target.value,
								})
							}
						>
							<option value="Mensual">Mensual</option>
							<option value="Semestral">Semestral</option>
						</select>
					</div>
					{!isLoading ? <button onClick={update}>Crear</button> : <Spinner width={0.5} size={4}></Spinner>}
				</div>
			)}
		</div>
	);
}
