import { useState, createContext, useContext } from "react";

interface UI_I {
	showModalLogin: boolean;
	handleModalLogin: () => void;

	showModalRegister: boolean;
	handleModalRegister: () => void;

	showModalGift: boolean;
	handleModalGift: () => void;

	showModalReset: boolean;
	handleModalReset: () => void;

	currency: string;
	handleCurrency: (value: string) => void;
}

const initailValue: UI_I = {
	showModalLogin: false,
	handleModalLogin: () => {},

	showModalRegister: false,
	handleModalRegister: () => {},

	showModalGift: false,
	handleModalGift: () => {},

	showModalReset: false,
	handleModalReset: () => {},

	currency: "mxn",
	handleCurrency: () => {},
};

export const UI_IContext = createContext(initailValue);

export const useUI = () => {
	return useContext(UI_IContext);
};

export const UIContext = (props: any) => {
	const [showModalLogin, setShowModalLogin] = useState(false);
	const [showModalRegister, setShowModalRegister] = useState(false);
	const [showModalGift, setShowModalGift] = useState(false);
	const [showModalReset, setShowModalReset] = useState(false);
	const [currency, setCurrency] = useState<string>("mxn");

	const handleModalReset = () => {
		setShowModalReset((p) => !p);
	};

	const handleModalLogin = () => {
		setShowModalLogin((p) => !p);
	};

	const handleModalGift = () => {
		setShowModalGift((p) => !p);
	};

	const handleModalRegister = () => {
		setShowModalRegister((p) => !p);
	};

	const handleCurrency = (value: string) => {
		setCurrency(value);
	};

	const values: UI_I = {
		showModalLogin,
		handleModalLogin,
		showModalRegister,
		handleModalRegister,
		showModalGift,
		handleModalGift,
		showModalReset,
		handleModalReset,
		currency,
		handleCurrency,
	};

	return <UI_IContext.Provider value={values} {...props} />;
};
