import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { updateProfile } from "../../../../services/PowerCoachService";
import { assignBecomeMember, setSocialMedia } from "../../../../utilis/functions/AdminPowerCoach";
import "./PowerCoachProfile.scss";
interface ISocialMedia {
    name: string,
    active: boolean,
    link: string
}

export function PowerCoachProfile() {
    const powerCoach: any = useOutletContext();
    const [step, setStep] = useState(0);
    const [socialMedias, setSocialMedias] = useState([
        { name: "facebook", active: false, link: "" },
        { name: "twitter", active: false, link: "" },
        { name: "discord", active: false, link: "" },
        { name: "youtube", active: false, link: "" },
        { name: "reddit", active: false, link: "" },
        { name: "telegram", active: false, link: "" },
        { name: "tiktok", active: false, link: "" },
        { name: "instagram", active: false, link: "" },
    ]);
    const [becomeMember, setBecomeMember] = useState<any>({
        "accessC": false,
        "asesoria": false,
        "exclusiveContent": false,
        "faq": false,
        "foro": false,
        "lives": false,
        "merch": false,
        "newCourses": false
    });
    const [active, setActive] = useState(false);
    const [profile, setProfile] = useState<any>("/assets/images/avatar_ph.png");
    const [banner, setBanner] = useState<any>("/assets/placeholders/background.png");

    const updateMedia = (value: any, type: string, name: string) => {
        let tempSocialMedias = socialMedias;
        let index = tempSocialMedias.findIndex((x) => x.name === name);

        if (type === "active") {
            tempSocialMedias[index].active = value
        } else {
            tempSocialMedias[index].link = value
        }
        setSocialMedias(tempSocialMedias);
    }

    const getImage = (file: any, type: string) => {
        var reader = new FileReader();
        reader.readAsDataURL(file[0]);
        reader.onload = (_event) => {
            if (type === "banner") {
                setBanner(reader.result);
            } else {
                setProfile(reader.result);
            }
        };
    }

    const update = () => {
        powerCoach.becomeMember = becomeMember;
        powerCoach.active = active;
        powerCoach.imageLink = profile;
        powerCoach.bannerLink = banner;
        socialMedias.forEach((element) => {
            let tempName = element.name;
            powerCoach[tempName] = element;
        })
        if (!powerCoach.name || !powerCoach.about || !powerCoach.area || !powerCoach.buttonColor || !powerCoach.previewVideo) {
            alert("Complete los campos obligatorios")
        } else {
            updateProfile(powerCoach).then(() => {
                alert("Perfil actualizado!")
            })
        }
    }

    useEffect(() => {
        if (powerCoach) {
            if (Object.keys(powerCoach).length > 1) {
                setStep(2);
                setProfile(powerCoach.imageLink);
                setBanner(powerCoach.bannerLink);
                setActive(powerCoach.active);
                assignBecomeMember(becomeMember, powerCoach).then((res) => {
                    setBecomeMember(res);
                })
                setSocialMedia(socialMedias, powerCoach);
            }
        }
    }, [powerCoach])

    const changeStep = () => {
        if (Object.keys(powerCoach).length > 1) {
            setStep(2);
        } else {
            setStep(0);
        }
    }

    return (
        <div className="powercoach-profile-container">
            {step === 0 && <div className="first-step">
                <h4>Al parecer no has creado tu perfil de powercoach, crealo ahora.</h4>
                <button onClick={() => { setStep(1) }}>Crear Perfil</button>
            </div>}
            {step === 2 && <div className="powercoach-info">
                <h2>Power Coach</h2>
                <div className="powercoach-box">
                    <img src={powerCoach?.imageLink} alt="" />
                    <p className="name">{powerCoach?.name}</p>
                    <a onClick={() => { setStep(1) }}>Editar</a>
                </div>
            </div>}
            {step === 1 && <div className="second-step">
                <div className="return" onClick={() => { changeStep() }}>
                    <MdOutlineArrowBackIosNew />
                    <p>Atras</p>
                </div>
                <img className="profile-img" src={profile} alt="" />
                <div>
                    <p>Imagen de perfil: (480 x 480) recomendado</p>
                    <input type="file" onChange={(e) => { getImage(e.target.files, "profile") }} />
                </div>
                <img className="banner-img" src={banner} alt="" />
                <div>
                    <p>Imagen de portada: (1100 x 300) recomendado</p>
                    <input type="file" onChange={(e) => { getImage(e.target.files, "banner") }} />
                </div>
                <div className="input-container">
                    <label>Nombre:</label>
                    <input type="text" defaultValue={powerCoach?.name} onChange={(e) => {
                        powerCoach.name = e.target.value
                    }} />
                </div>
                <div className="input-container">
                    <label>Area/Experiencia:</label>
                    <input type="text" defaultValue={powerCoach?.area} onChange={(e) => {
                        powerCoach.area = e.target.value
                    }} />
                </div>
                <div className="input-container">
                    <label>Acerca de:</label>
                    <textarea name="" id="" defaultValue={powerCoach?.about} onChange={(e) => {
                        powerCoach.about = e.target.value
                    }}></textarea>
                </div>
                {socialMedias.map((socialMedia: ISocialMedia, index: number) => {
                    return (
                        <div className="social-media" key={"social-media" + index}>
                            <div className="social">
                                <input type="checkbox" defaultChecked={socialMedia.active} onChange={(e) => {
                                    updateMedia(e.target.checked, "active", socialMedia.name)
                                }} />
                                <p>{socialMedia.name}</p>
                            </div>
                            <input className="link" type="text" placeholder="Link" defaultValue={socialMedia.link}
                                onChange={(e) => {
                                    updateMedia(e.target.value, "link", socialMedia.name)
                                }} />
                        </div>
                    )
                })}
                <p>Conviertete en miembro:</p>
                {Object.keys(becomeMember).map((key, index: number) => {
                    return (
                        <div className="membership" key={"membership" + index}>
                            <input type="checkbox" defaultChecked={becomeMember[key]} onChange={(e) => {
                                setBecomeMember({ ...becomeMember, [key]: e.target.checked })
                            }
                            } />
                            {key === "accessC" && <p>Acceso a la comunidad</p>}
                            {key === "asesoria" && <p>Asesorías</p>}
                            {key === "exclusiveContent" && <p>Contenido exclusivo</p>}
                            {key === "faq" && <p>Preguntas frecuentes</p>}
                            {key === "foro" && <p>Foros</p>}
                            {key === "lives" && <p>En vivos</p>}
                            {key === "merch" && <p>Mercancía Exclusiva</p>}
                            {key === "newCourses" && <p>Cursos nuevos</p>}
                        </div>
                    )
                })}
                <div className="input-container">
                    <label>Color de botones:</label>
                    <input className="color" type="color" value={powerCoach.buttonColor} onChange={(e) => {
                        powerCoach.buttonColor = e.target.value
                    }} />
                </div>
                <div className="input-container">
                    <label>Link preview: (387 x 474) recomendado:</label>
                    <input type="text" placeholder="473696779" defaultValue={powerCoach.previewVideo} onChange={(e) => {
                        powerCoach.previewVideo = e.target.value
                    }} />
                </div>
                <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                    <input type="checkbox" defaultChecked={active} onChange={(e: any) => {
                        setActive(!active)
                    }} />
                    <label>Activo</label>
                </div>
                <button onClick={update}>Guardar</button>
            </div>}
        </div>
    )
}