import axios from "axios";
import { addDoc, collection, doc, getDoc, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../firebase/FirebaseConfig";
import { lecture, lectureCommentI, modulesCourseComponentI, newCommentData } from "../interfaces/ICourse";
import { IForum } from "../interfaces/IForum";
import { getUserImageByEmail } from "./UserService";

export async function getModulesByCourseId(courseId: string, powerCoachId: string) {
	const coursesArray: Array<modulesCourseComponentI> = [];
	const q = query(collection(db, "powerCoaches", powerCoachId, "courses", courseId, "modules"), orderBy("number", "asc"));
	const qSnap = await getDocs(q);

	if (qSnap.size > 0) {
		await Promise.all(
			qSnap.docs.map(async (module) => {
				const moduleData = {
					id: module.id,
					...module.data(),
					lectures: await getLecturesByModuleId(powerCoachId, courseId, module.id),
				} as modulesCourseComponentI;
				coursesArray.push(moduleData);
			})
		);
	}
	return coursesArray;
}

export async function getLecturesByModuleId(powerCoachId: string, courseId: string, moduleId: string) {
	const lecturesArray: Array<lecture> = [];
	const q = query(collection(db, "powerCoaches", powerCoachId, "courses", courseId, "modules", moduleId, "lectures"), orderBy("createdAt", "asc"));
	const qSnap = await getDocs(q);

	if (qSnap.size > 0) {
		qSnap.docs.map((lecture) => {
			const lectureData = {
				id: lecture.id,
				...lecture.data(),
			} as lecture;

			lecturesArray.push(lectureData);
		});
	}
	return lecturesArray;
}

export async function getVideoData(videoId: number) {
	return await axios
		.get("https://vimeo.com/api/oembed.json?url=" + "https://vimeo.com/" + videoId)
		.then((res: any) => {
			return res.data;
		})
		.catch((error) => {
			console.log(error);
			return error;
		});
}

export async function getCommentsByLectureId(powerCoachId: string, courseId: string, moduleId: string, lectureId: string) {
	const commentsArray: Array<lectureCommentI> = [];
	const q = query(
		collection(db, "powerCoaches", powerCoachId, "courses", courseId, "modules", moduleId, "lectures", lectureId, "comments"),
		orderBy("createAt", "desc")
	);
	const qSnap = await getDocs(q);

	if (qSnap.size > 0) {
		await Promise.all(
			qSnap.docs.map(async (comment) => {
				const commentData = {
					id: comment.id,
					...comment.data(),
					photo: await getUserImageByEmail(comment.data().email),
				} as lectureCommentI;

				commentsArray.push(commentData);
			})
		);
	}
	return commentsArray;
}

export async function newCommentForLecture(powerCoachId: string, courseId: string, moduleId: string, lectureId: string, commentData: newCommentData) {
	await addDoc(
		collection(db, "powerCoaches", powerCoachId, "courses", courseId, "modules", moduleId, "lectures", lectureId, "comments"),
		{
			...commentData,
		}
	);
	return "comentario agregado con éxito!"
}

export async function getForumById(powerCoachId: string, forumId: string) {
	const docRef = doc(db, "powerCoaches", powerCoachId, "community", forumId);
	let forumData: IForum | null = null;
	const docSnap = await getDoc(docRef);

	if(docSnap.exists()) {
		forumData = {
			...docSnap.data(),
			id: docSnap.id
		} as IForum
	}

	return forumData
}