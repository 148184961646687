import { powerCoach } from "../../../../../../interfaces/PowerCoaches";

interface AboutCardProps {
  powerCoach: powerCoach;
}

export function AboutCard(props: AboutCardProps) {
  return (
    <div className="card about-card">
      <h2>Acerca de</h2>
      <div className="about-description-container">
        <p>{props.powerCoach.about}</p>
      </div>
      <div className="social-icons-container">
        {props.powerCoach.facebook.active && (
          <a href={props.powerCoach.facebook.link} target="_blank">
            <img src="/assets/images/facebook-icon.svg" alt="facebook-icon" />
          </a>
        )}
        {props.powerCoach.twitter.active && (
          <a href={props.powerCoach.twitter.link} target="_blank">
            <img src="/assets/images/twitter-icon.svg" alt="twitter-icon" />
          </a>
        )}
        {props.powerCoach.youtube.active && (
          <a href={props.powerCoach.youtube.link} target="_blank">
            <img src="/assets/images/youtube-icon.svg" alt="youtube-icon" />
          </a>
        )}
        {props.powerCoach.reddit.active && (
          <a href={props.powerCoach.reddit.link} target="_blank">
            <img src="/assets/images/reddit-icon.svg" alt="reddit-icon" />
          </a>
        )}
        {props.powerCoach.telegram.active && (
          <a href={props.powerCoach.telegram.link} target="_blank">
            <img src="/assets/images/telegram.svg" alt="twitch-icon" />
          </a>
        )}
        {props.powerCoach.instagram.active && (
          <a href={props.powerCoach.instagram.link} target="_blank">
            <img src="/assets/images/instagram.svg" alt="instagram-icon" />
          </a>
        )}
        {props.powerCoach.tiktok.active && (
          <a href={props.powerCoach.tiktok.link} target="_blank">
            <img src="/assets/images/tiktok.svg" alt="tiktok-icon" />
          </a>
        )}
      </div>
    </div>
  );
}
