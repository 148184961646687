import {
    collection,
    doc,
    getDocs,
    updateDoc,
    query,
    where
} from "firebase/firestore";
import { db } from "../firebase/FirebaseConfig";

export const getAllUsers = async () => {
    let data: any = [];
    const docRef = collection(db, "users");
    const querySnapshot = await getDocs(docRef);
    querySnapshot.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
    });
    return data;
}

export const updateUser = async (user: any) => {
    const userRef = doc(db, "users", user.id);
    await updateDoc(userRef, {
        name: user.name,
        birthdate: user.birthdate,
        powerCoach: user.powerCoach
    });
};

export const getAllPowercoach = async () => {
    let data: any = [];
    const docRef = collection(db, "powerCoaches");
    const querySnapshot = await getDocs(docRef);
    querySnapshot.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id, email: "" });
    });
    await Promise.all(data.map(async (element: any) => {
        if (!element.commission) {
            element.commission = 0
        }
        if (!element.commissionPaypal) {
            element.commissionPaypal = 0
        }
        if (!element.commissionStripe) {
            element.commissionStripe = 0
        }
        const docRef = query(collection(db, "users"), where("userId", "==", element.id));
        const docSnap = await getDocs(docRef);
        if (docSnap.size === 1) {
            element.email = docSnap.docs[0].data().email;
        }
    }))
    return data;
}

export const updatePowerCoachCommissions = async (powercoach: any) => {
    const userRef = doc(db, "powerCoaches", powercoach.id);
    await updateDoc(userRef, {
        commission: powercoach.commission,
        commissionPaypal: powercoach.commissionPaypal,
        commissionStripe: powercoach.commissionStripe
    });
};