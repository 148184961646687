import { useCallback, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { Spinner } from "../../../../components/spinner/Spinner";
import { CommunityCards, powerCoachContext, premiumContent, } from "../../../../interfaces/PowerCoaches";
import {
	getCommunityCards,
	getLastCommunity,
	getLastCourse,
	getLastLive,
	getRecentDonors,
} from "../../../../services/PowerCoachService";
import { AboutCard } from "./components/aboutCard/AboutCard";
import { CommunityCard } from "./components/communityCard/CommunityCard";
import { PremiumBenefitsCard } from "./components/premiumBenefits/PremiumBenefits";
import { SubscriptionCard } from "./components/subscriptionCard/SubscriptionCard";
import "./GeneralPowerCoach.scss";


export function GeneralPowerCoach() {
	const { powerCoach, }: powerCoachContext = useOutletContext();
	const [loadingCommunities, setLoadingCommunities] = useState<boolean>(true);
	const [communities, setCommunities] = useState<Array<CommunityCards>>([]);
	const [loadingCommunitiesPremium, setLoadingCommunitiesPremium] = useState<boolean>(true);

	const [premiumContent, setPremiumContent] = useState<premiumContent>({
		lastCommunity: null,
		lastLive: null,
		lastCourse: null,
	});

	const getCommunities = useCallback( async () => {
		const communityArray: Array<CommunityCards> = await getCommunityCards( powerCoach.id );
		setCommunities(communityArray);
		setLoadingCommunities(false);
	}, [ powerCoach.id ] );

	const getPremiumContent = useCallback( async () => {
		setPremiumContent({
			lastCommunity: await getLastCommunity(powerCoach.id),
			lastLive: await getLastLive(powerCoach.id),
			lastCourse: await getLastCourse(powerCoach.id),
		});
		setLoadingCommunitiesPremium(false);
	}, [powerCoach.id] ) ;

	const getLastDonors = useCallback ( async () => {
		await getRecentDonors(powerCoach.id)
	}, [ powerCoach.id ] );

	useEffect(() => {
		document.documentElement.style.setProperty("--power-coach-color", powerCoach.buttonColor);
		document.documentElement.style.setProperty("--power-coach-bg-color", powerCoach.buttonColor + 27);
		getCommunities();
		getPremiumContent();
		getLastDonors();
	}, [ getCommunities, getPremiumContent, getLastDonors, powerCoach.buttonColor ]);

	return (
		<div className="general-pc-main-container">
			<div className="top-container">
				<div className="about-benefits-container">
					<AboutCard powerCoach={powerCoach} />
					<PremiumBenefitsCard benefits={powerCoach.becomeMember} />
				</div>
				<div className="subscription-container">
					<SubscriptionCard />
				</div>
			</div>
			<div className="card secondary-container">
				{loadingCommunities ? (
					<Spinner width={1} size={12} color={powerCoach.buttonColor} />
				) : (
					communities.length > 0 && (
						<section className="community-container">
							<h2>Comunidad</h2>
							<div className="community-cards-container">
								{communities.map((community, i) => {
									return <CommunityCard community={community} key={"community-card-" + i} />;
								})}
							</div>
						</section>
					)
				)}
				{/* {recentDonors.length > 0 && (
					<section className="donors-container">
						<h2>Donadores recientes</h2>
						<div className="donors-cards-container">
							{recentDonors.map((donor, i) => {
								return <DonorsCard profileImage={donor.profileImage} userName={donor.userName} key={"recent-donor-card-" + i} />;
							})}
						</div>
					</section>
				)} */}
				{loadingCommunitiesPremium ? (
					<Spinner width={1} size={12} color={powerCoach.buttonColor} />
				) : (
					<section className="premium-container">
						<h2>Contenido premium</h2>
						<div className="community-cards-container justify-content-center">
							{premiumContent.lastCourse !== null && (
								<CommunityCard
									community={{
										id: premiumContent.lastCourse.id,
										createAt: new Date(),
										description: premiumContent.lastCourse.description.slice(0, 100) + "...",
										imageCommunity: premiumContent.lastCourse.courseImage,
										socialNetwork: premiumContent.lastCourse.title,
									}}
									premium={true}
								/>
							)}
							{premiumContent.lastLive !== null && (
								<CommunityCard
									community={{
										id: premiumContent.lastLive.id,
										createAt: new Date(),
										description: premiumContent.lastLive.description,
										imageCommunity: premiumContent.lastLive.liveBanner,
										socialNetwork: "En vivo: " + premiumContent.lastLive.title,
									}}
									premium={true}
								/>
							)}
							{premiumContent.lastCommunity !== null && (
								<CommunityCard
									community={{
										id: premiumContent.lastCommunity.id,
										createAt: new Date(),
										description: premiumContent.lastCommunity.description,
										imageCommunity: premiumContent.lastCommunity.imageCommunity,
										socialNetwork: premiumContent.lastCommunity.socialNetwork,
									}}
									premium={true}
								/>
							)}
						</div>
					</section>
				)}
			</div>
		</div>
	);
}
