import { doc, onSnapshot, setDoc } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { NavLink, Outlet, useNavigate, useParams } from "react-router-dom";
import { Spinner } from "../../components/spinner/Spinner";
import { UserContext } from "../../context/UserContext";
import { db } from "../../firebase/FirebaseConfig";
import { PowerCoachSubI } from "../../interfaces/IUser";
import { powerCoach } from "../../interfaces/PowerCoaches";
import { getFreeCoursesByPowerCoachId, getPowerCoachByName } from "../../services/PowerCoachService";
import { getPowerCoachSubByUser, retrieveUserSubs } from "../../services/UserService";
import "./PowerCoach.scss";

export function PowerCoach() {
	const params = useParams();
	const [powerCoach, setPowerCoach] = useState<powerCoach>();
	const [freeContent, setFreeContent] = useState<boolean>(false);
	const [loadingPowerCoach, setLoadingPowerCoach] = useState<boolean>(true);
	const [powerCoachSub, setPowerCoachSub] = useState<PowerCoachSubI | null>();
	const user = useContext(UserContext);
	const navigate = useNavigate();
	let today = new Date().getTime() / 1000;

	const getPowerCoach = async () => {
		const pcName = params.powercoach;

		if (pcName) {
			const powerCoachData = await getPowerCoachByName(pcName);
			if (powerCoachData === null) {
				navigate("/");
			} else {
				const freeCoursesAvailable = await getFreeCoursesByPowerCoachId(powerCoachData!.id);
				setFreeContent(freeCoursesAvailable.length > 0);
				setPowerCoach(powerCoachData);
				if (user !== null) {
					getPowerCoachSub(powerCoachData);
				} else {
					setPowerCoachSub(null);
					setLoadingPowerCoach(false);
				}
			}
		}
	};

	const getPowerCoachSub = async (powerCoachData: powerCoach) => {
		const docRef = doc(db, "users", user.id, "powerCoachesSub", powerCoachData.id);

		const checkBoth = onSnapshot(
			doc(db, "users", user.id, "powerCoachesSub", "ambos"),
			{ includeMetadataChanges: true }, (docDataBoth) => {
				setLoadingPowerCoach(true);
				if (docDataBoth.exists()) {
					if (docDataBoth.data().final_date !== null && docDataBoth.data().final_date.seconds > today) {
						const subData = {
							id: docDataBoth.id,
							...docDataBoth.data(),
						} as PowerCoachSubI;
						setPowerCoachSub(subData);
						setLoadingPowerCoach(false);
					} else {
						const unsub = onSnapshot(
							doc(db, "users", user.id, "powerCoachesSub", powerCoachData.id),
							{ includeMetadataChanges: true },
							async (doc: any) => {
								setLoadingPowerCoach(true);
								if (!doc.exists()) {
									await setDoc(docRef, {
										stripe_id: "",
										final_date: null,
										initial_date: null,
										active: false,
										firstTimeSub: true,
										cancel_at_period_end: false,
										days: 0,
									});
								} else {
									const subData = {
										id: doc.id,
										...doc.data(),
									} as PowerCoachSubI;
									if (subData.final_date === null || subData.final_date.seconds < today) {
										navigate("/academia");
									}
									setPowerCoachSub(subData);
									setLoadingPowerCoach(false);
								}
							}
						);
					}
				} else {
					const unsub = onSnapshot(
						doc(db, "users", user.id, "powerCoachesSub", powerCoachData.id),
						{ includeMetadataChanges: true },
						async (doc: any) => {
							setLoadingPowerCoach(true);
							if (!doc.exists()) {
								await setDoc(docRef, {
									stripe_id: "",
									final_date: null,
									initial_date: null,
									active: false,
									firstTimeSub: true,
									cancel_at_period_end: false,
									days: 0,
								});
							} else {
								const subData = {
									id: doc.id,
									...doc.data(),
								} as PowerCoachSubI;
								if (subData.final_date === null || subData.final_date.seconds < today) {
									navigate("/academia");
								}
								setPowerCoachSub(subData);
								setLoadingPowerCoach(false);
							}
						}
					);
				}
			})
	};

	useEffect(() => {
		getPowerCoach();
	}, []);

	if (loadingPowerCoach) return <Spinner size={10} width={1.5} />;

	if (powerCoach !== undefined) {
		return (
			<div className="powercoach-main-container">
				<div className="personal-banner-container">
					<img src={powerCoach.bannerLink} alt="" />
				</div>
				<div className="power-coach-content-container">
					<div className="power-coach-top-container">
						<div className="profile-pic-container">
							<img src={powerCoach.imageLink} alt="profile pic" />
						</div>
						<div className="name-followers-container">
							<h2>{powerCoach.name}</h2>
							<p>{powerCoach.students} alumnos</p>
						</div>
					</div>
					<div className="power-coach-profile-nav-container">
						<ul>
							<NavLink
								end
								to={""}
								style={(navData) =>
									navData.isActive
										? {
											color: powerCoach.buttonColor,
											borderColor: powerCoach.buttonColor,
										}
										: {}
								}
							>
								<li>General</li>
							</NavLink>
							<NavLink
								to={"premium"}
								style={(navData) =>
									navData.isActive
										? {
											color: powerCoach.buttonColor,
											borderColor: powerCoach.buttonColor,
										}
										: {}
								}
							>
								<li>Premium</li>
							</NavLink>
							<NavLink
								to={"foros"}
								style={(navData) =>
									navData.isActive
										? {
											color: powerCoach.buttonColor,
											borderColor: powerCoach.buttonColor,
										}
										: {}
								}
							>
								<li>Foros</li>
							</NavLink>
							{freeContent && (
								<NavLink
									to={"gratis"}
									style={(navData) =>
										navData.isActive
											? {
												color: powerCoach.buttonColor,
												borderColor: powerCoach.buttonColor,
											}
											: {}
									}
								>
									<li>Gratis</li>
								</NavLink>
							)}
						</ul>
						<div className="bottom-border"></div>
					</div>
					<div className="bottom-border"></div>
				</div>
				<Outlet context={{ powerCoach: powerCoach, powerCoachSub: powerCoachSub }} />
			</div>
		);
	} else {
		return <></>;
	}
}
