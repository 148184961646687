import {
	collection,
	getCountFromServer,
	getDocs,
	limit,
	orderBy,
	query,
	where,
	getDoc,
	doc,
	setDoc,
	addDoc,
	updateDoc,
	deleteDoc,
} from "firebase/firestore";
import { db } from "../firebase/FirebaseConfig";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import {
	CategoryCoursesPowerCoachI,
	CommunityCards,
	CourseI,
	CoursesPowercoachI,
	LiveI,
	powerCoach,
	RecentDonorsI,
} from "../interfaces/PowerCoaches";
import { IForum } from "../interfaces/IForum";
import { modulesCourseComponentI } from "../interfaces/ICourse";
import axios from "axios";

export async function getPowerCoachByName(name: string) {
	const docRef = query(collection(db, "powerCoaches"), where("name", "==", name));
	const docSnap = await getDocs(docRef);

	if (docSnap.size === 1) {
		const powerCoachDoc = docSnap.docs[0];
		const powerCoachData = {
			id: powerCoachDoc.id,
			...powerCoachDoc.data(),
		} as powerCoach;

		return powerCoachData;
	}
	return null;
}

export async function getCommunityCards(powerCoachId: string) {
	const q = query(collection(db, "powerCoaches", powerCoachId, "comunidad"));
	const docsSnap = await getDocs(q);
	const comunityCardsArray: Array<CommunityCards> = [];

	if (docsSnap.size > 0) {
		docsSnap.docs.map((community) => {
			const data = {
				id: community.id,
				...community.data(),
			} as CommunityCards;

			comunityCardsArray.push(data);
		});
	}
	return comunityCardsArray;
}

export async function getCommunityPremiumCards(powerCoachId: string) {
	const q = query(collection(db, "powerCoaches", powerCoachId, "community"));
	const docsSnap = await getDocs(q);
	const comunityCardsArray: Array<CommunityCards> = [];

	if (docsSnap.size > 0) {
		docsSnap.docs.map((community) => {
			const data = {
				id: community.id,
				...community.data(),
			} as CommunityCards;

			comunityCardsArray.push(data);
		});
	}
	return comunityCardsArray;
}

export async function getLastCommunity(powerCoachId: string) {
	const q = query(collection(db, "powerCoaches", powerCoachId, "community"), orderBy("createAt", "desc"), limit(1));
	const docsSnap = await getDocs(q);
	const lastCommunityArray: Array<CommunityCards> = [];

	if (docsSnap.size === 1) {
		docsSnap.docs.map((course) => {
			const data = {
				id: course.id,
				...course.data(),
			} as CommunityCards;

			lastCommunityArray.push(data);
		});
	}
	return lastCommunityArray.length === 1 ? lastCommunityArray[0] : null;
}

export async function getLastCourse(powerCoachId: string) {
	const q = query(collection(db, "powerCoaches", powerCoachId, "courses"), orderBy("createdAt", "desc"), limit(1));
	const docsSnap = await getDocs(q);
	const coursesArray: Array<CourseI> = [];

	if (docsSnap.size === 1) {
		docsSnap.docs.map((course) => {
			const data = {
				id: course.id,
				...course.data(),
			} as CourseI;

			coursesArray.push(data);
		});
	}
	return coursesArray.length === 1 ? coursesArray[0] : null;
}

export async function getLastLive(powerCoachId: string) {
	const q = query(collection(db, "powerCoaches", powerCoachId, "live"), orderBy("date", "desc"), limit(1));
	const docsSnap = await getDocs(q);
	const livesArray: Array<LiveI> = [];

	if (docsSnap.size === 1) {
		docsSnap.docs.map((live) => {
			const data = {
				id: live.id,
				...live.data(),
			} as LiveI;

			livesArray.push(data);
		});
	}
	return livesArray.length === 1 ? livesArray[0] : null;
}

export async function getRecentDonors(powerCoachId: string) {
	const q = query(collection(db, "powerCoaches", powerCoachId, "donadores"), orderBy("date", "desc"), limit(20));
	const docsSnap = await getDocs(q);
	const recentDonorsArray: Array<RecentDonorsI> = [];

	if (docsSnap.size > 0) {
		docsSnap.docs.map((donor) => {
			const data: RecentDonorsI = {
				id: donor.id,
				profileImage: donor.data().userImage,
				userName: donor.data().name,
			};

			recentDonorsArray.push(data);
		});
	}

	return recentDonorsArray;
}

export async function getCategories(powerCoachId: string) {
	const q = query(collection(db, "powerCoaches", powerCoachId, "categories"));
	const docsSnap = await getDocs(q);
	const categoriesArray: Array<CategoryCoursesPowerCoachI> = [];

	if (docsSnap.size > 0) {
		docsSnap.docs.map((category) => {
			const data: CategoryCoursesPowerCoachI = {
				id: category.id,
				title: category.data().title,
				subTitle: category.data().subTitle,
				courses: [],
			};

			categoriesArray.push(data);
		});
	}

	return categoriesArray;
}

export async function getCoursesByCategory(powercoachId: string, categoryId: string) {
	const q = query(
		collection(db, "powerCoaches", powercoachId, "courses"),
		where("category", "==", categoryId),
		where("active", "==", true)
	);
	const docsSnap = await getDocs(q);
	const coursesArray: Array<CoursesPowercoachI> = [];

	if (docsSnap.size > 0) {
		await Promise.all(
			docsSnap.docs.map(async (course) => {
				const data = {
					id: course.id,
					...course.data(),
					modulesNumber: await getModulesNumberByCourse(powercoachId, course.id),
				} as CoursesPowercoachI;

				coursesArray.push(data);
			})
		);
	}

	return coursesArray;
}

export async function getModulesNumberByCourse(powerCoachId: string, courseId: string) {
	const courseModulesColl = collection(db, "powerCoaches", powerCoachId, "courses", courseId, "modules");
	const snap = await getCountFromServer(courseModulesColl);

	return snap.data().count;
}

export async function getFreeCoursesByPowerCoachId(powerCoachId: string) {
	const q = query(
		collection(db, "powerCoaches", powerCoachId, "courses"),
		where("type", "==", "free"),
		where("active", "==", true)
	);
	const docsSnap = await getDocs(q);
	const coursesArray: Array<CoursesPowercoachI> = [];
	if (docsSnap.size > 0) {
		await Promise.all(
			docsSnap.docs.map(async (course) => {
				const data = {
					id: course.id,
					...course.data(),
					modulesNumber: await getModulesNumberByCourse(powerCoachId, course.id),
				} as CoursesPowercoachI;

				if (data.modulesNumber > 0) {
					coursesArray.push(data);
				}
			})
		);
	}

	return coursesArray;
}

export async function getCourseById(powerCoachId: string, courseId: string) {
	const docRef = doc(db, "powerCoaches", powerCoachId, "courses", courseId);
	const docSnap = await getDoc(docRef);

	if (docSnap.exists()) {
		const courseData = {
			id: docSnap.id,
			...docSnap.data(),
		} as CourseI;

		return courseData;
	}
	return null;
}
export async function getPowerCoachById(id: string) {
	const docRef = doc(db, "powerCoaches", id);
	const docSnap = await getDoc(docRef);
	if (docSnap.exists()) {
		return { ...docSnap.data(), id: docSnap.id };
	}
}
export async function getPowerCoaches() {
	let data: any = [];
	const docRef = collection(db, "powerCoaches");
	const querySnapshot = await getDocs(docRef);
	querySnapshot.forEach((doc) => {
		data.push({ ...doc.data(), id: doc.id });
	});
	return data;
}
export const updateProfile = async (powercoach: any) => {
	const userRef = doc(db, "powerCoaches", powercoach.id);
	await setDoc(userRef, powercoach);
};

export const createForum = async (powercoachId: string, forum: IForum) => {
	const docRef = await addDoc(collection(db, "powerCoaches", powercoachId, "community"), {
		...forum,
		imageCommunity: "",
	});
	forum.id = docRef.id;
	const result = await uploadFile(forum.imageCommunity, `forums/${docRef.id}`);
	const userRef = doc(db, "powerCoaches", powercoachId, "community", docRef.id);
	await updateDoc(userRef, {
		imageCommunity: result,
		createAt: new Date(),
	});
};

export const updateForumInfo = async (powercoachId: string, forum: any) => {
	const docRef = doc(db, "powerCoaches", powercoachId, "community", forum.id);
	let result;
	if (!(typeof forum.imageCommunity === "string")) {
		result = await uploadFile(forum.imageCommunity, `forums/${forum.id}`);
	}
	await updateDoc(docRef, {
		...forum,
		imageCommunity: result || forum.imageCommunity,
	});
};

export const retrieveForums = async (powercoachId: string) => {
	let data: any = [];
	const docRef = collection(db, "powerCoaches", powercoachId, "community");
	const querySnapshot = await getDocs(docRef);
	querySnapshot.forEach((doc) => {
		data.push({ ...doc.data(), id: doc.id });
	});
	return data;
};

export const uploadFile = async (file: File, uploadPath: string) => {
	const storage = getStorage();
	const storageRef = ref(storage, uploadPath);
	return new Promise(async (resolve, reject) => {
		await uploadBytes(storageRef, file).then((snapshot) => {
			getDownloadURL(snapshot.ref).then((downloadURL) => {
				resolve(downloadURL);
			});
		});
	});
};

export const createCategory = async (powercoachId: string, category: CategoryCoursesPowerCoachI) => {
	const docRef = await addDoc(collection(db, "powerCoaches", powercoachId, "categories"), category);
	category.id = docRef.id;
};

export const updateCategoryInfo = async (powercoachId: string, category: any) => {
	const docRef = doc(db, "powerCoaches", powercoachId, "categories", category.id);
	delete category.id;
	await updateDoc(docRef, category);
};

export const retrieveCategories = async (powercoachId: string) => {
	let data: any = [];
	const docRef = collection(db, "powerCoaches", powercoachId, "categories");
	const querySnapshot = await getDocs(docRef);
	querySnapshot.forEach((doc) => {
		data.push({ ...doc.data(), id: doc.id });
	});
	return data;
};

export const createCourse = async (powercoachId: string, course: any) => {
	const docRef = await addDoc(collection(db, "powerCoaches", powercoachId, "courses"), {
		...course,
		courseImage: "",
		bannerImage: "",
	});
	course.id = docRef.id;
	const cover = await uploadFile(course.courseImage, `courses/cover/${docRef.id}`);
	const banner = await uploadFile(course.bannerImage, `courses/banner/${docRef.id}`);
	const userRef = doc(db, "powerCoaches", powercoachId, "courses", docRef.id);
	await updateDoc(userRef, {
		courseImage: cover,
		bannerImage: banner,
		createAt: new Date(),
	});
};

export const retrieveCourses = async (powercoachId: string) => {
	let data: any = [];
	const docRef = collection(db, "powerCoaches", powercoachId, "courses");
	const querySnapshot = await getDocs(docRef);
	querySnapshot.forEach((doc) => {
		data.push({ ...doc.data(), id: doc.id });
	});
	return data;
};

export const updateCourseInfo = async (powercoachId: string, course: any) => {
	const docRef = doc(db, "powerCoaches", powercoachId, "courses", course.id);
	let cover;
	let banner;
	if (!(typeof course.courseImage === "string")) {
		cover = await uploadFile(course.courseImage, `courses/cover/${docRef.id}`);
	}
	if (!(typeof course.bannerImage === "string")) {
		banner = await uploadFile(course.bannerImage, `courses/banner/${docRef.id}`);
	}
	await updateDoc(docRef, {
		...course,
		courseImage: cover || course.courseImage,
		bannerImage: banner || course.bannerImage,
	});
};

export const createModule = async (powercoachId: string, courseId: string, module: modulesCourseComponentI) => {
	const docRef = await addDoc(collection(db, "powerCoaches", powercoachId, "courses", courseId, "modules"), module);
	module.id = docRef.id;
};

export const updateModuleInfo = async (powercoachId: string, courseId: string, module: any) => {
	const docRef = doc(db, "powerCoaches", powercoachId, "courses", courseId, "modules", module.id);
	delete module.id;
	await updateDoc(docRef, module);
};

export const createLesson = async (powercoachId: string, courseId: string, moduleId: string, lesson: any) => {
	await addDoc(collection(db, "powerCoaches", powercoachId, "courses", courseId, "modules", moduleId, "lectures"), {
		...lesson,
		createdAt: new Date(),
	});
};

export const updateLessonInfo = async (powercoachId: string, courseId: string, moduleId: string, lesson: any) => {
	const docRef = doc(db, "powerCoaches", powercoachId, "courses", courseId, "modules", moduleId, "lectures", lesson.id);
	delete lesson.id;
	await updateDoc(docRef, lesson);
};

export const deleteLesson = async (powercoachId: string, courseId: string, moduleId: string, lesson: any) => {
	await deleteDoc(doc(db, "powerCoaches", powercoachId, "courses", courseId, "modules", moduleId, "lectures", lesson.id));
};

export const updatePowercoachInfo = async (powercoachId: string, subscription: any, data: any) => {
	await addDoc(collection(db, "powerCoaches", powercoachId, "subscriptions"), {
		...subscription,
	});
	const powerCoachRef = doc(db, "powerCoaches", powercoachId);
	await updateDoc(powerCoachRef, {
		...data,
	});
};

export const retrieveSubscriptions = async (powercoachId: string) => {
	let data: any = [];
	const docRef = collection(db, "powerCoaches", powercoachId, "subscriptions");
	const querySnapshot = await getDocs(docRef);
	querySnapshot.forEach((doc) => {
		data.push({ ...doc.data(), id: doc.id });
	});
	return data;
};

export const updatePowerCoachSubscriptionMonth = async (powercoachId: string, subscription: any) => {
	const docRef = doc(db, "powerCoaches", powercoachId);
	await updateDoc(docRef, {
		priceId: subscription.priceId,
		precioCard: subscription.precioCard,
		paypalPriceId: subscription.paypalPriceId,
	});
};

export const updatePowerCoachSubscriptionYear = async (powercoachId: string, subscription: any) => {
	const docRef = doc(db, "powerCoaches", powercoachId);
	await updateDoc(docRef, {
		priceId_year: subscription.priceId_year,
		precioCard_year: subscription.precioCard_year,
		paypalPriceId_year: subscription.paypalPriceId_year,
	});
};

export const updatePowerCoach = async (powerCoachId: string, data: any) => {
	const docRef = doc(db, "powerCoaches", powerCoachId);
	await updateDoc(docRef, {
		...data,
	});
};

export const retrievePowerCoachInvoices = async () => {
	let data: any = [];
	const docRef = collection(db, "invoices");
	const querySnapshot = await getDocs(docRef);
	querySnapshot.forEach((doc) => {
		data.push({ ...doc.data(), id: doc.id });
	});
	return data;
};

export const createInvoice = async (invoice: any) => {
	await addDoc(collection(db, "invoices"), invoice);
};

export const addCommunityCard = async (powercoachId: string, community: CommunityCards) => {
	community.createAt = new Date();
	await addDoc(collection(db, "powerCoaches", powercoachId, "comunidad"), community);
};

export const updatePowerCoachCommunityCard = async (powercoachId: string, community: CommunityCards) => {
	const docRef = doc(db, "powerCoaches", powercoachId, "comunidad", community.id);
	await updateDoc(docRef, {
		description: community.description,
		link: community.link,
		socialNetwork: community.socialNetwork,
	});
};

export const retrievePowerCoachSocialNetworks = async (powerCoachId: string) => {
	let data: any = [];
	const docRef = query(collection(db, "socialNetwork"), where("powerCoachId", "==", powerCoachId));
	const querySnapshot = await getDocs(docRef);
	querySnapshot.forEach((doc) => {
		data.push({ ...doc.data(), id: doc.id });
	});
	return data;
};

export const cancelPaypal = async (user: any) => {
	const clientIdAndSecret =
		"AXn-e1UCYo0EoE0WZTj-_1KRHkk0xEA-ei_XWLYDWAq0qmfaIC_6LYdDMB0DNOEsfisf5A5G80_MxIbn:EOxl0Bqw185x0oIRqKGYk3-usZtWis-SH8qqgZPXXudsmKGilDWzUKUz1g8HrDl_qQYWmseErb9CgXjo";
	const base64: any = btoa(clientIdAndSecret);
	let body = {
		grant_type: "client_credentials",
	};
	return axios
		.post("https://api-m.paypal.com/v1/oauth2/token", body, {
			headers: {
				Accept: "application/json",
				"Accept-Language": "en_US",
				"content-type": "application/x-www-form-urlencoded",
				Authorization: `Basic ${base64}`,
			},
		})
		.then((res) => {
			console.log(user);

			return axios
				.post(
					`https://api-m.paypal.com/v1/billing/subscriptions/${user.planId}/cancel`,
					{ body: JSON.stringify({ reason: "Not satisfied with the service" }) },
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${res.data.access_token}`,
						},
					}
				)
				.then(async (result) => {
					return result;
				})
				.catch((error) => {
					console.log(error);
					return error;
				});
		})
		.catch((error) => {
			console.log(error);
			return error;
		});
};

export const deleteCommunityById = async (powercoachId: string, communityId: string) => {
	await deleteDoc(doc(db, "powerCoaches", powercoachId, "comunidad", communityId));
};
