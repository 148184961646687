import { useEffect, useState } from "react";
import { MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos } from "react-icons/md";
import { useOutletContext } from "react-router-dom";
import { Spinner } from "../../../../components/spinner/Spinner";
import { powerCoach, socialNetworks } from "../../../../interfaces/PowerCoaches";
import { retrievePowerCoachSocialNetworks } from "../../../../services/PowerCoachService";
import { getPowerCoachSubByUser } from "../../../../services/UserService";
import "./PowerCoachSocialNetworks.scss";

export function PowerCoachSocialNetworks() {
    const powerCoach: powerCoach = useOutletContext();
    const [socialNetworks, setSocialNetworks] = useState<Array<socialNetworks>>([]);
    const [pages, setPages] = useState<any>([]);
    const [page, setPage] = useState(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [option, setOption] = useState<string>("all");

    useEffect(() => {
        if (powerCoach) {
            getSocialNetworks();
        }
    }, [powerCoach])

    const getSocialNetworks = () => {
        retrievePowerCoachSocialNetworks("VoQIZfjMmBS5pXdUwfUhC4d2aot2").then((res) => {
            setSocialNetworks(res);
            setPages(getPages(res));
            setIsLoading(false);
        })
    }

    const getPages = (users: any) => {
        let tempPages = Math.ceil(users.length / 50)
        let tempArray: any = [];
        for (let i = 0; i < tempPages; i++) {
            tempArray.push([]);
        }
        let total = 50;
        let count = 0;
        for (let i = 0; i < tempPages; i++) {
            for (let w = 0; w <= users.length; w++) {
                if (w >= count && tempArray[i].length < total) {
                    if (users[w] && tempArray[i].findIndex((e: any) => e === users[w]) === -1)
                        tempArray[i].push(users[w]);
                    count++;
                }
            }
        }
        setIsLoading(false);
        return tempArray;
    }

    const filter = (search: string) => {
        let tempUsers = socialNetworks.filter((x: socialNetworks) => (x.userName.includes(search)))
        setPages(getPages(tempUsers));
    }

    const handleSelect = async (value: string) => {
        setIsLoading(true);
        if (value === "all") {
            setPages(getPages(socialNetworks));
        }
        if (value === "active") {
            let tempUsers: any = [];
            await Promise.all(socialNetworks.map(async (x) => {
                await getPowerCoachSubByUser(x.userId, x.powerCoachId).then((res: any) => {
                    if (res && res.final_date) {
                        let today = new Date().getTime() / 1000;
                        if (res.final_date?.seconds > today) {
                            tempUsers.push(x);
                        }
                    }
                })
            }))
            setPages(getPages(tempUsers));
        }
        if (value === "inactive") {
            let tempUsers: any = [];
            await Promise.all(socialNetworks.map(async (x) => {
                await getPowerCoachSubByUser(x.userId, x.powerCoachId).then((res: any) => {
                    if (res) {
                        let today = new Date().getTime() / 1000;
                        if (res.final_date?.seconds < today || !res.final_date) {
                            tempUsers.push(x);
                        }
                    }
                })
            }))
            setPages(getPages(tempUsers));
        }
    }


    return (
        <div className="powercoach-socialNetworks-container">
            <div className="top">
                <h2>Usuarios de Telegram y Whatsapp</h2>
                <input className="filter" type="text" placeholder="Buscar usuario" onChange={(e) => {
                    filter(e.target.value)
                }} />
            </div>
            <div className="pagination">
                {page > 0 && < MdOutlineArrowBackIosNew onClick={() => {
                    setPage(page - 1);
                }} />}
                <div className="page">
                    <p>{page + 1}</p>
                </div>
                <MdOutlineArrowForwardIos onClick={() => {
                    setPage(page + 1);
                }} />
            </div>
            <select onChange={(e) => {
                handleSelect(e.target.value);
            }}>
                <option value="all">Todos</option>
                <option value="active">Activos</option>
                <option value="inactive">Inactivos</option>
            </select>
            {!isLoading ? <div className="social-container">
                {pages[page]?.map((x: socialNetworks, index: number) => {
                    return (
                        <div className="user-card" key={"social-user" + index}>
                            <p>{x.userName}</p>
                            <p>Cel: {x.lada} {x.cel}</p>
                            <p>Grupo: {x.socialNetwork}</p>
                        </div>
                    )
                })}
            </div> :
                <Spinner width={.5} size={4}></Spinner>}
        </div>
    )
}