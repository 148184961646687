import { useEffect, useState } from "react";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { useOutletContext } from "react-router-dom";
import { Spinner } from "../../../../components/spinner/Spinner";
import { CategoryCoursesPowerCoachI, CourseI, CoursesPowercoachI } from "../../../../interfaces/PowerCoaches";
import { createCourse, retrieveCategories, retrieveCourses, updateCourseInfo } from "../../../../services/PowerCoachService";
import { PowerCoachCourseLessons } from "./components/lectures/PowerCoachCourseLessons";
import { PowerCoachCourseModules } from "./components/modules/PowerCoachCourseModules";
import "./PowerCoachCourses.scss";

export function PowerCoachCourses() {
    const powerCoach: any = useOutletContext();
    const [step, setStep] = useState(0);
    const [cover, setCover] = useState<any>("/assets/placeholders/background.png");
    const [banner, setBanner] = useState<any>("/assets/placeholders/background.png");
    const [course, setCourse] = useState({} as CourseI);
    const [courses, setCourses] = useState<Array<CourseI>>([]);
    const [module, setModule] = useState({});
    const [active, setActive] = useState<boolean>(false)
    const [categories, setCategories] = useState<Array<CategoryCoursesPowerCoachI>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (powerCoach) {
            retrieveCategories(powerCoach.id).then((res) => {
                setCategories(res);
            })
            getCourses();
        }
    }, [powerCoach])

    const getCourses = () => {
        retrieveCourses(powerCoach.id).then((res) => {
            setCourses(res);
        })
    }

    const handleStep = (value: number) => {
        setStep(value); setCourse({} as CourseI); setActive(false);
        setBanner("/assets/placeholders/background.png"); setCover("/assets/placeholders/background.png");
    }

    const getImage = (file: any, type: string) => {
        var reader = new FileReader();
        reader.readAsDataURL(file[0]);
        reader.onload = (_event) => {
            if (type === "banner") {
                setBanner(reader.result);
                course.bannerImage = file[0];
            } else {
                course.courseImage = file[0];
                setCover(reader.result);
            }
        };
    }

    const update = () => {
        setIsLoading(true);
        if (!course.type) {
            course.type = "gratis";
        }
        course.active = active;
        if (!course.title || !course.description || !course.about || !course.category || !course.courseImage || !course.bannerImage) {
            getCourses();
            alert("Complete los campos por favor!");
            setIsLoading(false);
            return;
        }
        if (course.id) {
            updateCourseInfo(powerCoach.id, course).then(() => {
                alert("Curso Editado!");
                setIsLoading(false);
            })
        } else {
            createCourse(powerCoach.id, course).then(() => {
                getCourses();
                alert("Curso creado!");
                setStep(0);
                setIsLoading(false);
            })
        }
    }

    return (
        <div className="powercoach-courses-container">
            {step === 0 && <div className="page-one">
                <div className="top">
                    <h2>Tus cursos de powercoach</h2>
                    <button onClick={() => {
                        handleStep(1);
                    }}
                    >Nuevo</button>
                </div>
                <div className="course-cards">
                    {courses.map((x, index: number) => {
                        return (
                            <div className="course-card" key={"course" + index}>
                                <img src={x.courseImage} alt="" />
                                <p>{x.description}</p>
                                <p>{x.title}</p>
                                <div className="edit">
                                    <a onClick={() => {
                                        setCourse(x);
                                        setCover(x.courseImage);
                                        setBanner(x.bannerImage);
                                        setActive(x.active);
                                        setStep(1);
                                    }}>Editar curso</a>
                                    <a onClick={() => {
                                        setStep(2);
                                        setCourse(x);
                                    }}>Editar contenido</a>
                                </div>
                            </div>
                        )
                    })
                    }
                </div>
            </div>}
            {step === 2 && <PowerCoachCourseModules powercoachId={powerCoach.id} courseId={course.id} setStep={setStep} setData={setModule}></PowerCoachCourseModules>}
            {step === 3 && <PowerCoachCourseLessons powercoachId={powerCoach.id} courseId={course.id} module={module} setStep={setStep}></PowerCoachCourseLessons>}
            {step === 1 && <div className="page-two">
                <div className="return"
                    onClick={() => { handleStep(0) }}
                >
                    <MdOutlineArrowBackIosNew />
                    <p>Atras</p>
                </div>
                <h2>{course.id ? "Editar" : "Nuevo"} curso</h2>
                <div className="course-container">
                    <img className="cover" src={cover} alt="" />
                    <p>Imagen del curso: (387 x 212) recomendado</p>
                    <input type="file" onChange={(e) => { getImage(e.target.files, "cover") }} />
                    <img className="banner" src={banner} alt="" />
                    <p>Banner del curso: (1100 x 400) recomendado</p>
                    <input type="file" onChange={(e) => { getImage(e.target.files, "banner") }} />
                    <div className="input-container">
                        <label>Título:</label>
                        <input defaultValue={course.title} onChange={(e) => {
                            course.title = e.target.value
                        }} />
                    </div>
                    <div className="input-container">
                        <label>Descripción:</label>
                        <input defaultValue={course.description} onChange={(e) => {
                            course.description = e.target.value
                        }} />
                    </div>
                    <div className="input-container">
                        <label>Sobre el curso:</label>
                        <input defaultValue={course.about} onChange={(e) => {
                            course.about = e.target.value
                        }} />
                    </div>
                    <div className="input-container">
                        <label>Tipo de suscripcion:</label>
                        <select defaultValue={course.type} onChange={(e) => { course.type = e.target.value }}>
                            <option value="free">gratis</option>
                            <option value="premium">Premium</option>
                            <option value="cienpotenica">Cien Potencia</option>
                        </select>
                    </div>
                    <div className="input-container">
                        <label>Categoría:</label>
                        <select defaultValue={course.category} onChange={(e) => { course.category = e.target.value }}>
                            <option value="free">Selecciona una opción</option>
                            {categories.map((x: CategoryCoursesPowerCoachI, index: number) => {
                                return (
                                    <option key={"category" + index} value={x.id}>{x.title}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="active-container">
                        <p>Activo</p>
                        <div className={`switch ${active ? "switch-active" : ""}`} onClick={() => {
                            setActive(!active)
                        }}>
                            <div className={`pin ${active ? "pin-active" : ""}`}></div>
                        </div>
                    </div>
                    <p>Activo se refiere si el curso esta activado para que le aparezca a los usuarios.
                        Recomendamos activarlo una vez que se haya agregado el contenido del curso.
                    </p>
                    {!isLoading ? <button onClick={() => { update() }}>Guardar</button> :
                        <Spinner width={.5} size={4}></Spinner>
                    }
                </div>
            </div>}
        </div>
    )
}