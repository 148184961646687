import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "./ModalAuth.scss";
import { FaCheck } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { useFormik } from "formik";
import { object, string, boolean } from "yup";
import { Spinner } from "../../spinner/Spinner";
import { useUI } from "../../../context/UIContext";
import { accessWithAuthProvider, signInWithCreds, signUpCreds } from "../../../services/AuthService";
import { useNavigate } from "react-router-dom";
import { getUserByEmail } from "../../../services/UserService";
const google_icon = "assets/redesign/icons/logo-google.png";
const grad_figure = "assets/redesign/svg/grad_auth.png";

type TModalAuth = {
	show: boolean;
	onHide: () => void;
	type: "login" | "register";
};

export const ModalAuth = (props: TModalAuth) => {
	const { show, onHide, type } = props;
	const { handleModalLogin, handleModalRegister, handleModalReset } = useUI();
	const [loader, setLoader] = useState<boolean>(false);
	const navigate = useNavigate();
	const moveTo = () => {
		if (type === "register") {
			handleModalLogin();
		}
		if (type === "login") {
			handleModalRegister();
		}
		onHide();
		formik.resetForm();
	};
	const toReset = () => {
		handleModalReset();
	};
	const closeModal = () => {
		onHide();
		formik.resetForm();
	};
	const initialValues = {
		name: "",
		email: "",
		password: "",
		terms: false,
	};
	const registerSchema = object({
		name: string().required("El nombre es requerido"),
		email: string().email("Proporcione un correo electrónico válido").required("El correo electrónico es requerido"),
		password: string().min(6, "La contraseña debe tener al menos 6 caracteres").required("La Contraseña es requerida"),
		terms: boolean()
			.required("Debes acepta los términos y condiciones")
			.oneOf([true], "Debes acepta los términos y condiciones"),
	});
	const loginSchema = object({
		email: string().email("Proporcione un correo electrónico válido").required("El correo electrónico es requerido"),
		password: string().required("La Contraseña es requerida"),
	});
	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: type === "register" ? registerSchema : loginSchema,
		onSubmit: async (values, { setErrors, resetForm }) => {
			setLoader(true);
			if (type === "register") {
				const user: any = { ...values, birthdate: "", renovation: true, confirm_password: "" };
				let temp: any = await getUserByEmail(values.email.toLowerCase());
				const result = await signUpCreds(user, temp);
				if (result === "success") {
					handleModalRegister();
					resetForm({ values: initialValues });
					navigate("/academia");
				}

				if (result === "auth/email-already-in-use") {
					setErrors({ email: "Usuario ya Registrado" });
				}
				if (result === "auth/invalid-email") {
					setErrors({ email: "Correo invalido!" });
				}
			}
			if (type === "login") {
				const user: any = {
					email: values.email,
					password: values.password,
				};
				const result = await signInWithCreds(user);

				if (result.user) {
					handleModalLogin();
					resetForm({ values: initialValues });
					navigate("/academia");
				}
				if (result === "auth/user-not-found") {
					setErrors({ email: "El usuario no existe!" });
				}
				if (result === "auth/invalid-email") {
					setErrors({ email: "Correo invalido!" });
				}
				if (result === "auth/wrong-password") {
					setErrors({ password: "Contraseña incorrecta!" });
				}
			}

			setLoader(false);
		},
	});
	const {
		values: { name, email, password, terms },
		errors,
		touched,
		setErrors,
	} = formik;
	const accessWithGoogle = async () => {
		const result = await accessWithAuthProvider("Google");
		if (result.user) {
			closeModal();
			navigate("/academia");
		} else {
			if (result === "auth/email-already-exists") {
				setErrors({ email: "El correo ya existe con otra cuenta!" });
			}
		}
	};

	return (
		<Modal className="modal-component" show={show} centered onHide={closeModal}>
			<img className="figure" src={grad_figure} />
			<form onSubmit={formik.handleSubmit}>
				<IoClose className="close" onClick={closeModal} />
				<h2>
					{type === "login" && "Iniciar sesión"}
					{type === "register" && "Registrarme"}
				</h2>
				<div className="input-contain">
					<label>Email</label>
					<input id="email" name="email" onChange={formik.handleChange} value={email} placeholder="correo@gmail.com" />
					{touched.email && errors.email && <p className="error">{errors.email}</p>}
				</div>
				{type === "register" && (
					<div className="input-contain">
						<label>Nombre</label>
						<input id="name" name="name" onChange={formik.handleChange} value={name} placeholder="Nombre" />
						{touched.name && errors.name && <p className="error">{errors.name}</p>}
					</div>
				)}
				<div className="input-contain">
					<label>Contraseña</label>
					<input
						id="password"
						name="password"
						onChange={formik.handleChange}
						value={password}
						placeholder="*******"
						type="password"
					/>
					{touched.password && errors.password && <p className="error">{errors.password}</p>}
				</div>
				{type === "register" && (
					<div className="row-contain">
						<div className="checkbox" onClick={() => formik.setFieldValue("terms", !terms)}>
							<FaCheck className={"check " + (terms ? "check-select" : "")} />
						</div>
						<p>
							Acepto los <u>términos y condiciones</u>
						</p>
						{touched.terms && errors.terms && <p className="error">{errors.terms}</p>}
					</div>
				)}
				{type === "login" && (
					<>
						{loader ? (
							<div className="spin-contain">
								<Spinner width={0.4} size={4} />
							</div>
						) : (
							<button className="start" type="submit">
								Ingresar
							</button>
						)}
						<hr />
						<p>
							<u className="no-account active" onClick={toReset}>
								¿Olvidaste tu contraseña?
							</u>
						</p>
						{loader ? (
							<div className="spin-contain">
								<Spinner width={0.4} size={4} />
							</div>
						) : (
							<button className="google" type="button" onClick={accessWithGoogle}>
								<img src={google_icon} /> <p>Ingresar con Google</p>
							</button>
						)}
						<p className="no-account">
							{" "}
							¿No tienes cuenta?{" "}
							<u className="active" onClick={moveTo}>
								Regístrate
							</u>
						</p>
					</>
				)}
				{type === "register" && (
					<>
						{loader ? (
							<div className="spin-contain">
								<Spinner width={0.4} size={4} />
							</div>
						) : (
							<button className="start" type="submit">
								Registrarme
							</button>
						)}
						<hr />
						{loader ? (
							<div className="spin-contain">
								<Spinner width={0.4} size={4} />
							</div>
						) : (
							<button className="google" type="button" onClick={accessWithGoogle}>
								<img src={google_icon} /> <p>Registrarme con Google</p>
							</button>
						)}
						<p className="no-account">
							{" "}
							¿Ya tienes cuenta?{" "}
							<u className="active" onClick={moveTo}>
								Inicia sesión
							</u>
						</p>
					</>
				)}
			</form>
		</Modal>
	);
};
