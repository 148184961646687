import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { CourseCard } from "../../../../components/courseCard/CourseCard";
import { Spinner } from "../../../../components/spinner/Spinner";
import { CoursesPowercoachI, powerCoach, powerCoachContext } from "../../../../interfaces/PowerCoaches";
import { getFreeCoursesByPowerCoachId } from "../../../../services/PowerCoachService";
import "./Free.scss";

export function Free() {
	const { powerCoach, powerCoachSub }: powerCoachContext = useOutletContext();
	const [freeCourses, setFreeCourses] = useState<Array<CoursesPowercoachI>>([]);
	const [loadingFreeCourses, setLoadingFreeCourses] = useState<boolean>(true);

	const getFreeCourses = async () => {
		getFreeCoursesByPowerCoachId(powerCoach.id).then((courses) => {
			setFreeCourses(courses);
			setLoadingFreeCourses(false);
		});
	};

	useEffect(() => {
		getFreeCourses();
	}, []);

	return (
		<div className="free-pc-main-container">
			<div className="main-title-container">
				<h1>Cursos gratis</h1>
			</div>
			{loadingFreeCourses ? (
				<Spinner size={10} width={1} color={powerCoach.buttonColor} />
			) : freeCourses.length > 0 ? (
				<div className="free-courses-cards-container">
					{freeCourses.map((course, i) => {
						return <CourseCard course={course} key={"free-course-card-" + i} />;
					})}
				</div>
			) : (
				<div className="no-content-container">
					<h2>No se han encontrado cursos en esta sección</h2>
				</div>
			)}
		</div>
	);
}
