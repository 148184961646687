export async function setSocialMedia(socialMedias: any, powerCoach: any) {
	socialMedias.forEach((element: any) => {
		if (element.name === powerCoach.facebook.name) {
			element.active = powerCoach.facebook.active;
			element.link = powerCoach.facebook.link;
		}
		if (element.name === powerCoach.twitter.name) {
			element.active = powerCoach.twitter.active;
			element.link = powerCoach.twitter.link;
		}
		if (element.name === powerCoach.discord.name) {
			element.active = powerCoach.discord.active;
			element.link = powerCoach.discord.link;
		}
		if (element.name === powerCoach.youtube.name) {
			element.active = powerCoach.youtube.active;
			element.link = powerCoach.youtube.link;
		}
		if (element.name === powerCoach.reddit.name) {
			element.active = powerCoach.reddit.active;
			element.link = powerCoach.reddit.link;
		}
		if (element.name === powerCoach.telegram.name) {
			element.active = powerCoach.telegram.active;
			element.link = powerCoach.telegram.link;
		}
		if (element.name === powerCoach.tiktok.name) {
			element.active = powerCoach.tiktok.active;
			element.link = powerCoach.tiktok.link;
		}
		if (element.name === powerCoach.instagram.name) {
			element.active = powerCoach.instagram.active;
			element.link = powerCoach.instagram.link;
		}
	});
	return socialMedias;
}

export async function assignBecomeMember(becomeMember: any, powerCoach: any) {
	let tempMember = becomeMember;
	tempMember.accessC = powerCoach.becomeMember.accessC;
	tempMember.asesoria = powerCoach.becomeMember.asesoria;
	tempMember.exclusiveContent = powerCoach.becomeMember.exclusiveContent;
	tempMember.faq = powerCoach.becomeMember.faq;
	tempMember.foro = powerCoach.becomeMember.foro;
	tempMember.lives = powerCoach.becomeMember.lives;
	tempMember.merch = powerCoach.becomeMember.merch;
	tempMember.newCourses = powerCoach.becomeMember.newCourses;
	return tempMember;
}
export function formatDate(date: number) {
	let day: any = new Date(date).getDate();
	let month: any = new Date(date).getMonth();
	let year: any = new Date(date).getFullYear();
	let tempDate = `${day}-${month + 1}-${year}`;
	return tempDate;
}
