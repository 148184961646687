import { httpsCallable } from "firebase/functions";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { UserContext } from "../../../../../../context/UserContext";
import { functions } from "../../../../../../firebase/FirebaseConfig";
import { powerCoachContext } from "../../../../../../interfaces/PowerCoaches";
import { DefaultPaymentModal } from "../defaultPaymentModal/DefaultPaymentModal";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import "./SubscriptionCard.scss";
import { updateUserInfo, updateUserPowerCoachSubscription } from "../../../../../../services/UserService";
import { cancelPaypal, createInvoice } from "../../../../../../services/PowerCoachService";

export interface paymentDataI {
	type: string;
	anualSub: boolean;
	donation: number;
}

export function SubscriptionCard(props: any) {
	const userData = useContext(UserContext)
	const [type, setType] = useState<any>("subscription");
	const [anualSub, setAnualSub] = useState<boolean>(true);
	const [donation, setDonation] = useState<number>(10);
	const { powerCoach, powerCoachSub }: powerCoachContext = useOutletContext();
	const [modalShow, setModalShow] = useState<boolean>(false);
	const [paymentData, setPaymentData] = useState<paymentDataI | null>(null);
	const [daysLeft, setDaysLeft] = useState<number | null>(null);
	const [loadingCancel, setLoadingCancel] = useState<boolean>(false);
	const [paypal, setPaypal] = useState(true);
	const navigate = useNavigate();

	const tryPay = () => {
		if (!userData) {
			// navigate("/login");
			navigate("/");
			return;
		}
		// setPaymentData({
		// 	type: type,
		// 	anualSub: anualSub,
		// 	donation: donation,
		// });
		// setModalShow(true);
		navigate(`/checkout/${powerCoach.id}`);
	};

	const getDaysLeft = () => {
		if (powerCoachSub && powerCoachSub.final_date !== null) {
			const finalDate = new Date(powerCoachSub.final_date.seconds * 1000);
			const now = new Date();

			let difference = finalDate.getTime() - now.getTime();

			let days = Math.ceil(difference / (1000 * 3600 * 24));
			setDaysLeft(days);
		} else {
			setDaysLeft(null);
		}

	};
	const handlePaypal = () => {
		setPaypal(false);
		setTimeout(() => {
			setPaypal(true)
		}, 500);
	}

	const cancelRenovation = async () => {
		try {
			setLoadingCancel(true);
			if (powerCoachSub.stripe_id.includes("sub")) {
				const tryCancel = httpsCallable(functions, "cancelSubscription_ACC");

				tryCancel({ susId: powerCoachSub.stripe_id })
					.then((res) => {
						setLoadingCancel(false);
					})
					.catch((error) => {
						console.log(error);
						setLoadingCancel(false);
					});
			} else {
				let body = {
					planId: powerCoachSub.stripe_id
				}
				let subscription = {
					cancel_at_period_end: true,
					stripe_id: ""
				}
				cancelPaypal(body).then((res) => {
					console.log(res);
					updateUserPowerCoachSubscription(userData.id, powerCoachSub.id, subscription);
					setLoadingCancel(false);
				}).catch((error) => {
					console.log(error);
					setLoadingCancel(false);
				});
			}
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		getDaysLeft();
	}, [powerCoachSub]);

	let today = new Date().getTime() / 1000;

	return (
		<>
			<DefaultPaymentModal show={modalShow} onHide={() => setModalShow(false)} paymentData={paymentData} />
			<div className="card subscriptions-card-component">
				<div className="title-container">
					<h2>Aprende con Cecy</h2>
				</div>
				<div className="type-selector">
					<button className={type === "subscription" ? "active" : ""} onClick={() => setType("subscription")}>
						Suscripción
					</button>
				</div>
				<div
					className={
						"option-selector-container " + (powerCoachSub && powerCoachSub?.final_date !== null &&
							powerCoachSub?.final_date.seconds > today && type === "subscription" ? "d-none" : "")
					}
				>
					{type === "donation" ? (
						<div className="donation-options">
							<div className="donation-circle" onClick={() => { setDonation(10); handlePaypal() }}>
								<p>10</p>
							</div>
							<div className="donation-circle" onClick={() => { setDonation(20); handlePaypal() }}>
								<p>20</p>
							</div>
							<div className="donation-circle" onClick={() => { setDonation(50); handlePaypal() }}>
								<p>50</p>
							</div>
						</div>
					) : (
						(!powerCoachSub || powerCoachSub.final_date === null || powerCoachSub.final_date.seconds < today) && (
							<>
								<div className="subscription-options">
									<label className="form-check-label" htmlFor="flexSwitchCheckChecked">
										Mensual
									</label>
									<div className="form-check form-switch">
										<input
											className="form-check-input m-0"
											type="checkbox"
											role="switch"
											id="flexSwitchCheckChecked"
											onClick={() => { setAnualSub(!anualSub); handlePaypal() }}
											checked={anualSub}
											readOnly
										/>
									</div>
									<label className="form-check-label m-0" htmlFor="flexSwitchCheckChecked">
										Anual
									</label>
								</div>
							</>
						)
					)}
				</div>
				<div className={"total-container " + (type === "subscription" && (powerCoachSub && powerCoachSub.final_date !== null) ? "flex-grow-1" : "")}>
					{type === "donation" ? (
						<div className="input-container donation">
							<img src="/assets/images/dollar.png" alt="" />
							<input
								type="number"
								name="total"
								id="total"
								value={donation}
								onChange={(e) => setDonation(parseInt(e.target.value))}
							/>
						</div>
					) : (
						<>
							{(!powerCoachSub || powerCoachSub.final_date === null || powerCoachSub.final_date.seconds < today) ? (
								<>
									<label htmlFor="total">Por una suscripción anual.</label>
									<div className="input-container">
										<p>$</p>
										<input
											type="text"
											name="total"
											id="total"
											value={((!anualSub ? powerCoach.precioCard : powerCoach.precioCard_year) / 100).toFixed(2)}
											disabled
										/>
										<p>MXN</p>
									</div>
								</>
							) : (
								<div className="subscription-info-container">
									<h2>Suscripción se vence en:</h2>
									<h3>{daysLeft} DÍAS</h3>
									{powerCoachSub.cancel_at_period_end && <h2>*Su suscripción ya no se va a renovar</h2>}
								</div>
							)}
						</>
					)}
				</div>
				<div className="buttons-container">
					{type === "donation" ? (
						<button className="pay-btn" onClick={tryPay}>
							Donar {isNaN(donation) ? 0 : donation}
						</button>
					) : (!powerCoachSub || powerCoachSub.final_date === null || powerCoachSub.final_date.seconds < today) ? (
						<button className="pay-btn" onClick={tryPay}>
							Unirse
						</button>
					) : !powerCoachSub.cancel_at_period_end ? (
						<button onClick={cancelRenovation} disabled={loadingCancel}>
							Cancelar renovación
						</button>
					) : (
						<></>
					)}
					{/* {(paypal && type === "donation" || paypal && daysLeft === null || paypal && daysLeft !== null && daysLeft <= 0) && <PayPalScriptProvider options={{
						"client-id": "AXn-e1UCYo0EoE0WZTj-_1KRHkk0xEA-ei_XWLYDWAq0qmfaIC_6LYdDMB0DNOEsfisf5A5G80_MxIbn",
						currency: "MXN",
						'vault': true,
					}}
					>
						{type == 'subscription' && <PayPalButtons
							className="paypal"
							style={{
								color: "blue",
								layout: 'horizontal',
								shape: 'pill',
								height: 50,
								tagline: false
							}}
							onClick={() => {
								if (userData === null) {
									navigate("/login");
									return;
								}
							}}
							createSubscription={(data, actions) => {
								return actions.subscription.create({
									plan_id: anualSub ? powerCoach.paypalPriceId_year : powerCoach.paypalPriceId
								})
							}}
							onApprove={(data: any, actions) => {
								let final_date = new Date();
								let sub = {
									sub_type: anualSub ? "year" : "month",
									stripe_id: data.subscriptionID,
									final_date: anualSub ? new Date(final_date.setDate(final_date.getDate() + 365)) : new Date(final_date.setDate(final_date.getDate() + 30)),
									cancel_at_period_end: false
								}
								let tempUser = {
									paypalEmail: userData.email,
									paypalSub: data.subscriptionID,
									id: userData.id
								}
								updateUserInfo(tempUser);
								updateUserPowerCoachSubscription(userData.id, powerCoach.id, sub);
								return data
							}}
						/>}
						{type === 'donation' && <PayPalButtons
							className="paypal"
							style={{
								color: "gold",
								layout: 'horizontal',
								shape: 'pill',
								height: 50,
								tagline: false
							}}
							onClick={() => {
								if (userData === null) {
									navigate("/login");
									return;
								}
							}}
							createOrder={(data, actions) => {
								return actions.order.create({

									purchase_units: [
										{
											amount: {
												value: donation.toString(),
											},
										},
									],
								});
							}}
							onApprove={(data, actions: any) => {
								let tempSubscription = {
									cash: donation * 100,
									date: new Date(),
									productName: "Donación",
									user_name: userData.name,
									user_id: userData.id,
									user_email: userData.email,
									coach_id: powerCoach.id,
									coach_paypal_id: powerCoach.paypalPriceId,
								}
								return actions.order.capture().then((details: any) => {
									createInvoice(tempSubscription)
								});
							}}
						/>}
					</PayPalScriptProvider>} */}
				</div>
			</div>
		</>
	);
}
