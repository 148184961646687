import React from 'react'
import './Section3.scss'
const background = '/assets/svg/cecy_parete_3.svg';
const icon_book = '/assets/redesign/icons/book.png';
const icon_user = '/assets/redesign/icons/user.png';
const icon_reward = '/assets/redesign/icons/reward.png';

export const Section3 = () => {
  return (
    <div className='section-three'>
        <img src={background} className='background'/>
        <div className='main-contain'>
            <div className='program-contain'>
                <img src={icon_book}/>
                <h2 className='brown'>2 PROGRAMAS</h2>
                <p>2 programas de estudios<br/> disponibles.</p>
            </div>
            <div className='program-contain'>
                <img src={icon_user}/>
                <h2>+500 ESTUDIANTES</h2>
                <p>Mas de 500 estudiantes activos en nuestra<br/> plataforma.</p>
            </div>
            <div className='program-contain'>
                <img src={icon_reward}/>
                <h2 className='yellow'>+1500 EGRESADOS</h2>
                <p>Tenemos una gran taza de éxito de <br/> aprendizaje.</p>
            </div>
        </div>
    </div>
  )
}