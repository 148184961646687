import { Modal } from "react-bootstrap";

import "./ModalReset.scss";
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useUI } from "../../../context/UIContext";
import { object, string, boolean, ref } from "yup";
import { useFormik } from "formik";
import { useState } from "react";
import { Spinner } from "../../spinner/Spinner";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { FaCheckCircle } from "react-icons/fa";
type TModalReset = {
	show: boolean;
	onHide: () => void;
};

export const ModalReset = (props: TModalReset) => {
	const { show, onHide } = props;
	const { currency } = useUI();
	const navigate = useNavigate();
	const [loader, setLoader] = useState(false);
	const [sentPassword, setSentPassword] = useState(false);
	const initialValues = {
		email: "",
	};
	const schema = object({
		email: string().email("Proporcione un correo electrónico válido").required("El correo electrónico es requerido"),
	});
	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: schema,
		onSubmit: async (values, { resetForm }) => {
			setLoader(true);
			try {
				const auth = getAuth();
				await sendPasswordResetEmail(auth, values.email);
				setSentPassword(true);
			} catch (error) {
				console.log(error);
			}
			setLoader(false);
		},
	});
	const {
		errors,
		touched,
		resetForm,
		values: { email },
	} = formik;
	const goTo = () => {
		onHide();
		setSentPassword(false);
		resetForm();
	};
	return (
		<Modal show={show} onHide={goTo} className="modal-reset">
			<form className="main-contain" onSubmit={formik.handleSubmit}>
				<h2>Cambiar Contraseña</h2>
				<p>Al dar en Cambiar se le enviará un correo para poder realizar el cambio de contraseña</p>
				<IoClose className="close" onClick={onHide} />
				<div className="inputs">
					<div className="input-contain">
						<input
							type="email"
							placeholder="Correo"
							name="email"
							value={email}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						/>
						{touched.email && errors.email && <p className="error"> {errors.email} </p>}
					</div>
				</div>
				{sentPassword ? (
					<p className="sent">
						<FaCheckCircle /> El correo se ha enviado con éxito
					</p>
				) : (
					<>
						{loader ? (
							<div style={{ width: 177, height: 60 }}>
								<Spinner size={3} width={0.3} />
							</div>
						) : (
							<button type="submit"> Cambiar</button>
						)}
					</>
				)}
			</form>
		</Modal>
	);
};
