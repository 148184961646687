import Vimeo from "@u-wave/react-vimeo";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { Spinner } from "../../../../components/spinner/Spinner";
import { UserContext } from "../../../../context/UserContext";
import { db } from "../../../../firebase/FirebaseConfig";
import { lecture, lectureCommentI, modulesCourseComponentI } from "../../../../interfaces/ICourse";
import { powerCoach } from "../../../../interfaces/PowerCoaches";
import { getCommentsByLectureId, getVideoData, newCommentForLecture } from "../../../../services/CourseService";
import { getUserImageByEmail } from "../../../../services/UserService";
import { LectureNewComment } from "../../../../components/LectureNewComment/NewComment";
import { OtherLectureCard } from "../../components/OtherLectureCard/OtherLectureCard";
import "./Lecture.scss";

interface LectureOutletContext {
	modules: Array<modulesCourseComponentI>;
	powerCoach: powerCoach;
}

export function Lecture() {
	const params = useParams();
	const { powerCoach, modules }: LectureOutletContext = useOutletContext();
	const [module, setModule] = useState<modulesCourseComponentI | null>(null);
	const [lectures, setLectures] = useState<Array<lecture>>([]);
	const [actualLecture, setActualLecture] = useState<lecture | null>(null);
	const [loading, setLoading] = useState<boolean>(true);
	const [view, setView] = useState<number>(1);
	const [comments, setComments] = useState<Array<lectureCommentI>>([]);
	const navigate = useNavigate();

	const setModuleAndLecture = async () => {
		const mIndex = modules.findIndex((e) => e.id === params.moduleId);
		if (mIndex === -1) navigate("../");
		const lIndex = modules[mIndex].lectures.findIndex((e) => e.id === params.lectureId);
		if (lIndex === -1) navigate("../");

		setModule(modules[mIndex]);
		setLectures(modules[mIndex].lectures.filter((e) => e.id !== modules[mIndex].lectures[lIndex].id));
		setActualLecture(modules[mIndex].lectures[lIndex]);
	};

	const getCommentsInRealTime = async () => {
		const q = query(
			collection(
				db,
				"powerCoaches",
				powerCoach.id,
				"courses",
				params.courseId!,
				"modules",
				params.moduleId!,
				"lectures",
				actualLecture!.id,
				"comments"
			),
			orderBy("createAt", "desc")
		);

		onSnapshot(q, async (qs) => {
			const commentsArray: Array<lectureCommentI> = [];
			if (qs.size > 0) {
				await Promise.all(
					qs.docs.map(async (comment) => {
						const commentData = {
							id: comment.id,
							...comment.data(),
							photo: await getUserImageByEmail(comment.data().email),
						} as lectureCommentI;
						commentsArray.push(commentData);
					})
				);
			}
			setComments(commentsArray);
		});
	};

	useEffect(() => {
		setLoading(true);
		setLectures([]);
		setModule(null);
		setActualLecture(null);
		document.documentElement.style.setProperty("--power-coach-color", powerCoach.buttonColor);
		document.documentElement.style.setProperty("--power-coach-bg-color", powerCoach.buttonColor + 27);
		setModuleAndLecture();
	}, [params]);

	useEffect(() => {
		if (actualLecture !== null) {
			setLoading(false);
			getCommentsInRealTime();
		}
	}, [actualLecture]);

	const downloadMaterial = (x: any) => {
		window.open(x.ulr);
	}

	if (loading) return <Spinner size={10} width={1} color={powerCoach.buttonColor} />;

	if (module !== null && actualLecture !== null) {
		return (
			<div className="lecture-main-container">
				<div className="header">
					<h1>{actualLecture.title}</h1>
				</div>
				<div className="wrap-container">
					<div className="lecture-content-container">
						<section className="video-section">
							<div className="video-container">
								<Vimeo video={actualLecture.videoUrl} autoplay speed />
							</div>
						</section>
						<div className="lecture-info-container">
							<ul className="lecture-info-nav">
								<li className={view === 1 ? "active" : ""} onClick={() => setView(1)}>
									Recursos adicionales
								</li>
								<li className={view === 2 ? "active" : ""} onClick={() => setView(2)}>
									Descripción
								</li>
								<li className={view === 3 ? "active" : ""} onClick={() => setView(3)}>
									Comentarios
								</li>
							</ul>
							<div className="info-outlet">
								{view === 1 &&
									(actualLecture.files?.length > 0 ? (
										actualLecture?.files.map((file, i) => {
											return <a key={"lecture-file-" + i} href={file.url} target="_blank">{file.name}</a>;
										})
									) : (
										<p>Esta lección no cuenta con material adicional.</p>
									))}
								{view === 2 && <p>{actualLecture.description}</p>}
								{view === 3 && (
									<div className="lecture-comments-container">
										<LectureNewComment actualLecture={actualLecture} />
										{comments.map((comment, i) => {
											return (
												<div className="comment-card-container" key={"comment-card-" + i}>
													<div className="image-container">
														<img src={comment.photo} alt="" />
													</div>
													<div className="comment-info-container">
														<div className="email-container">
															<h3>{comment.email}</h3>
														</div>
														<div className="text-container">
															<p>{comment.comment}</p>
														</div>
													</div>
												</div>
											);
										})}
									</div>
								)}
							</div>
						</div>
					</div>
					{lectures.length > 0 && (
						<section className="other-lectures-section">
							<h2>Otras lecciones</h2>
							<div className="other-lectures-cards-container">
								{lectures.map((lecture, i) => {
									return (

										<OtherLectureCard moduleId={module.id} lecture={lecture} key={"other-lecture-card-" + i} />
									);
								})}
							</div>
						</section>
					)}
				</div>
			</div>
		);
	} else {
		return <></>;
	}
}
