import { useState } from "react";
import parse from 'html-react-parser';

import { SlQuestion } from "react-icons/sl";
import { IoIosArrowDown } from "react-icons/io";

import style from "./DropdownCard.module.scss";

interface DropdownCardComponentProps {
	title: string;
	text: string;
	index: number;
}

export function DropdownCard( props: DropdownCardComponentProps ){
	const [ open, setOpen ] = useState<boolean>( false );
	
	return (
		<div className={ `${ style[ "dropdown-card-main-container" ] } ${ open ? style.open : style.close }` }>
			
			<div className={ style["header-container"] } id={"header-" + props.index } onClick={ () => setOpen( !open ) } >
				<div className={ style.header } >
					<SlQuestion fill="#FF5757" style={{ padding: "2px" }} />
					<h2>{props.title}</h2>
				</div>
				<div className={ style["icon-container"] }>
					<IoIosArrowDown />
				</div>
			</div>

			<div className={ ` ${ style["text-container"] }` }>
				<p>{ parse( props.text ) }</p> 
			</div>
		</div>
	);
}