import { httpsCallable } from "firebase/functions";
import { useState, ChangeEvent } from "react";
import { useFormik } from "formik";
import { object, string } from "yup";
import { functions } from "../../firebase/FirebaseConfig";
import "./Contact.scss"
import { Spinner } from "../spinner/Spinner";
const background = "/assets/images/joven-contactUs.png";
const cecy_resp = "/assets/redesign/images/cecy_responsive.png";

interface IUser {
   name: string,
   email: string,
   about: string,
}
const initialUserData: IUser = {
   name: "",
   email: "",
   about: "",
}
const userSchema = object({
   name: string().required("El Nombre es requerido"),
   email: string().email("Proporcione un correo electronico valido").required("El correo electronico es requerido"),
   about: string().required("El mensaje es nesesario"),
})
export const Contact = () => {
   const [loader, setLoader] = useState<boolean>(false)
   const formik = useFormik({
      initialValues: initialUserData,
      validationSchema: userSchema,
      onSubmit: async (_, { resetForm }) => {
         setLoader(true);
         if (name === "" || email === "" || about === "") {
            console.log("nada");
            setLoader(false);
            return;
         }
         let data = {
            name,
            email,
            about
         }
         const contact = httpsCallable(functions, "sendEmailContact");

         await contact(data).then((res) => {
            alert("Su información fue enviada con exito!")
            setLoader(false);
         }).catch(err => console.log(err)
         )
         setLoader(false);
      }
   })
   const { values: { name, email, about }, errors, touched } = formik
   return (
   <div className='contact-container'>
      <div className="box">
         <form className='form' onSubmit={formik.handleSubmit} >
            <h2 className="br-norm">ESTEMOS<br /> <span>EN CONTACTO</span></h2>
            <h2 className="br-resp">Estemos en contacto</h2>
            <div className='wrapInputs'>
               <div className="input-container">
                  <input 
                     type='text' 
                     placeholder='Nombre' 
                     name="name" 
                     value={name} 
                     onChange={formik.handleChange} 
                     onBlur={formik.handleBlur} 
                  />
                  {errors.name && touched.name && <p className="error">{errors.name}</p>}
               </div>
               <div className="input-container">
                  <input 
                     type='email' 
                     placeholder='Correo' 
                     name="email" 
                     value={email} 
                     onChange={formik.handleChange} 
                     onBlur={formik.handleBlur} 
                  />
                  {errors.email && touched.email && <p className="error"> {errors.email} </p>}
               </div>
               <div className="input-container">
                  <textarea 
                     name="about" 
                     id="mensaje" 
                     placeholder='Mensaje' 
                     value={about} 
                     onChange={formik.handleChange} 
                     onBlur={formik.handleBlur} 
                  />
                  {errors.about && touched.about && <p className="error"> {errors.about} </p>}
               </div>
            </div>
            <div className='wrapButton'>
               {
                  !loader 
                  ?<button type="submit">Enviar</button>
                  :<div className="spinner-contain"><Spinner width={.3} size={3} />
                     </div>
               }
            </div>
         </form>
         {/* <img className="img1" src='/assets/images/Grad_18.svg' alt="aprende con cecy" /> */}
         <img className="cecy-img" src={background} alt="aprende con cecy" />
         <img className="cecy-img-resp" src={cecy_resp} alt="aprende con cecy" />
      </div>
   </div>
   )
}
