import { useContext, useEffect, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import { lecture, modulesCourseComponentI } from "../../interfaces/ICourse";
import { powerCoach } from "../../interfaces/PowerCoaches";
import { newCommentForLecture } from "../../services/CourseService";
import { newCommentForForum } from "../../services/ForumService";
import "./NewComment.scss";

interface LectureOutletContext {
	modules: Array<modulesCourseComponentI>;
	powerCoach: powerCoach;
}

interface lectureNewCommentProps {
	actualLecture?: lecture;
	forumId?: string;
}

export function LectureNewComment(props: lectureNewCommentProps) {
	const user = useContext(UserContext);
	const params = useParams();
	const { powerCoach, modules }: LectureOutletContext = useOutletContext();
	const [comment, setComment] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false);

	const sendComment = async () => {
		setLoading(true);
		const commentData = {
			comment: comment,
			...(props.actualLecture !== undefined && { email: user.email }),
			...(props.forumId !== undefined && { name: user.name }),
			photo: user.photo,
			createAt: new Date(),
		};
		if (props.actualLecture !== undefined) {
			newCommentForLecture(powerCoach.id, params.courseId!, params.moduleId!, props.actualLecture.id, commentData)
				.then((res) => {
					console.log(res);
					setComment("");
					setLoading(false);
				})
				.catch((error) => {
					console.log(error);
					alert("Ha ocurrido un error, favor de intentar de nuevo más tarde");
					setLoading(false);
				});
		} else if (props.forumId !== undefined) {
			console.log("new comment in forum");
			newCommentForForum(powerCoach.id, props.forumId, commentData)
				.then((res) => {
					console.log(res);
					setComment("");
					setLoading(false);
				})
				.catch((error) => {
					console.log(error);
					alert("Ha ocurrido un error, favor de intentar de nuevo más tarde");
					setLoading(false);
				});
		}
	};

	useEffect(() => {
		document.documentElement.style.setProperty("--power-coach-color", powerCoach.buttonColor);
		document.documentElement.style.setProperty("--power-coach-bg-color", powerCoach.buttonColor + 27);
	}, []);

	return (
		<div className="lecture-new-comment-main-container">
			<div className="new-comment-card">
				<div className="image-container">
					<img src={(user.photo.includes("assets") ? "/" : "") + user.photo} alt="" />
				</div>
				<div className="input-container">
					<textarea
						name="new-comment"
						placeholder="Dejanos aquí tu comentario..."
						value={comment}
						onChange={(e) => setComment(e.target.value)}
						id="new-comment"
						rows={4}
					></textarea>
				</div>
			</div>
			<div className="submit-btn-container">
				<button disabled={loading} onClick={sendComment}>
					Enviar comentario
				</button>
			</div>
		</div>
	);
}
