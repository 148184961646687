export const testimonies_array = [
    {
        img: "/assets/reviews/abigail.jpeg",
        about: "A mí en lo personal me encantan las clases de Teacher Cecy, aparte de que siempre transmite buena vibra y mucha energía y mucha alegría.También aprendo demasiado en todas y cada uno de los días que tomó su clase. Téngalo por seguro que si tienes alguna duda ella siempre te la va resolver.",
        name: "Abigail Hernández"
    },
    {
        img: "/assets/reviews/eduardo.jpeg",
        about: "Hoy en día estoy tomando clases de Francés con Madame Cecy en nivel básico 1. Hasta ahora llevo 2 meses y en cada una de estas clases, además de claro está, aprender algo nuevo sobre el idioma, he salido motivado y feliz, porque eso es lo que Madame Cecy proyecta en cada sesión. Es super atenta, nos comparte material de apoyo y contesta cada una de mis dudas con la mejor actitud. Estoy a un mes de terminar básico 1 y por supuesto que les recomiendo entrar a Aprende con Cecy y no sólo adquirir conocimiento en idiomas, sino también de la vida, con Madame Cecy.",
        name: "Eduardo Vargas"
    },
    {
        img: "/assets/reviews/jazmin.jpeg",
        about: "Hola, me llamó Jazmin, tengo 17 años y soy del Estado de México, y primeramente debo mencionar que los cursos son increíbles,  para ser sincera a mi se me complica un poco los idiomas , y más si no estoy en constante práctica,  pero con Cecy a sido increíble poder aprender con ella , tiene una energía y vibra que hace tan entendible la clase , que sientes como transmite el deseo por aprender algo nuevo, sus técnicas de enseñanza son muy accesibles y a pesar de estar trabajando por zoom la conexión es fascinante entre el grupo.No me arrepiento ni un solo momento en haber entrado a su curso , te hace la vida más feliz y te sientes ya toda una gringa jaja , fascinante , en verdad valen oro.",
        name: "Jazmín"
    },
    {
        img: "/assets/reviews/jessica.jpeg",
        about: "Yo soy la más feliz tomando las clases de “Aprende con Cecy” en lo personal tomo francés y hace las clases súper dinámicas, divertidas, es como de esas veces que tú amigo te explica y le entiendes mejor que al maestro jaja, y realmente le entiendes porque su forma de explicar lo hace ver muy fácil, te ayuda con TODAS las dudas que tengas, te corrige de manera correcta, en fin, no tengo cosa mala para decir, inscríbanse, tomen algunas clases y verán que les encantará.",
        name: "Jessica Domínguez"
    },
    {
        img: "/assets/reviews/Itzel.jpeg",
        about: "Hello! mi nombre es Itzel y estoy en las clases de Inglés principiante con Aprende con Cecy, y la verdad es que me a encantadooo. y quiero decir que siiii  he aprendido Inglés gracias a ella, es una Miss excelente y me encanta que tiene un carisma y unas ganas de enseñar súper grandes, ademas de que te explica todooo , siempre te pregunta si tienes dudas y ya ella te explica mejor si no entendiste alguna cosa sobre la clase. Gracias Cecy por dar clases de inglés en medio de la pandemia y espero que sigas así",
        name: "Itzel"
    },
    {
        img: "/assets/reviews/irma.jpeg",
        about: "Me da mucho gusto haber encontrado esta oportunidad aquí  en Aprendiendo con Cecy; mi nombre es Irma Castro soy ama de casa y se me complica salir a tomar clases de Inglés en cuando vi la oportunidad  me encanto y pues aquí sigo me encanta mucho tus clases  nos explicas  excelentemente bien! Y me gustaría seguir aprendiendo más  tus clases son muy productivas  y excelentes  muchísimas gracias y espero seguir aquí con tus clases hasta lograr mi objetivo.",
        name: "Irma Castro"
    },
    {
        img: "/assets/reviews/hector.jpeg",
        about: "Nos encantaron los cursos, mi hija mejoró  mucho en el colegio en la materia de inglés, a mi esposa le gusta mucho poder conectarse puntualmente pq ella en su trabajo se organiza para poder llevar la clase y en lo personal se me hace muy completo y muy objetivo Recomendable 100%.",
        name: "Hector Cárdenas"
    },
    {
        img: "/assets/reviews/beatriz.jpeg",
        about: "Aprendiendo con Cecy, en verdad es increíble en lo personal yo tenía temor al aprender inglés a las clases por qué intenté tomar clases y las personas que me tocaban puedo todo es solo inglés y no era de un principiante, en fin con Cecy es todo diferente, magnífico simplemente me encanta por qué sus clases son muy dinámicas y te hace sentir segura de ti misma y realmente empiezas desde el nivel que eliges, es muy paciente super dedica y explica muy bien, la mega recomiendo ampliamente, es la mejor!",
        name: "Beatriz Adriana Sierra"
    }
]