import { Modal } from "react-bootstrap";

import "./ModalGift.scss";
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import Confetti from "react-confetti";
import { useUI } from "../../../context/UIContext";
type TModalGift = {
	show: boolean;
	onHide: () => void;
};

export const ModalGift = (props: TModalGift) => {
	const { show, onHide } = props;
	const { currency } = useUI();
	const navigate = useNavigate();
	const goTo = () => {
		navigate("/academia/ambos/anual");
		onHide();
	};
	return (
		<Modal show={show} onHide={onHide} centered className="modal-gift">
			<div className="main-contain">
				<Confetti />
				<IoClose className="close" onClick={onHide} />
				<p className="title">
					{" "}
					Aprende ambos idiomas <br />
					por sólo{" "}
				</p>
				<div className="price-contain">
					<p>{currency === "mxn" ? "$3,399.00 MXN" : "199.99 USD"}</p>
					<hr />
				</div>
				<p className="sale">
					{currency === "mxn" ? (
						<>
							$2,049.00 <span>MXN</span>
						</>
					) : (
						<>
							$119.99 <span>USD</span>
						</>
					)}
				</p>
				<p className="bottom-text">Anual</p>
				<button onClick={goTo}> Aprovechar la oferta </button>
			</div>
		</Modal>
	);
};
