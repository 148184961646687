import { Benefits } from "../../../../../../interfaces/PowerCoaches";

interface PremiumBenefitsProps {
  benefits: Benefits;
}

export function PremiumBenefitsCard(props: PremiumBenefitsProps) {
  return (
    <div className="card benefits-card">
      <h2>Beneficios premium</h2>
      <div className="become-member-icons-container">
        {props.benefits.lives && (
          <div className="become-member-icons">
            <img src="/assets/images/live-icon.png" alt="" />
            <p>
              En
              <br />
              vivos
            </p>
          </div>
        )}
        {props.benefits.asesoria && (
          <div className="become-member-icons">
            <img src="/assets/images/community-icon.png" alt="" />
            <p>Asesorías</p>
          </div>
        )}
        {props.benefits.faq && (
          <div className="become-member-icons">
            <img src="/assets/images/FAQ-icon.png" alt="" />
            <p>
              Preguntas
              <br />
              frecuentes
            </p>
          </div>
        )}
        {props.benefits.accessC && (
          <div className="become-member-icons">
            <img
              src="/assets/images/access-community-icon.png"
              alt=""
            />
            <p>
              Acceso a la
              <br />
              comunidad
            </p>
          </div>
        )}
        {props.benefits.newCourses && (
          <div className="become-member-icons">
            <img src="/assets/images/new-courses.png" alt="" />
            <p>
              Cursos
              <br />
              nuevos
            </p>
          </div>
        )}
        {props.benefits.exclusiveContent && (
          <div className="become-member-icons">
            <img
              src="/assets/images/exclusive-content-icon.png"
              alt=""
            />
            <p>
              Contenido
              <br />
              exclusivo
            </p>
          </div>
        )}
        {props.benefits.foro && (
          <div className="become-member-icons">
            <img src="/assets/images/foro-icon.png" alt="" />
            <p>
              Foro
              <br />
            </p>
          </div>
        )}
        {props.benefits.merch && (
          <div className="become-member-icons">
            <img src="/assets/images/exclusive-merch-icon.png" alt="" />
            <p>
              Mercancía
              <br />
              exclusiva
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
