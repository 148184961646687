import {
    getAuth,
    signInWithEmailAndPassword,
    signInWithPopup,
    createUserWithEmailAndPassword
} from "firebase/auth";
import { doc, setDoc, getDoc, deleteDoc } from "firebase/firestore";
import { GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth";
import { db } from "../firebase/FirebaseConfig";
import { getUserByEmail, retrieveUserSubs } from "./UserService";

interface ICredentials {
    name: string
    email: string,
    password: string,
    birthdate: string,
    renovation: boolean,
    confirm_password: string,
    terms: boolean,
    id?: string,
}

export const signInWithCreds = async (credentials: ICredentials) => {
    const auth = getAuth();
    return await signInWithEmailAndPassword(auth, credentials.email, credentials.password).then((res) => {
        return res;
    }).catch((err) => {
        auth.signOut()
        return err.code;
    })
}

export const signUpCreds = async (credentials: ICredentials, userInfo: any) => {
    const auth = getAuth();

    const user = {
        name: credentials.name,
        email: credentials.email.toLowerCase(),
        birthdate: credentials.birthdate,
        photo: "/assets/icons/avatar.png",
        coverPhoto: "",
        stripe_id: "",
        userType: 1,
        renovation: true
    }

    if (!userInfo) {
        return await createUserWithEmailAndPassword(auth, credentials.email.toLowerCase(), credentials.password).then(async (res) => {
            await setDoc(doc(db, "users", res.user.uid), user);
            return "success";
        }).catch((err) => {
            return err.code;
        })
    } else {
        return await createUserWithEmailAndPassword(auth, credentials.email.toLowerCase(), credentials.password).then(async (res) => {
            const temp = { ...userInfo }
            delete temp.id
            await setDoc(doc(db, "users", res.user.uid), temp);

            const subs = await retrieveUserSubs(userInfo.id);
            if (subs.length > 0) {
                await Promise.all(subs.map(async (x: any) => {
                    await setDoc(doc(db, "users", res.user.uid, "powerCoachesSub", x.id), x);
                }))
            }
            await deleteDoc(doc(db, "users", userInfo.id));
            return "success";
        }).catch((err) => {
            return err.code;
        })
    }

};

export const accessWithAuthProvider = async (proveedor: string) => {
    const auth = getAuth();
    let provider: GoogleAuthProvider | FacebookAuthProvider;

    switch (proveedor) {
        case "Google":
            provider = new GoogleAuthProvider();
            break;
        case "Facebook":
            provider = new FacebookAuthProvider()
            break;
        default:
            provider = new GoogleAuthProvider();
            break;
    }
    return signInWithPopup(auth, provider).then(async (res: any) => {
        const ref = doc(db, "users", res.user.uid);
        const docSnap = await getDoc(ref);
        if (docSnap.exists()) {
            return res;
        } else {
            let temp: any = await getUserByEmail(res.user.email);
            if (!temp) {
                const user = {
                    name: res.user.displayName,
                    email: res.user.email,
                    birthdate: "",
                    photo: res.user.photoURL,
                    coverPhoto: "",
                    stripe_id: "",
                    userType: 1,
                    renovation: true
                }
                setDoc(doc(db, "users", res.user.uid), user);
            } else {
                const subs = await retrieveUserSubs(temp.id);
                await setDoc(doc(db, "users", res.user.uid), temp);
                if (subs.length > 0) {
                    subs.forEach(async (element: any) => {
                        await setDoc(doc(db, "users", res.user.uid, "powerCoachesSub", element.id), element);
                    });
                }
                await deleteDoc(doc(db, "users", temp.id));
            }
            return res;
        }
    }).catch((err: any) => {
        return err.code;
    })

}

