import { useContext, useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";

import { IconContext } from "react-icons";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoIosClose } from "react-icons/io";

import { UserContext } from "../../context/UserContext";
import style from "./Navbar.module.scss";
import { useUI } from "../../context/UIContext";

export function Navbar() {
  const [path, setPath] = useState(useLocation().pathname);
  const [user, setUser] = useState<any>();
  const userData = useContext(UserContext)
  const { handleModalLogin, handleModalRegister } = useUI()
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if ((path === "/admin" || path.includes("/powercoach")) && (userData === null || userData.userType === 1)) {
      // navigate('/login')

    }
    setUser(userData);
  }, [ userData, navigate, path ])

  let location = useLocation();
  useEffect(() => {
    setPath(location.pathname);
  }, [location]);

  return (
    <nav className="navbar navbar-expand-lg w-100">
      <div className={ `container-fluid ${ style.header }` }>
        <Link className={ style.wrapEsquina } to={"/"}>
          <span className={ style.botonSidebar } onClick={ () => setShow( p => !p ) } >
            <GiHamburgerMenu size={ 25 }  />
          </span>
          <img className={ style.logo } src="/assets/logos/logo-color.svg" alt="aprende con Cecy" />
        </Link>

        <div className={ style.wrapNavbarCenter }>
          <ul className={ style.navbarCenter } >
            <li className="">
              <NavLink to="/" className={`${path === "/" ? style["active"] : ""}  ${ style.link }`}>
                Inicio
              </NavLink>
            </li>
            <li className="">
              <NavLink to="/academia" className={`${path === "/academia" ? style["active"] : ""}  ${ style.link }`}>
                Academia
              </NavLink>
            </li>
            <li className="">
              <NavLink to="/preguntas" className={`${path === "/preguntas" ? style["active"] : ""}  ${ style.link }`} >
                Preguntas
              </NavLink>
            </li>
            { ( user && user.userType === 3 ) && <li className="">
              <NavLink to="/admin" className={`${path === "/admin" ? style["active"] : ""}  ${ style.link }`}
              >
                Admin
              </NavLink>
            </li> }
            { ( user && user.powerCoach === "si" ) && <li className="">
              <NavLink
                to={`/powercoach`}
                className={`${path === "/pow" ? "active" : ""} ${ style.link }`}
              >
                Power Coach
              </NavLink>
            </li> }

          </ul>          
        </div>

        <div className={`${ style.botones }`}>
          { !user && 
                <ul className={ `${ style.authWrap }` }>
                  <li className={""} onClick={ () => { setShow( false ); handleModalRegister(); }  }>
                    <p className={`${ style.link }`} >
                      Registrarme
                    </p>
                  </li>
                  <li className={""}>
                      <button className={`${ style.btn }`} onClick={ () => { setShow( false ); handleModalLogin() }  } >
                        Ingresar
                      </button>
                  </li>
                </ul>
          }
          { user && 
              <NavLink
                to="/perfil"
              >
                <img src={user.photo} alt="avatar" className={ style.image } referrerPolicy="no-referrer" />
              </NavLink>
            }
        </div>
        <div className={ style["wrapSidebar"] }>
            <div className={ `${ style.sidebar } ${ show ?  style.open : style.close  } ` }>
              <div className={ `${ style.container }` }>
                <img src="assets/images/Grad_18.svg" /> 
                  <div className={ `${ style.wrapcloseBtn }` } >
                    <IconContext.Provider value={ { className: `${ style.closeBtn }` } }  >
                      <IoIosClose  color="white" onClick={ () => setShow( p => !p ) }  />
                    </IconContext.Provider>
                  </div>
                  <ul className={ style.listaSidebar }>
                    <li className="">
                      <NavLink to="/" className={`${path === "/" ? style["active"] : ""}  ${ style.link }`}  onClick={ () => setShow( false ) }>
                        Inicio
                      </NavLink>
                    </li>
                    { !user ? <li className="">
                        <span className={`${path === "/" ? style["active"] : "" }  ${ style.link }`} onClick={ () => { setShow( false ); handleModalRegister(); }  } >
                          Ingresar
                        </span>
                      </li> : 
                      <li className="">
                        <NavLink to="/perfil" className={`${path === "/perfil" ? style["active"] : ""}  ${ style.link }`} onClick={ () => setShow( false ) } >
                          Perfil
                        </NavLink>
                      </li>
                    }  
                    <li className="">
                      <NavLink to="/academia" className={`${path === "/academia" ? style["active"] : ""}  ${ style.link }`} onClick={ () => setShow( false ) } >
                        Academia
                      </NavLink>
                    </li>
                    <li className="">
                      <NavLink to="/preguntas" className={`${path === "/academia" ? style["active"] : ""}  ${ style.link }`} onClick={ () => setShow( false ) } >
                        Preguntas
                      </NavLink>
                    </li>
                  </ul>
              </div>
              <div className={ style["botonesAuth"] }>
                <button className={ style["btnEmpezar"] }  onClick={ () => { setShow( false );  navigate( "/academia" )  } } >
                  Empezar
                </button>
              </div>
            </div>
        </div>
      </div>
    </nav>
  );
}
