import './Section5.scss'
export const Section5 = () => {
    return(
        <div className="section-five">
            <div className='main-contain'>
                <h2>Certificados</h2>
                <p>Todos nuestros cursos y clases cuentan con el respaldo de los siguientes certificados de enseñanza de inglés y francés:</p>
                <img src="/assets/images/certificates.png" alt="" />
            </div>
        </div>
    )
}