import { useEffect, useState, useRef, useContext } from "react";
import { query, collection, where, getDocs, orderBy, doc, onSnapshot, setDoc } from "firebase/firestore";
import style from "./Academy.module.scss";
import { db } from "../../firebase/FirebaseConfig";
import { FaHeart, FaCheck, FaArrowDown } from "react-icons/fa";

import { IoIosArrowForward } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { BtnRegalo } from "../../components/btnRegalo/BtnRegalo";
import { PowerCoachSubI } from "../../interfaces/IUser";
import { powerCoach } from "../../interfaces/PowerCoaches";
import { UserContext } from "../../context/UserContext";
import { retrieveUserSubs } from "../../services/UserService";
import { useUI } from "../../context/UIContext";

export function Academy() {
	const [powerCoaches, setPowerCoaches] = useState<Array<any>>([]);
	const [subscriptions, setSubscriptions] = useState<any>([]);
	const [currentSubs, setCurrentSubs] = useState<any>([]);
	const tableRef = useRef<HTMLTableElement>(null);
	const navigate = useNavigate();
	const user = useContext(UserContext);
	const { currency, handleCurrency } = useUI()
	let today = new Date().getTime() / 1000;

	const getPowerCoaches = async () => {
		const powerCoachesTemp: any[] = [];

		const q = query(collection(db, "powerCoaches"), where("active", "==", true), orderBy("name"));
		const docsSnap = await getDocs(q);

		if (docsSnap.size > 0) {
			docsSnap.docs.forEach((powerCoach) => {
				const data = {
					id: powerCoach.id,
					...powerCoach.data(),
				};

				powerCoachesTemp.push(data);
			});

			setPowerCoaches(powerCoachesTemp);
		}
	};

	const goToTable = () => {
		if (tableRef.current) {
			window.scrollTo({
				top: tableRef.current.offsetTop,
				behavior: "smooth",
			});
		}
	};
	const checkSub = async () => {
		let tempSubs = [];
		let english_id = "VoQIZfjMmBS5pXdUwfUhC4d2aot2";
		let french_id = "qFBYxqYXFZhyld4KvM5nzd7Z2ST2";
		const subs = await retrieveUserSubs(user.id);
		setSubscriptions(subs);
		const checkBoth = subs.filter((x: any) => x.id === "ambos");
		const checkEnglish = subs.filter((x: any) => x.id === english_id);
		const checkFrench = subs.filter((x: any) => x.id === french_id);
		if (checkBoth.length > 0) {
			if (checkBoth[0].final_date !== null && checkBoth[0].final_date.seconds > today) {
				tempSubs.push("ambos");
			}
		}
		if (checkEnglish.length > 0 && checkEnglish[0].final_date !== null && checkEnglish[0].final_date.seconds > today) {
			tempSubs.push("ingles");
		}
		if (checkFrench.length > 0 && checkFrench[0].final_date !== null && checkFrench[0].final_date.seconds > today) {
			tempSubs.push("frances");
		}
		setCurrentSubs(tempSubs);
	};
	const goTo = async (id: string, type: string) => {
		let english_id = "VoQIZfjMmBS5pXdUwfUhC4d2aot2";
		let french_id = "qFBYxqYXFZhyld4KvM5nzd7Z2ST2";
		if (user === null) {
			navigate(`/academia/${type}/anual`);
		}
		const checkBoth = subscriptions.filter((x: any) => x.id === "ambos");
		const checkSub = subscriptions.filter((x: any) => x.id === (type === "ingles" ? english_id : french_id));
		if (checkBoth.length > 0) {
			if (checkBoth[0].final_date !== null && checkBoth[0].final_date.seconds > today) {
				navigate(`/academia/${id}`);
			} else {
				if (checkSub[0].final_date !== null && checkSub[0].final_date.seconds > today) {
					navigate(`/academia/${id}`);
				} else {
					navigate(`/academia/${type}/anual`);
				}
			}
		} else {
			if (checkSub[0] && checkSub[0].final_date !== null && checkSub[0].final_date.seconds > today) {
				navigate(`/academia/${id}`);
			} else {
				navigate(`/academia/${type}/anual`);
			}
		}
	};
	useEffect(() => {
		getPowerCoaches();
	}, []);

	useEffect(() => {
		if (user) {
			checkSub();
		}
	}, [user]);
	return (
		<div className={style["academy-main-container"]}>
			<BtnRegalo />

			<div className={style.figura_fondo1}>
				<svg xmlns="http://www.w3.org/2000/svg" width="480" height="1163" viewBox="0 0 480 1163" fill="none">
					<path
						opacity="0.2"
						d="M0 0V98V1162.5C0 1162.5 289 1122 447 906C605 690 134.371 618.752 191.5 439.5C272.398 185.667 28.3999 10.4267 0 0Z"
						fill="url(#paint0_linear_21_725)"
					/>
					<defs>
						<linearGradient id="paint0_linear_21_725" x1="0" y1="0" x2="447" y2="906" gradientUnits="userSpaceOnUse">
							<stop stopColor="#FAC492" />
							<stop offset="1" stopColor="#FDEFD5" />
						</linearGradient>
					</defs>
				</svg>
			</div>

			<div className={style.figura_fondo2}>
				<svg xmlns="http://www.w3.org/2000/svg" width="386" height="1223" viewBox="0 0 386 1223" fill="none">
					<path
						d="M13.2285 610.006C-57.6561 920.116 187.811 1092.19 319.406 1139.46L746.656 1222.41L958.445 131.622C716.853 35.9694 250.752 -100.606 319.085 118.315C404.502 391.966 101.834 222.368 13.2285 610.006Z"
						fill="url(#paint0_linear_21_726)"
					/>
					<defs>
						<linearGradient
							id="paint0_linear_21_726"
							x1="555.401"
							y1="11.7668"
							x2="335.828"
							y2="1142.65"
							gradientUnits="userSpaceOnUse"
						>
							<stop stopColor="#FF0000" />
							<stop offset="1" stopColor="#EB00FF" stopOpacity="0" />
						</linearGradient>
					</defs>
				</svg>
			</div>

			<div className={style.figura_fondo3}>
				<img src="assets/images/Grad_18.svg" alt="aprende con cecy" />
			</div>

			<div className={style.figura_fondo4}>
				<svg xmlns="http://www.w3.org/2000/svg" width="1920" height="664" viewBox="0 0 1920 664" fill="none">
					<g>
						<path
							d="M980 48.1764C612.4 120.17 137.5 104.446 -54 87.5844V664H1971V21.7083C1793.83 0.533894 1347.6 -23.8167 980 48.1764Z"
							fill="#0052B4"
						/>
					</g>
				</svg>
			</div>

			<div className={style.figura_fondo5}>
				<img src="assets/images/francia.png" alt="aprende con cecy" />
			</div>

			<div className={style.figura_fondo6}>
				<img src="assets/images/londres.png" alt="aprende con cecy" />
			</div>
			{currentSubs.filter((x: string) => x === "ambos").length === 0 && (
				<div className={style["botonFlotante"]} onClick={goToTable}>
					<span>
						<FaArrowDown size={"1.5vw"} color="white" />
					</span>
				</div>
			)}

			<img className={style.lineas} src="/assets/redesign/images/lineas2.png" alt="aprende con cecy" />
			<h2 className={style.title}>¿Qué te gustaría aprender?</h2>
			<div className={style.wrapLanguages}>
				<div className={style.language}>
					<img src="assets/svg/france.svg" alt="aprende con cecy" />
					<div className={style.wrapSelectLanguage}>
						<div className={style.selectLanguage1}>
							<button
								onClick={() => {
									goTo("CURSO DE FRANCÉS", "frances");
								}}
							>
								<span>
									{currentSubs.filter((x: string) => x === "ambos" || x === "frances").length > 0
										? "Ingresar"
										: "Empezar"}
								</span>
								<div className={style.selectLanguage1_boton_icon}>
									<IoIosArrowForward size={25} color="white" />
								</div>
							</button>
							<span>Francés</span>
						</div>

						<div className={style.selectLanguage2}>
							<p className={style.egresados}>
								{" "}
								<span>
									<svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">
										<path
											d="M37.1874 32.3225L34.2999 33.005C33.6524 33.1625 33.1449 33.6525 33.0049 34.3L32.3924 36.8725C32.0599 38.2725 30.2749 38.71 29.3474 37.6075L24.1149 31.5875C23.6949 31.0975 23.9224 30.3275 24.5524 30.17C27.6499 29.4175 30.4324 27.685 32.4799 25.2175C32.8124 24.815 33.4074 24.7625 33.7749 25.13L37.6599 29.015C38.9899 30.345 38.5174 32.0075 37.1874 32.3225Z"
											fill="#0085FF"
										/>
										<path
											d="M4.72504 32.3225L7.61254 33.005C8.26004 33.1625 8.76754 33.6525 8.90754 34.3L9.52004 36.8725C9.85254 38.2725 11.6375 38.71 12.565 37.6075L17.7975 31.5875C18.2175 31.0975 17.99 30.3275 17.36 30.17C14.2625 29.4175 11.48 27.685 9.43254 25.2175C9.10004 24.815 8.50504 24.7625 8.13754 25.13L4.25254 29.015C2.92254 30.345 3.39504 32.0075 4.72504 32.3225Z"
											fill="#0085FF"
										/>
										<path
											d="M21 3.5C14.2275 3.5 8.75 8.9775 8.75 15.75C8.75 18.2875 9.5025 20.615 10.7975 22.5575C12.6875 25.3575 15.68 27.335 19.1625 27.8425C19.7575 27.9475 20.37 28 21 28C21.63 28 22.2425 27.9475 22.8375 27.8425C26.32 27.335 29.3125 25.3575 31.2025 22.5575C32.4975 20.615 33.25 18.2875 33.25 15.75C33.25 8.9775 27.7725 3.5 21 3.5ZM26.355 15.365L24.9025 16.8175C24.6575 17.0625 24.5175 17.535 24.605 17.885L25.025 19.6875C25.3575 21.105 24.605 21.665 23.345 20.9125L21.595 19.88C21.28 19.6875 20.755 19.6875 20.44 19.88L18.69 20.9125C17.43 21.6475 16.6775 21.105 17.01 19.6875L17.43 17.885C17.5 17.5525 17.3775 17.0625 17.1325 16.8175L15.645 15.365C14.7875 14.5075 15.0675 13.65 16.2575 13.4575L18.13 13.1425C18.445 13.09 18.8125 12.81 18.9525 12.53L19.985 10.465C20.545 9.345 21.455 9.345 22.015 10.465L23.0475 12.53C23.1875 12.81 23.555 13.09 23.8875 13.1425L25.76 13.4575C26.9325 13.65 27.2125 14.5075 26.355 15.365Z"
											fill="#FFA800"
										/>
									</svg>
								</span>{" "}
								353 Egresados
							</p>
						</div>
					</div>
				</div>
				<div className={style.language}>
					<img src="assets/svg/usa.svg" alt="aprende con cecy" />
					<div className={style.wrapSelectLanguage}>
						<div className={style.selectLanguageEnglish1}>
							<span>Inglés</span>
							<button
								onClick={() => {
									goTo("CURSO DE INGLÉS", "ingles");
								}}
							>
								<span>
									{" "}
									{currentSubs.filter((x: string) => x === "ambos" || x === "ingles").length > 0
										? "Ingresar"
										: "Empezar"}{" "}
								</span>
								<div className={style.selectLanguageEnglish2_boton_icon}>
									<IoIosArrowForward size={25} color="#0052B4" />
								</div>
							</button>
						</div>
						<div className={style.selectLanguageEnglish2}>
							<p className={style.egresados}>
								{" "}
								<span>
									<svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">
										<path
											d="M37.1874 32.3225L34.2999 33.005C33.6524 33.1625 33.1449 33.6525 33.0049 34.3L32.3924 36.8725C32.0599 38.2725 30.2749 38.71 29.3474 37.6075L24.1149 31.5875C23.6949 31.0975 23.9224 30.3275 24.5524 30.17C27.6499 29.4175 30.4324 27.685 32.4799 25.2175C32.8124 24.815 33.4074 24.7625 33.7749 25.13L37.6599 29.015C38.9899 30.345 38.5174 32.0075 37.1874 32.3225Z"
											fill="#0085FF"
										/>
										<path
											d="M4.72504 32.3225L7.61254 33.005C8.26004 33.1625 8.76754 33.6525 8.90754 34.3L9.52004 36.8725C9.85254 38.2725 11.6375 38.71 12.565 37.6075L17.7975 31.5875C18.2175 31.0975 17.99 30.3275 17.36 30.17C14.2625 29.4175 11.48 27.685 9.43254 25.2175C9.10004 24.815 8.50504 24.7625 8.13754 25.13L4.25254 29.015C2.92254 30.345 3.39504 32.0075 4.72504 32.3225Z"
											fill="#0085FF"
										/>
										<path
											d="M21 3.5C14.2275 3.5 8.75 8.9775 8.75 15.75C8.75 18.2875 9.5025 20.615 10.7975 22.5575C12.6875 25.3575 15.68 27.335 19.1625 27.8425C19.7575 27.9475 20.37 28 21 28C21.63 28 22.2425 27.9475 22.8375 27.8425C26.32 27.335 29.3125 25.3575 31.2025 22.5575C32.4975 20.615 33.25 18.2875 33.25 15.75C33.25 8.9775 27.7725 3.5 21 3.5ZM26.355 15.365L24.9025 16.8175C24.6575 17.0625 24.5175 17.535 24.605 17.885L25.025 19.6875C25.3575 21.105 24.605 21.665 23.345 20.9125L21.595 19.88C21.28 19.6875 20.755 19.6875 20.44 19.88L18.69 20.9125C17.43 21.6475 16.6775 21.105 17.01 19.6875L17.43 17.885C17.5 17.5525 17.3775 17.0625 17.1325 16.8175L15.645 15.365C14.7875 14.5075 15.0675 13.65 16.2575 13.4575L18.13 13.1425C18.445 13.09 18.8125 12.81 18.9525 12.53L19.985 10.465C20.545 9.345 21.455 9.345 22.015 10.465L23.0475 12.53C23.1875 12.81 23.555 13.09 23.8875 13.1425L25.76 13.4575C26.9325 13.65 27.2125 14.5075 26.355 15.365Z"
											fill="#FFA800"
										/>
									</svg>
								</span>{" "}
								578 Egresados
							</p>
						</div>
					</div>
				</div>
			</div>
			{currentSubs.filter((x: string) => x === "ambos").length === 0 && (
				<div className={style.tableSection}>
					<p className={style["title"]}> Evalúa el plan más conveniente para ti </p>
					<div className={style.wrapTable}>
					<div className={style.currencySelectorContainer}>
						<label htmlFor="currency">Precios en:</label>
						<select name="currency" id="currency" value={currency} onChange={(e) => handleCurrency(e.target.value)}>
							<option value="mxn">MXN</option>
							<option value="usd">USD</option>
						</select>
					</div>
						<table className={style.table} ref={tableRef}>
							<thead className={style.header}>
								<tr>
									<th className={style.cabecera}></th>
									<th className={style.cabecera} style={{ backgroundColor: "#D9E2FB" }}>
										Plan anual dúo
									</th>
									<th className={style.cabecera}>Plan semestral</th>
									<th className={style.cabecera}>Plan mensual</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className={style.desc}>Idiomas a seleccionar</td>
									<td className={style.blueColor}> 2 idiomas </td>
									<td className={style.redColor}> 1 idioma </td>
									<td className={style.yellowColor}> 1 idioma </td>
								</tr>
								<tr>
									<td className={style.desc}>Acceso a sesiones en plataforma</td>
									<td className={style.blueColor}>
										<FaCheck size={"2vw"} />
									</td>
									<td className={style.redColor}>
										<FaCheck size={"2vw"} />
									</td>
									<td className={style.yellowColor}>
										<FaCheck size={"2vw"} />
									</td>
								</tr>
								<tr>
									<td className={style.desc}>Revisión de tareas</td>
									<td className={style.blueColor}>
										<FaCheck size={"2vw"} />
									</td>
									<td className={style.redColor}>
										<FaCheck size={"2vw"} />
									</td>
									<td className={style.yellowColor}>
										<FaCheck size={"2vw"} />
									</td>
								</tr>
								<tr>
									<td className={style.desc}>Acompañamiento</td>
									<td className={style.blueColor}>
										<FaCheck size={"2vw"} />
									</td>
									<td className={style.redColor}>
										<FaCheck size={"2vw"} />
									</td>
									<td className={style.yellowColor}></td>
								</tr>
								<tr>
									<td className={style.desc}>Acceso a grupo de WhatsApp</td>
									<td className={style.blueColor}>
										<FaCheck size={"2vw"} />
									</td>
									<td className={style.redColor}>
										<FaCheck size={"2vw"} />
									</td>
									<td className={style.yellowColor}></td>
								</tr>
								<tr>
									<td className={style.desc}>Certificado de finalización</td>
									<td className={style.blueColor}>
										<FaCheck size={"2vw"} />
									</td>
									<td className={style.redColor}>
										<FaCheck size={"2vw"} />
									</td>
									<td className={style.yellowColor}>
										<FaCheck size={"2vw"} />
									</td>
									<td className={style.yellowColor}></td>
								</tr>
								<tr>
									<td className={style.desc}>Una sesión en vivo por semana</td>
									<td className={style.blueColor}>
										<FaCheck size={"2vw"} />
									</td>
									<td className={style.redColor}>
										<FaCheck size={"2vw"} />
									</td>
									<td className={style.yellowColor}></td>
								</tr>
								<tr>
									<td className={style.desc}>Libro de actividades</td>
									<td className={style.blueColor}>
										<FaCheck size={"2vw"} />
									</td>
									<td className={style.redColor}></td>
									<td className={style.yellowColor}></td>
								</tr>
								<tr>
									<td className={style.desc}></td>
									{currency === "mxn" ? (
										<td className={style.greyColor}>$3,399.00 MXN</td>
									) : (
										<td className={style.greyColor}>$199.99 USD</td>
									)}
									<td className={style.redColor}></td>
									<td className={style.yellowColor}> </td>
								</tr>
								<tr>
									<td className={style.desc}>Costo</td>
									{currency === "mxn" ? (
										<>
											<td className={style.blueColor}>$2,049.00 MXN</td>
											<td className={style.redColor}>$1,399.00 MXN</td>
											<td className={style.yellowColor}> $259.00 MXN </td>
										</>
									) : (
										<>
											<td className={style.blueColor}>$119.99 USD</td>
											<td className={style.redColor}>$80.99 USD</td>
											<td className={style.yellowColor}> $14.99 USD </td>
										</>
									)}
								</tr>
								<tr>
									<td className={style.desc}></td>
									<td className={style.blueColor}></td>
									<td className={style.redColor}></td>
									<td className={style.yellowColor}></td>
								</tr>
							</tbody>
							<tfoot>
								<tr>
									<td></td>
									<td className={style.costos}>
										<Link to={"/academia/ambos/anual"}>
											<button className={style.btnBlue}> ¡Aprender ya! </button>
										</Link>
									</td>
									<td className={style.costos}>
										<Link to={"/academia/ingles/anual"}>
											<button className={style.btnRed}> ¡Explorar todo el año! </button>
										</Link>
									</td>
									<td className={style.costos}>
										<Link to={"/academia/ingles/mensual"}>
											<button className={style.btnYellow}> Iniciar mes </button>
										</Link>
									</td>
								</tr>
							</tfoot>
						</table>
					</div>
					<div className={style.cards}>
						<div className={style.card1}>
							<p className={style.titleCard}> Plan anual dúo </p>
							<p className={style.descCard}>
								¡Desata tu potencial! Aprende dos idiomas y abre un mundo de oportunidades. ¡Sí, puedes hacerlo!
							</p>
							<p className={style.costCard1}>
								{" "}
								${currency === "usd" ? "119.99 USD" : "2,049 MXN"} <span className={style.pagoCard1}> Pago anual </span>{" "}
							</p>

							<div>
								<p className={style.idiomas1}> 2 idiomas </p>
								<ul className={style.listCard}>
									<li>
										{" "}
										<span>
											{" "}
											<FaCheck color="#3770B6" size={"2rem"} />{" "}
										</span>{" "}
										Acceso a sesiones en plataforma{" "}
									</li>
									<li>
										{" "}
										<span>
											{" "}
											<FaCheck color="#3770B6" size={"2rem"} />{" "}
										</span>{" "}
										Revisión de tareas{" "}
									</li>
									<li>
										{" "}
										<span>
											{" "}
											<FaCheck color="#3770B6" size={"2rem"} />{" "}
										</span>{" "}
										Acompañamiento{" "}
									</li>
									<li>
										{" "}
										<span>
											{" "}
											<FaCheck color="#3770B6" size={"2rem"} />{" "}
										</span>{" "}
										Acceso a grupo de WhatsApp{" "}
									</li>
									<li>
										{" "}
										<span>
											{" "}
											<FaCheck color="#3770B6" size={"2rem"} />{" "}
										</span>{" "}
										Certificado de finalización{" "}
									</li>
									<li>
										{" "}
										<span>
											{" "}
											<FaCheck color="#3770B6" size={"2rem"} />{" "}
										</span>{" "}
										Una sesión en vivo por semana{" "}
									</li>
									<li>
										{" "}
										<span>
											{" "}
											<FaCheck color="#3770B6" size={"2rem"} />{" "}
										</span>{" "}
										Libro de actividades{" "}
									</li>
								</ul>
							</div>

							<div className={style.wrapBoton}>
								<Link to={"/academia/ambos/anual"}>
									<button> ¡Aprender ya! </button>
								</Link>
							</div>
						</div>

						<div className={style.card2}>
							<p className={style.titleCard}> Plan semestral </p>
							<p className={style.descCard}>
								Embárcate en un semestre de clases y actividades personalizadas. Consejos exclusivos y motivación te
								acompañarán en cada paso. ¡Descubre conmigo cómo aprender idiomas puede ser emocionante!
							</p>
							<p className={style.costCard2}>
								{" "}
								${currency === "usd" ? "80.99 USD" : "1,399 MXN"} <br /> <span className={style.pagoCard2}> Pago semestral </span>{" "}
							</p>

							<div>
								<p className={style.idiomas2}> 1 idioma </p>
								<ul className={style.listCard}>
									<li>
										{" "}
										<span>
											{" "}
											<FaCheck color="#D80027" size={"2rem"} />{" "}
										</span>{" "}
										Acceso a sesiones en plataforma{" "}
									</li>
									<li>
										{" "}
										<span>
											{" "}
											<FaCheck color="#D80027" size={"2rem"} />{" "}
										</span>{" "}
										Revisión de tareas{" "}
									</li>
									<li>
										{" "}
										<span>
											{" "}
											<FaCheck color="#D80027" size={"2rem"} />{" "}
										</span>{" "}
										Acompañamiento{" "}
									</li>
									<li>
										{" "}
										<span>
											{" "}
											<FaCheck color="#D80027" size={"2rem"} />{" "}
										</span>{" "}
										Acceso a grupo de WhatsApp{" "}
									</li>
									<li>
										{" "}
										<span>
											{" "}
											<FaCheck color="#D80027" size={"2rem"} />{" "}
										</span>{" "}
										Certificado de finalización{" "}
									</li>
									<li>
										{" "}
										<span>
											{" "}
											<FaCheck color="#D80027" size={"2rem"} />{" "}
										</span>{" "}
										Una sesión en vivo por semana{" "}
									</li>
								</ul>
							</div>

							<div className={style.wrapBoton}>
								<Link to={"/academia/ingles/anual"}>
									<button> ¡Explorar todo el semestre! </button>
								</Link>
							</div>
						</div>

						<div className={style.card3}>
							<p className={style.titleCard}> Plan mensual </p>
							<p className={style.descCard}>
								Conquista el aprendizaje de idiomas a tu ritmo y disponibilidad. ¡Sin excusas! Estoy aquí para ayudarte a
								aprender idiomas de manera eficaz y rápida.
							</p>
							<p className={style.costCard3}>
								{" "}
								${currency === "usd" ? "14.99 USD" : "259.00 MXN"} <span className={style.pagoCard3}> Pago mensual </span>{" "}
							</p>

							<div>
								<p className={style.idiomas3}> 1 idioma </p>
								<ul className={style.listCard}>
									<li>
										{" "}
										<span>
											{" "}
											<FaCheck color="#FAC71A" size={"2rem"} />{" "}
										</span>{" "}
										Acceso a sesiones en plataforma{" "}
									</li>
									<li>
										{" "}
										<span>
											{" "}
											<FaCheck color="#FAC71A" size={"2rem"} />{" "}
										</span>{" "}
										Revisión de tareas{" "}
									</li>
									<li>
										{" "}
										<span>
											{" "}
											<FaCheck color="#FAC71A" size={"2rem"} />{" "}
										</span>{" "}
										Certificado de finalización{" "}
									</li>
								</ul>
							</div>

							<div className={style.wrapBoton}>
								<Link to={"/academia/ingles/mensual"}>
									<button> Iniciar mes </button>
								</Link>
							</div>
						</div>
					</div>
					<p className={style.planes}>*Planes sujeto a cambio sin previo aviso</p>
				</div>
			)}
			{/* { powerCoaches.length > 0 && (
				<section className="powercoaches-section">
					<h1>CONOCE A NUESTROS POWERCOACHES</h1>
					<div className="power-coach-cards-container">
						{ powerCoaches.map((powerCoach, index) => {
							return (
								<div className="power-coach-card-container" key={"powercoach-card-" + index}>
									<PowerCoachCard powerCoach={powerCoach} index={index} />
								</div>
							);
						}) }
					</div>
				</section>
			) } */}
		</div>
	);
}
