
import "./Home.scss";
import { Welcome } from "./components/welcome/Welcome";
import { Clases } from "./components/clases/clases";
import { ThirdSection } from "./components/thirdSection/thirdSection"
import { Testimonial } from "./components/testimonial/Testimonial";
import { Contact } from "../../components/contact/Contact";
import { Section1 } from "./componentsV2/Section1/Section1";
import { Section2 } from "./componentsV2/Section2/Section2";
import { Section3 } from "./componentsV2/Section3/Section3";
import { Section4 } from "./componentsV2/Section4/Section4";
import { Section5 } from "./componentsV2/Section5/Section5";

export function Home() {

    return (
        <div className='home-main-container'>
            <Section1/>
            <Section2/>
            <Section3/>
            <Section4/>
            <Section5/>
            <Contact /> 
            {/* <Welcome />

            <Clases />

            <ThirdSection />

            <Testimonial />
            
            <Contact /> */}
        </div>
    )
}