import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { CommunityCards, powerCoach, powerCoachContext } from "../../../../interfaces/PowerCoaches";
import { getCommunityPremiumCards } from "../../../../services/PowerCoachService";
import { CommunityCard } from "../general/components/communityCard/CommunityCard";
import "./Forums.scss";

export function Forums() {
	const { powerCoach, powerCoachSub }: powerCoachContext = useOutletContext();
	const [forums, setForums] = useState<Array<CommunityCards>>([]);

	const getForums = async () => {
		setForums(await getCommunityPremiumCards(powerCoach.id));
	};

	useEffect(() => {
		getForums();
	}, []);

	return (
		<div className="forums-pc-main-container">
			<div className="card all-forums-card">
				<div className="title-container">
					<h1>Foros</h1>
				</div>
				{forums.length > 0 && (
					<div className="forums-cards-container">
						{forums.map((forum, i) => {
							return <CommunityCard community={forum} key={"forum-card-" + i} />;
						})}
					</div>
				)}
			</div>
		</div>
	);
}
