import { forumCommentI } from "../../../interfaces/IForum";

interface ForumCommentCardProps {
	forumComment: forumCommentI;
}

export function ForumCommentCard(props: ForumCommentCardProps) {
	return (
		<div className="forum-comment-card">
			<div className="image-container">
				<img src={props.forumComment.photo} alt={props.forumComment.name + " foto de perfil"} />
			</div>
			<div className="comment-info-container">
				<div className="name-container">
					<h2>{props.forumComment.name}</h2>
				</div>
				<div className="comment-text">
					<p>{props.forumComment.comment}</p>
				</div>
			</div>
		</div>
	);
}
