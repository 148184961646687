
import { DropdownCard } from "../../components/dropdownCard/DropdownCard";
import style from  "./Questions.module.scss";
import { Contact } from "../../components/contact/Contact";

export function Questions() {

	const questions = [
		{
			title: "¿Cuanto duran las clases?",
			text: "Cada clase tiene una duración de <b>45min</b>.",
		},{
			title: "¿Cuanto duran los cursos?",
			text: "Cada curso tiene una duración de <b>3 meses</b>.",
		},{
			title: "¿Solo esta disponible para México?",
			text: "¡Por supuesto que no! Estas clases son abiertas a todo público.",
		}
		
	];
	// ,{
	// 	title: "¿SOLO SON CLASES PARA PERSONAS QUE VIVEN EN MÉXICO?",
	// 	text: "¡Por supuesto que no! Estas clases son abiertas a todo público.",
	// },
	

	return (
		<div className={ style["questions-main-container"] } >
      	<div className={ style[ "questions-container" ] }> 
   	     	<div className={ style.content }>
					{ questions.map( ( question, index ) => {
						return (
							<DropdownCard
								title={question.title}
								text={question.text}
								index={ index }
								key={"Questions_dropdownCard_"+index}
							/>	)
					} ) }
        		</div>
      	</div>
			<Contact />
		</div>
	);
}
