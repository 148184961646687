import { useEffect, useState } from "react";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { useOutletContext } from "react-router-dom";
import { IForum } from "../../../../interfaces/IForum";
import { powerCoach } from "../../../../interfaces/PowerCoaches";
import { createForum, retrieveForums, updateForumInfo } from "../../../../services/PowerCoachService";
import { ForumCard } from "./components/forum-card/ForumCard";
import "./PowerCoachForums.scss";

export function PowerCoachForums() {
    const powerCoach: powerCoach = useOutletContext();
    const [step, setStep] = useState(0);
    const [image, setImage] = useState<any>("/assets/placeholders/background.png");
    const [forum, setForum] = useState({} as IForum);
    const [forums, setForums] = useState<Array<IForum>>([]);

    const getImage = (file: any) => {
        var reader = new FileReader();
        reader.readAsDataURL(file[0]);
        reader.onload = (_event) => {
            setImage(reader.result);
            forum.imageCommunity = file[0];
        };
    }

    const updateForum = () => {
        if (!forum.imageCommunity || !forum.description || !forum.socialNetwork) {
            alert("Por favor complete todos los campos!");
            return;
        }
        if (forum.id) {
            updateForumInfo(powerCoach.id, forum).then(() => {
                getForums();
                alert("Foro Editado!");
            })
        } else {
            createForum(powerCoach.id, forum).then(() => {
                alert("Foro creado!");
                getForums();
            })
        }
    }

    const getForums = () => {
        retrieveForums(powerCoach.id).then((res) => {
            setForums(res);
        })
    }

    const handleClick = (index: number) => {
        setImage(forums[index].imageCommunity);
        setForum(forums[index]);
    }

    useEffect(() => {
        if (powerCoach) {
            getForums();
        }
    }, [powerCoach])

    return (
        <div className="powercoach-forums-container">
            {step === 0 && <div className="page-one">
                <div className="pages">
                    <p>{forums.length}/3</p>
                </div>
                <h2>Tarjetas de foro</h2>
                <p>Las tarjetas de tu foro de mostraran en tu perfil, para que de esta manera los usuarios
                    puedan seguirte/unirse a cada una de ellas.
                </p>
                <div className="new-card">
                    <h2>Tus tarjetas de foro</h2>
                    {forums.length < 3 && <button onClick={() => { setStep(1) }}>Nueva</button>}
                </div>
                {forums.length > 0 && < div className="cards">
                    {forums.map((x: IForum, index: number) => {
                        return (
                            <ForumCard forum={x} setStep={setStep} index={index} handleClick={handleClick} key={"forum" + index}></ForumCard>
                        )
                    })}
                </div>}
            </div>}
            {
                step === 1 && <div className="page-two">
                    <div className="return"
                        onClick={() => { setStep(0); setForum({} as IForum); setImage("/assets/placeholders/background.png"); }}
                    >
                        <MdOutlineArrowBackIosNew />
                        <p>Atras</p>
                    </div>
                    <h2>Nueva tarjeta</h2>
                    <div className="forum-container">
                        <img src={image} alt="" />
                        <p>Imagen del foro: (387 x 212) recomendado</p>
                        <input type="file" onChange={(e) => { getImage(e.target.files) }} />
                        <div className="input-container">
                            <label>Título:</label>
                            <input defaultValue={forum.socialNetwork} onChange={(e) => {
                                forum.socialNetwork = e.target.value
                            }} />
                        </div>
                        <div className="input-container">
                            <label>Descripción:</label>
                            <textarea name="" id="" defaultValue={forum.description} onChange={(e) => {
                                forum.description = e.target.value
                            }}></textarea>
                        </div>
                        <button style={{ margin: "auto" }} onClick={updateForum}>{forum.id ? "Editar" : "Crear"}</button>
                    </div>
                </div>
            }
        </div >
    )
}