import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/UserContext";
import { MdEmail } from "react-icons/md";
import { HiUser, HiCreditCard, HiTrash } from "react-icons/hi";
import { updateUserInfo } from "../../services/ProfileService";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { PaymetnMethodModal } from "./Modals/PaymentMethodModal";
import { functions } from "../../firebase/FirebaseConfig";
import { httpsCallable } from "firebase/functions";
import { ICard } from "../../interfaces/ICard";
import { Spinner } from "../../components/spinner/Spinner";
import { IUser } from "../../interfaces/IUser";
import "./Profile.scss";

export function Profile() {
    const [user, setUser] = useState({} as IUser);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [cards, setCards] = useState<Array<ICard>>([]);
    const userData = useContext(UserContext)
    const [tab, setTab] = useState(0);
    const auth = getAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (userData) {
            getPaymentMethods();
            setUser(userData);
        }

    }, [userData])

    const getPaymentMethods = async () => {
        let tempCards: any = [];
        let data = { customerId: userData.stripe_id }
        const addPm: any = httpsCallable(functions, 'customerPaymentMethods_ACC');
        await addPm(data).then(async (res: any) => {
            res.data.data.map((x: any) => {
                tempCards.push({
                    paymentMethodId: x.id,
                    exp_month: x.card.exp_month,
                    exp_year: x.card.exp_year,
                    last4: x.card.last4,
                    brand: x.card.brand
                });
            })
            setCards(tempCards);
        })
    }

    const update = () => {
        updateUserInfo(user).then(() => {
            alert("Su información ha sido editada!");
        })
    }


    const deletePm = (card: ICard) => {
        setIsLoading(true);
        let detachPm = httpsCallable(functions, 'deletePaymentMethod');
        detachPm({ pmId: card.paymentMethodId }).then((res) => {
            console.log(res);
            alert("Método de pago eliminado");
            setIsLoading(false);
        })
    }

    const handleClick = () => {
        setIsLoading(true);
        getPaymentMethods();
        setIsLoading(false);
    }

    // const usersUpdate = () => {
    //     let limit = 50;
    //     getAllUsers().then(async (res) => {
    //         for (let i = 0; i <= limit; i++) {
    //             console.log(i);
    //             await getPowerCoachSubByUser(res[i].id, "VoQIZfjMmBS5pXdUwfUhC4d2aot2").then((sub: any) => {
    //                 if (sub.final_date === "") {
    //                     let subscription = {
    //                         final_date: null
    //                     }
    //                     updateUserPowerCoachSubscription(res[i].id, "VoQIZfjMmBS5pXdUwfUhC4d2aot2", subscription)
    //                 }
    //             });
    //             if (i === limit) {
    //                 limit = limit + 50;
    //             }
    //             if (i === res.length) {
    //                 return;
    //             }
    //         }
    //     })
    // }

    // useEffect(() => {
    //     usersUpdate();
    // }, [])

    return (
        <div className="profile-main-container">
            {user && <div className="top">
                <img src={user.photo} alt="" referrerPolicy="no-referrer" />
                <h2>{user.name}</h2>
                <button onClick={() => {
                    signOut(auth).then(() => {
                        navigate("/")
                    })
                }}>Cerrar sesión</button>
            </div>}
            <div className="tabs">
                <a className={tab === 0 ? "active" : ""} onClick={() => { setTab(0) }}>Acerca de</a>
                <a className={tab === 1 ? "active" : ""} onClick={() => { setTab(1) }}>Suscripción</a>
            </div>
            {tab === 0 && <div className="user-info">
                <h4>EDITAR DATOS</h4>
                <div className="input-container">
                    <input type="text" defaultValue={user?.email} disabled />
                    <MdEmail />
                </div>
                <div className="input-container">
                    <input type="text" defaultValue={user?.name} onChange={(e) => { user.name = e.target.value }} />
                    <HiUser />
                </div>
                <div className="input-container">
                    <input type="date" defaultValue={user?.birthdate} onChange={(e) => { user.birthdate = e.target.value }} />
                </div>
                <button onClick={update}>Guardar</button>
            </div>}
            {tab === 1 && <div className="subscription-container">
                {!isLoading ? <div className="payment">
                    <h4>MÉTODO DE PAGO</h4>
                    {cards.map((card: ICard, index: number) => {
                        return (
                            <div className="payment-container" key={"card" + index}>
                                <HiCreditCard /> <p>{card.brand} terminada en {card.last4}</p><p>{card.name}</p>
                                <HiTrash style={{ marginLeft: "auto", cursor: "pointer" }} onClick={() => {
                                    deletePm(card);
                                }} />
                            </div>
                        )
                    })}
                    <button data-bs-toggle="modal" data-bs-target="#paymentMethod" className="content">
                        <p>Agregar método de pago</p>
                    </button>
                </div> :
                    <Spinner width={3} size={3}></Spinner>
                }
            </div>}
            <PaymetnMethodModal user={user} handleClick={handleClick} setIsLoading={setIsLoading}></PaymetnMethodModal>
        </div>
    )
}