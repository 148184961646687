import { httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import { MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos } from "react-icons/md";
import { useOutletContext } from "react-router-dom";
import { Spinner } from "../../../../components/spinner/Spinner";
import { functions } from "../../../../firebase/FirebaseConfig";
import { IInvoice } from "../../../../interfaces/ICheckout";
import { powerCoach } from "../../../../interfaces/PowerCoaches";
import { getPowerCoachById, retrievePowerCoachInvoices } from "../../../../services/PowerCoachService";
import { formatDate } from "../../../../utilis/functions/AdminPowerCoach";
import "./PowerCoachSales.scss";

export function PowerCoachSales() {
    const powerCoach: powerCoach = useOutletContext();
    const [powercoach, setPowerCoach] = useState<powerCoach>();
    const [pages, setPages] = useState<any>([]);
    const [page, setPage] = useState(0);
    const [invoices, setInvoices] = useState<Array<IInvoice>>([]);
    const [months, setMonths] = useState<any>([
        { name: "Enero", value: 0 },
        { name: "Febrero", value: 1 },
        { name: "Marzo", value: 2 },
        { name: "Abril", value: 3 },
        { name: "Mayo", value: 4 },
        { name: "Junio", value: 5 },
        { name: "Julio", value: 6 },
        { name: "Agosto", value: 7 },
        { name: "Septiembre", value: 8 },
        { name: "Octubre", value: 9 },
        { name: "Noviembre", value: 10 },
        { name: "Diciembre", value: 11 },
    ]);
    const [years, setYears] = useState([
        2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030
    ])
    const [sales, setSales] = useState<any>({});
    const [selectedMonth, setSelectedMonth] = useState<any>(new Date().getMonth());
    const [selectedYear, setSelectedYear] = useState<any>(new Date().getFullYear());
    const [selector, setSelector] = useState<any>("payments");
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [lastId, setLastId] = useState("");
    const [users, setUsers] = useState<any>([]);

    useEffect(() => {
        if (powerCoach) {
            get()
        }
    }, [powerCoach, lastId, users])

    const get = async () => {
        setIsLoading(true);
        let temp = users || [];
        var firstDay = new Date(selectedYear, parseInt(selectedMonth), 1).getTime() / 1000;
        var lastDay = new Date(selectedYear, parseInt(selectedMonth) + 1, 0, 23, 59, 0).getTime() / 1000;
        let data = {
            firstDay,
            lastDay,
            nextPage: lastId,
        }

        const addPm: any = httpsCallable(functions, 'stripeList');
        await addPm(data).then((res: any) => {
            let tempArray = res.data.data;
            temp.push(tempArray);
            setUsers(temp);
            if (res.data.has_more) {
                setLastId(res.data.next_page)
            } else {
                let cash = 0;
                sales.invoices = [];
                temp.forEach((element: any) => {
                    element.forEach((x: any) => {
                        cash += parseInt(x.amount);
                        sales.invoices.push({ cash: x.amount, user_name: x.customer.name, productName: 'Stripe' })
                    });
                });
                sales.totalSales = cash / 100;
                filter()
            }
        })
    }


    const filter = () => {
        // let tempInvoices = [];
        // let tempTotalSalesMonth = 0;
        // let tempTotalSalesMonthStripe = 0;
        // let tempTotalSalesMonthPaypal = 0;
        // if (selector === "payments") {
        //     tempInvoices = sales.invoices;
        // }
        // if (selectedMonth !== "all") {
        //     tempInvoices = sales.invoices.filter((invoice: any) =>
        //         new Date(invoice.date.toDate()).getMonth() === parseInt(selectedMonth));
        // }

        // if (selectedYear !== "all") {
        //     tempInvoices = tempInvoices.filter((invoice: any) =>
        //         new Date(invoice.date.toDate()).getFullYear() === parseInt(selectedYear));
        // }

        // tempInvoices.forEach((invoice: IInvoice) => {
        //     tempTotalSalesMonth += parseInt(invoice.cash) / 100;
        //     if (invoice.productName == "Suscripción PowerCoach" ||
        //         invoice.productName == "Suscripción PowerCoach Oxxopay") {
        //         tempTotalSalesMonthStripe += parseInt(invoice.cash) / 100;
        //     }
        //     if (invoice.productName == "Suscripción PowerCoach PayPal") {
        //         tempTotalSalesMonthPaypal += parseInt(invoice.cash) / 100;
        //     }
        // });
        setPages(getPages(sales.invoices));
        // setInvoices(tempInvoices);
        // sales.totalSalesMonth = tempTotalSalesMonth;
        // sales.totalSalesMonthStripe = tempTotalSalesMonthStripe;
        // sales.commissionStripe = tempTotalSalesMonthStripe - ((5 * tempTotalSalesMonthStripe) / 100);
        // sales.totalSalesMonthPaypal = tempTotalSalesMonthPaypal;
        // sales.commissionPaypal = tempTotalSalesMonthPaypal - ((5 * tempTotalSalesMonthPaypal) / 100);
        // sales.totalSalesStripePaypalCommission = sales.commissionStripe + sales.commissionPaypal;
        // sales.totalSalesWithCommission = sales.totalSalesStripePaypalCommission - ((10 * sales.totalSalesStripePaypalCommission) / 100);
        setSales({ ...sales });
        setIsLoading(false);
    }

    const getPages = (users: any) => {
        let tempPages = Math.ceil(users.length / 50)
        let tempArray: any = [];
        for (let i = 0; i < tempPages; i++) {
            tempArray.push([]);
        }
        let total = 50;
        let count = 0;
        for (let i = 0; i < tempPages; i++) {
            for (let w = 0; w <= users.length; w++) {
                if (w >= count && tempArray[i].length < total) {
                    if (users[w] && tempArray[i].findIndex((e: any) => e === users[w]) === -1)
                        tempArray[i].push(users[w]);
                    count++;
                }
            }
        }
        return tempArray;
    }
    const filterByUser = (search: string) => {
        let tempUsers = sales.invoices.filter((x: any) => (x.user_name?.includes(search)));
        setPages(getPages(tempUsers));
    }

    return (
        <div className="powercoach-sales-container">
            <h2>Ventas</h2>
            {!isLoading ? <div className="sales-info-container">
                <p>General de ventas: ${sales?.totalSales}</p>
                <div className="filter">
                    <select defaultValue={selectedMonth} onChange={(e) => { setSelectedMonth(e.target.value) }}>
                        <option value="all">Todos</option>
                        {months.map((month: any, index: number) => {
                            return (
                                <option key={"month" + index} value={month.value}>{month.name}</option>
                            )
                        })}
                    </select>
                    <select defaultValue={selectedYear} onChange={(e) => { setSelectedYear(e.target.value) }}>
                        <option value="all">Todos</option>
                        {years.map((year: number, index: number) => {
                            return (
                                <option key={"year" + index} value={year}>{year}</option>
                            )
                        })}
                    </select>
                    <button onClick={() => { setLastId(""); setUsers("") }}>Buscar</button>
                </div>
                {/* <p>Total efectivo: ${Math.round(sales?.totalSalesMonth).toLocaleString()}</p>
                <p>Total Stripe: ${Math.round(sales?.totalSalesMonthStripe).toLocaleString()}</p>
                <p>Comisión Stripe {powercoach?.commissionStripe}%: ${Math.round(sales?.commissionStripe).toLocaleString()}</p>
                <p>Total Paypal: ${Math.round(sales?.totalSalesMonthPaypal).toLocaleString()}</p>
                <p>Comisión Paypal {powercoach?.commissionPaypal}%: ${Math.round(sales?.commissionPaypal).toLocaleString()}</p>
                <p>Efectivo restante: ${Math.round(sales.totalSalesStripePaypalCommission).toLocaleString()}</p>
                <p>Comisión Cien Potencia {powercoach?.commission}%: ${Math.round(sales?.totalSalesWithCommission).toLocaleString()}</p>
                <p>Total: ${Math.round(sales?.totalSalesWithCommission).toLocaleString()}</p> */}
                <div className="top">
                    <p>Total de paginas: {pages.length}</p>
                    <input className="filter" placeholder="Nombre de usuario" type="text" onChange={(e) => {
                        filterByUser(e.target.value)
                    }} />
                </div>
                <div className="pagination">
                    {page > 0 && < MdOutlineArrowBackIosNew onClick={() => {
                        setPage(page - 1);
                    }} />}
                    <div className="page">
                        <p>{page + 1}</p>
                    </div>
                    <MdOutlineArrowForwardIos onClick={() => {
                        setPage(page + 1);
                    }} />
                </div>
                <div className="users-container">
                    {pages[page]?.map((invoice: IInvoice, index: number) => {
                        return (
                            <div className="user-card" key={"invoice" + index}>
                                <p>{invoice.productName}</p>
                                <p>{invoice.user_name}</p>
                                <p>${invoice.cash / 100} MXN</p>
                                {/* <p>{formatDate(invoice.date.seconds * 1000)}</p> */}
                            </div>
                        )
                    })}
                </div>
            </div> :
                <Spinner width={.5} size={4}></Spinner>}
        </div>
    )
}