import { useEffect, useState } from "react";
import { getAllUsers } from "../../../../services/AdminService";
import { MdOutlineArrowForwardIos, MdOutlineArrowBackIosNew } from "react-icons/md";
import { Link } from "react-router-dom";
import { Spinner } from "../../../../components/spinner/Spinner";
import "./UsersAdmin.scss";
import { IUser } from "../../../../interfaces/IUser";

export function UsersAdmin() {
    const [pages, setPages] = useState<any>([]);
    const [allUsers, setAllUsers] = useState<Array<IUser>>([]);
    const [page, setPage] = useState(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const getPages = (users: any) => {
        let tempPages = Math.ceil(users.length / 50)
        let tempArray: any = [];
        for (let i = 0; i < tempPages; i++) {
            tempArray.push([]);
        }
        let total = 50;
        let count = 0;
        for (let i = 0; i < tempPages; i++) {
            for (let w = 0; w <= users.length; w++) {
                if (w >= count && tempArray[i].length < total) {
                    if (users[w] && tempArray[i].findIndex((e: any) => e === users[w]) === -1)
                        tempArray[i].push(users[w]);
                    count++;
                }
            }
        }
        return tempArray;
    }

    useEffect(() => {
        getAllUsers().then((res) => {
            setAllUsers(res);
            setPages(getPages(res));
            setIsLoading(false);
        })
    }, [])

    const filter = (search: string) => {
        let tempUsers = allUsers.filter((x: any) => (x.email?.includes(search)))
        setPages(getPages(tempUsers));
    }


    return (
        <div className="users-admin-main-container">
            <div className="top">
                <p>Total de paginas: {pages.length}</p>
                <input className="filter" placeholder="Correo de usuario" type="text" onChange={(e) => {
                    filter(e.target.value)
                }} />
            </div>
            <div className="pagination">
                {page > 0 && < MdOutlineArrowBackIosNew onClick={() => {
                    setPage(page - 1);
                }} />}
                <div className="page">
                    <p>{page + 1}</p>
                </div>
                <MdOutlineArrowForwardIos onClick={() => {
                    setPage(page + 1);
                }} />
            </div>
            {!isLoading ? <>
                <div className="table-header">
                    <p>Correo electrónico</p>
                    <p>Nombre asignado</p>
                    <p>Ver</p>
                </div>
                <div className="user-table">
                    {pages[page]?.map((user: IUser, index: number) => {
                        return (
                            <div className="user-container" key={"user" + index}>
                                <p>{user.email}</p>
                                <p>{user.name}</p>
                                <Link to={`/admin/usuarios/${user.id}`}>
                                    Editar
                                </Link>
                            </div>
                        )
                    })}
                </div>
            </> :
                <Spinner width={1} size={10}></Spinner>
            }
            <div className="pagination">
                {page > 0 && < MdOutlineArrowBackIosNew onClick={() => {
                    setPage(page - 1);
                }} />}
                <div className="page">
                    <p>{page + 1}</p>
                </div>
                <MdOutlineArrowForwardIos onClick={() => {
                    setPage(page + 1);
                }} />
            </div>
        </div>
    )
}