import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { updateUser } from "../../../../../services/AdminService";
import { getUser } from "../../../../../services/ProfileService";
import "./UserEditAdmin.scss";

export function UserEditAdmin() {
    const location = useLocation();
    const [user, setUser] = useState<any>("");

    useEffect(() => {
        const lastSegment: any = location.pathname.split("/").pop();
        getUser(lastSegment).then((res: any) => {
            if (!("powerCoach" in res)) {
                res.powerCoach = "no";
            }
            setUser(res);
        })
    }, [])

    const update = () => {
        updateUser(user).then(() => {
            alert("Se actualizo la infomación del usuario");
        })
    }

    return (
        <div className="edit-user-main-container">
            <div className="user-top-info">
                <img referrerPolicy="no-referrer"
                    src={user.photo === "assets/images/avatar_ph.png" ? "/assets/images/avatar_ph.png" : user.photo} alt="" />
                <h2>{user.name}</h2>
            </div>
            <div className="user-bottom-info">
                <h2>EDITAR DATOS</h2>
                <input type="text" placeholder="Correo de usuario" defaultValue={user.email} onChange={(e) => {
                    setUser({ ...user, email: e.target.value });
                }} disabled />
                <input type="text" placeholder="Nombre de usuario" defaultValue={user.name} onChange={(e) => {
                    setUser({ ...user, name: e.target.value });
                }} />
                <input type="date" placeholder="Fecha de cumpleaños" defaultValue={user.birthdate} onChange={(e) => {
                    setUser({ ...user, birthdate: e.target.value });
                }} />
                <h2>PowerCoach</h2>
                <select value={user.powerCoach} onChange={(e) => {
                    setUser({ ...user, powerCoach: e.target.value });
                }}>
                    <option value="no">No</option>
                    <option value="si">Si</option>
                </select>
                <button onClick={update}>Guardar</button>
            </div>
        </div>
    )
}