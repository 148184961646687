import "./BtnRegalo.scss"
import { IconContext } from 'react-icons'
import { BsGiftFill } from "react-icons/bs";
import { useUI } from "../../context/UIContext";

export const BtnRegalo = () => {
   const { handleModalGift } = useUI()

   return (
      <div className="btnRegalo"  onClick={ handleModalGift }>
         <p>
            <IconContext.Provider value={ { className: "regaloIcon" } } >
               <BsGiftFill  />  
            </IconContext.Provider>
            <span>
               Tenemos un regalo para ti 
            </span>
         </p>
      </div>
   )

}
