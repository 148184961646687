export async function cardErrors(res: any) {
	if (res.data.error.raw.code === "invalid_expiry_year") {
		return "La fecha ya expiro";
	}
	if (res.data.error.raw.code === "parameter_invalid_empty") {
		return "Ingrese un número de tarjeta";
	}
	if (res.data.error.raw.code === "parameter_invalid_integer") {
		return "Verifique que la información se ingreso correctamente";
	}
	if (res.data.error.raw.code === "invalid_number") {
		return "Número de tarjeta no valido";
	}
	if (res.data.error.raw.code === "invalid_cvc") {
		return "CVC invalido";
	}
	if (res.data.error.raw.code === "processing_error") {
		return "Error de procesamiento";
	}
	if (res.data.error.raw.code === "card_declined") {
		return "Tarjeta Declinada";
	}
	if (res.data.error.raw.code) {
		if (res.data.error.raw.decline_code === "insufficient_funds") {
			return "Fondos insuficientes";
		}
		if (res.data.error.raw.decline_code === "lost_card") {
			return "Tarjeta perdida";
		}
		if (res.data.error.raw.decline_code === "stolen_card") {
			return "Tarjeta robada";
		}
	}
	return "Ha habido un error"
}
