import { httpsCallable } from "firebase/functions";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate, useOutletContext } from "react-router-dom";
import { ConfirmModal } from "../../../../../../components/confirmModal/ConfirmModal";
import { UserContext } from "../../../../../../context/UserContext";
import { functions } from "../../../../../../firebase/FirebaseConfig";
import { powerCoach, powerCoachContext } from "../../../../../../interfaces/PowerCoaches";
import { paymentDataI } from "../subscriptionCard/SubscriptionCard";
import "./DefaultPaymentModal.scss";

interface defaultPaymentModalProps {
	show: boolean;
	onHide: () => void;
	paymentData: paymentDataI | null;
}

export function DefaultPaymentModal(props: defaultPaymentModalProps) {
	const { powerCoach }: powerCoachContext = useOutletContext();
	const user = useContext(UserContext);
	const [loading, setLoading] = useState<boolean>(false);
	const [confirmModal, setConfirmModal] = useState<boolean>(false);
	const [confirmInfo, setConfirmInfo] = useState({ title: "", message: "" });

	const pay = async () => {
		setLoading(true);

		const getDefaultPaymentSource = httpsCallable(functions, "getCustomerDefaultSource");
		getDefaultPaymentSource({ customer_id: user.stripe_id })
			.then((res: any) => {
				if (res.data.customer_default_source === null) {
					setConfirmInfo({
						title: "Error con el pago - tarjeta predeterminada",
						message:
							"Parece que no tienes una tarjeta predeterminada en tu cuenta," +
							" te redireccionaremos para que puedas agregar una y seguir con el pago.",
					});
					setConfirmModal(true);
					setLoading(false);
				} else {
					if (props.paymentData?.type === "donation") {
						const tryToPay = httpsCallable(functions, "createCharge_ACC");
						tryToPay({
							customer_id: user.stripe_id,
							price: props.paymentData?.donation! * 100,
							pm: res.data.customer_default_source,
							powerCoachId: powerCoach.id,
							user_id: user.id,
							user_name: user.name,
						})
							.then((res: any) => {
								setLoading(false);
								if (res.data.status === "success") {
									props.onHide();
								} else {
									alert("Ha ocurrido un error.");
								}
							})
							.catch((error) => {
								console.log(error);
								setLoading(false);
							});
					} else {
						const trySub = httpsCallable(functions, "createSubscription_ACC");
						trySub({
							stripeId: user.stripe_id,
							price: props.paymentData?.anualSub ? powerCoach.priceId_year : powerCoach.priceId,
							paymentMethodId: res.data.customer_default_source,
							powerCoachId: powerCoach.id,
							userId: user.id,
							userName: user.name,
							userEmail: user.email,
						})
							.then((res: any) => {
								setLoading(false);
								if (res.data.status === "success") {
									props.onHide();
								} else {
									console.log(res);

									alert("Ha ocurrido un error.");
								}
							})
							.catch((error) => {
								console.log(error);
								setLoading(false);
							});
					}
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};
	if (props.paymentData === null) {
		return <></>;
	}

	return (
		<>
			<ConfirmModal
				show={confirmModal}
				onHide={() => setConfirmModal(false)}
				title={confirmInfo.title}
				message={confirmInfo.message}
				type={props.paymentData.type}
				donation={props.paymentData.donation}
				powerCoach={powerCoach}
			/>
			<Modal
				className="default-pay-modal"
				show={props.show}
				onHide={props.onHide}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					<h2>¿Seguro que deseas continuar?</h2>
					<div className="payment-info-card">
						<img src="/assets/images/cash.png" alt="icono de dinero" />
						<a>
							$
							{props.paymentData.type === "donation"
								? props.paymentData.donation.toFixed(2)
								: ((props.paymentData.anualSub ? powerCoach.precioCard_year : powerCoach.precioCard) / 100).toFixed(2)}
							MXN
						</a>
						<p>Pagar con mi método de pago predeterminado</p>
					</div>
					<button className="pay-btn" onClick={pay} disabled={loading}>
						Realizar pago
					</button>
				</Modal.Body>
			</Modal>
		</>
	);
}
