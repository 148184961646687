import { Modal } from "react-bootstrap";
import "./AlertModal.scss";

interface alertModalProps {
    show: boolean;
    onHide: () => void;
    title: string;
    message: string;
}

export function AlertModal(props: alertModalProps) {
    return (
        <Modal
            className="alert-modal-component"
            show={props.show}
            size="lg"
            onHide={props.onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{props.message}</p>
            </Modal.Body>
            <Modal.Footer>
                <button className="cancel" onClick={props.onHide}>Cancelar</button>
            </Modal.Footer>
        </Modal>
    );
}
