import { useUI } from "../../context/UIContext";
import { ModalGift } from "./modalGift/ModalGift";
import { ModalAuth } from "./ModalAuth/ModalAuth";
import { ModalReset } from "./ModalReset/ModalReset";

const Modals = () => {
	const {
		showModalLogin,
		handleModalLogin,
		showModalRegister,
		handleModalRegister,
		showModalGift,
		handleModalGift,
		showModalReset,
		handleModalReset,
	} = useUI();
	return (
		<>
			<ModalAuth show={showModalLogin} onHide={handleModalLogin} type="login" />
			<ModalAuth show={showModalRegister} onHide={handleModalRegister} type="register" />
			<ModalGift show={showModalGift} onHide={handleModalGift} />
			<ModalReset show={showModalReset} onHide={handleModalReset} />
		</>
	);
};

export default Modals;
