export const returnPlan = (lang: string, type: string) => {
    if(lang === "ingles"){
        if(type === "mensual"){
            return "Inglés mensual"
        }else{
            return "Inglés anual"
        }
    }
    if(lang === "frances"){
        if(type === "mensual"){
            return "Francés mensual"
        }else{
            return "Francés anual"
        }
    }
    if(lang === "ambos"){
        return "Anual dúo"
    }
    return ''
}