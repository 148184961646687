import { useContext, useEffect, useState } from "react";
import { FaHouseUser, FaWindowClose } from "react-icons/fa";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import { getPowerCoachById } from "../../services/PowerCoachService";
import "./AdminPowerCoach.scss";

export function AdminPowerCoach() {
    const [powerCoach, setPowerCoach] = useState<any>();
    const [path, setPath] = useState(useLocation().pathname);
    const userData = useContext(UserContext);
    const [open, setOpen] = useState<boolean>(false);
    let location = useLocation();


    useEffect(() => {
        if (userData) {
            getPowerCoachById(userData.id).then((res: any) => {
                if (!res) {
                    setPowerCoach({ ...powerCoach, id: userData.id });
                } else {
                    setPowerCoach(res);
                }
            })
        }
    }, [userData])

    useEffect(() => {
        setPath(location.pathname);
    }, [location]);

    return (
        <div className="admin-powercoach-container">
            <div className="open-menu" onClick={() => setOpen(true)}>
                <FaHouseUser />
                <p>Menu Power Coach</p>
            </div>
            <div className={`side-bar ${open ? "active" : ""}`}>
                <FaWindowClose onClick={() => setOpen(false)} />
                <ul>
                    <NavLink to="" >
                        <li className={`${path === `/powercoach` ? "active" : ""}`}>Perfil</li>
                    </NavLink>
                    <NavLink to="/powercoach/foros" >
                        <li className={`${path === `/powercoach/foros` ? "active" : ""}`}>Foros</li>
                    </NavLink>
                    <NavLink to="/powercoach/categorias" >
                        <li className={`${path === `/powercoach/categorias` ? "active" : ""}`}>Categorías</li>
                    </NavLink>
                    <NavLink to="/powercoach/cursos" >
                        <li className={`${path === `/powercoach/cursos` ? "active" : ""}`}>Cursos</li>
                    </NavLink>
                    <NavLink to="/powercoach/suscripciones" >
                        <li className={`${path === `/powercoach/suscripciones` ? "active" : ""}`}>Suscripción</li>
                    </NavLink>
                    <NavLink to="/powercoach/ventas" >
                        <li className={`${path === `/powercoach/ventas` ? "active" : ""}`}>Ventas</li>
                    </NavLink>
                    <NavLink to="/powercoach/comunidad" >
                        <li className={`${path === `/powercoach/comunidad` ? "active" : ""}`}>Comunidad</li>
                    </NavLink>
                    <NavLink to="/powercoach/usuarios" >
                        <li className={`${path === `/powercoach/usuarios` ? "active" : ""}`}>Usuarios</li>
                    </NavLink>
                    <NavLink to="/powercoach/red-social" >
                        <li className={`${path === `/powercoach/red-social` ? "active" : ""}`}>Red social</li>
                    </NavLink>
                    <NavLink to="/powercoach/correo" >
                        <li className={`${path === `/powercoach/correo` ? "active" : ""}`}>Correo</li>
                    </NavLink>
                </ul>
            </div>
            <Outlet context={powerCoach} />
        </div>
    )
}