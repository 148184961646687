import { useContext, useEffect, useState } from "react";
import ReactInputMask from 'react-input-mask';
import { FaUser, FaCreditCard, FaCalendar, FaKey } from "react-icons/fa";
import { UserContext } from "../../context/UserContext";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { createInvoice, getPowerCoachById } from "../../services/PowerCoachService";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase/FirebaseConfig";
import { cardErrors } from "../../utilis/functions/Checkout";
import { addPaymentMethod, getPowerCoachSubByUser, updatePowerCoachCount, updateUserInfo, updateUserPowerCoachSubscription } from "../../services/UserService";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { Spinner } from "../../components/spinner/Spinner";
import { ICard, IPlan } from "../../interfaces/ICheckout";
import { IUser } from "../../interfaces/IUser";
import { powerCoach } from "../../interfaces/PowerCoaches";
import { SuccessModal } from "./modal/SuccessModal";
import { AlertModal } from "../../components/alertModal/AlertModal";
import "./Checkout.scss";
declare let Stripe: any;

export function Checkout() {
    const [selectedPlan, setSelectedPlan] = useState(1);
    const [selectedPm, setSelectedPm] = useState(0);
    const [powercoach, setPowercoach] = useState({} as powerCoach);
    const [plan, setPlan] = useState({} as IPlan);
    const [price, setPrice] = useState<any>(0);
    const [card, setCard] = useState<any>({});
    const [cards, setCards] = useState<Array<ICard>>([]);
    const [user, setUser] = useState({} as IUser);
    const [type, setType] = useState<string>("subscription");
    const [show, setShow] = useState<boolean>(false);
    const [message, setMessage] = useState<any>("");
    const userData = useContext(UserContext)
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    let location = useLocation();
    const navigate = useNavigate();
    const [paypal, setPaypal] = useState(true);
    const [searchParams] = useSearchParams();
    const [subscription, setSubscription] = useState<any>();

    useEffect(() => {
        if (userData) {
            getPaymentMethods();
        }
        setUser(userData);
    }, [userData])

    const getPaymentMethods = async () => {
        let tempCards: any = [];
        let data = { customerId: userData.stripe_id }
        const addPm: any = httpsCallable(functions, 'customerPaymentMethods_ACC');
        await addPm(data).then(async (res: any) => {
            res.data.data.map((x: any) => {
                tempCards.push({
                    paymentMethodId: x.id,
                    exp_month: x.card.exp_month,
                    exp_year: x.card.exp_year,
                    last4: x.card.last4,
                    brand: x.card.brand
                });
            })
            setCards(tempCards);
        })
    }

    const handlePaypal = () => {
        setPaypal(false);
        setTimeout(() => {
            setPaypal(true)
        }, 500);
    }

    useEffect(() => {
        if (location) {
            const id: any = location.pathname.split("/").pop();
            if (userData) {
                getPowerCoachSubByUser(userData.id, id).then((res) => {
                    setSubscription(res)
                    if (res && res.active) {
                        navigate("/academia")
                    }
                })
            } else {
                navigate("/academia")
            }
            getPowerCoachById(id).then((res: any) => {
                if (!res) {
                    return navigate("/academia");
                }
                handlePaypal()
                setPowercoach(res);
                plan.price = res.precioCard_year;
                plan.powerCoachName = res.name;
                plan.planId = res.priceId_year;
                plan.about = res.about;
                plan.powerCoachPhoto = res.imageLink;
                plan.paymentMethod = "card";
                if (searchParams.get('tipo')) {
                    setType("payment");
                    setPrice(searchParams.get('donacion'));
                }
                setPlan(plan);
            })
        }
    }, [location]);


    const pay = async () => {

        setIsLoading(true);
        if (plan.paymentMethod !== "oxxo" && (Object.keys(card).length === 0 || Object.values(card).some(value => value === ''))) {
            setIsLoading(false);
            return;
        }
        let tempSubscription = {
            stripeId: user.stripe_id,
            price: plan.planId,
            paymentMethodId: "",
            powerCoachId: powercoach.id,
            userName: user.name,
            userId: user.id,
            userEmail: user.email
        }
        if (plan.paymentMethod === "oxxo") {
            var stripe = Stripe("pk_live_51IVfzJH9ypYniZ9EQ8OzAj299pTmXs9s1Iw3bVO5aa2GHzZMr6o1tfARiTPPQgfUz6WozWEoNALdvfxoRNIlDB8z00Tfsu1V3b");
            let oxxo = {
                price: type === "payment" ? price * 100 : plan.price,
                stripeid: user.stripe_id,
                purchase: {
                    cash: type === "payment" ? price * 100 : plan.price,
                    date: new Date(),
                    productName: type === "payment" ? "Donación" : "Suscripción PowerCoach Oxxopay",
                    user_name: user.name,
                    user_id: user.id,
                    user_email: user.email,
                    coach_stripe_id: plan.planId,
                    payment_method: "Oxxopay",
                    purchase_type: "powercoach",
                    sub_type: selectedPlan === 1 ? "year" : "month",
                    powercoachId: powercoach.id
                },
            }
            const oxxoPay: any = httpsCallable(functions, 'payWithOXXO_ACC');
            await oxxoPay(oxxo).then(async (res: any) => {
                console.log(res);
                stripe.confirmOxxoPayment(res.data.data.client_secret, {
                    payment_method: {
                        billing_details: {
                            name: user.name + " aprende con cecy",
                            email: user.email,
                        },
                    },
                }).then((res: any) => {
                    console.log(res);
                })
            });
            setIsLoading(false);
            return;
        }
        if (type === "payment") {
            let payment = {
                customer_id: user.stripe_id,
                pm: "",
                price: price * 100,
                powerCoachId: powercoach.id,
                user_id: userData.id,
                user_name: user.name,
                type: "donation"
            }
            let invoice = {
                cash: price * 100,
                coach_stripe_id: powercoach.priceId,
                date: new Date(),
                productName: "Suscripción PowerCoach Oxxopay",
                status: "paid",
                user_email: user.email,
                user_id: user.id,
                user_name: user.name
            }
            if (!card.paymentMethodId) {
                let addCard = {
                    cardData: {
                        number: card.number,
                        exp_month: card.date.substring(0, 2),
                        exp_year: card.date.substring(3, 5),
                        cvc: card.cvc
                    },
                    userId: user.stripe_id
                }
                const addPm: any = httpsCallable(functions, 'newCard_ACC');
                await addPm(addCard).then(async (res: any) => {
                    if (res.data.error) {
                        cardErrors(res).then((response: any) => {
                            setMessage(response);
                            setShow(true);
                            setIsLoading(false);
                        })
                    } else {
                        payment.pm = res.data.data.id
                        let tempCard = {
                            brand: res.data.data.card.brand,
                            exp_month: res.data.data.card.exp_month,
                            exp_year: res.data.data.card.exp_year,
                            last4: res.data.data.card.last4,
                            name: user.name,
                            stripe_id: res.data.data.id
                        }
                        addPaymentMethod(user.id, res.data.data.id, tempCard).then(async () => {
                            const charge: any = httpsCallable(functions, 'createCharge_ACC');
                            await charge(payment).then(async (res: any) => {
                                setSuccess(true);
                                setIsLoading(false)
                            })
                        })
                    }
                })
            } else {
                payment.pm = card.paymentMethodId;
                const charge: any = httpsCallable(functions, 'createCharge_ACC');
                await charge(payment).then(async (res: any) => {
                    setSuccess(true);
                    setIsLoading(false);
                })
            }
        }
        if (type === "subscription") {
            if (!card.paymentMethodId) {
                let addCard = {
                    cardData: {
                        number: card.number,
                        exp_month: card.date.substring(0, 2),
                        exp_year: card.date.substring(3, 5),
                        cvc: card.cvc
                    },
                    userId: user.stripe_id
                }
                const addPm: any = httpsCallable(functions, 'newCard_ACC');
                await addPm(addCard).then(async (res: any) => {
                    if (res.data.error) {
                        cardErrors(res).then((response: any) => {
                            setMessage(response);
                            setShow(true);
                            setIsLoading(false);
                        })
                    } else {
                        let tempCard = {
                            brand: res.data.data.card.brand,
                            exp_month: res.data.data.card.exp_month,
                            exp_year: res.data.data.card.exp_year,
                            last4: res.data.data.card.last4,
                            name: user.name,
                            stripe_id: res.data.data.id
                        }
                        addPaymentMethod(user.id, res.data.data.id, tempCard).then(async () => {
                            const subscription: any = httpsCallable(functions, 'createSubscription_ACC');
                            tempSubscription.paymentMethodId = tempCard.stripe_id;
                            await subscription(tempSubscription).then((res: any) => {
                                if (res.data.error) {
                                    alert("Ocurrio un error");
                                    setIsLoading(false);
                                }
                                setSuccess(true);
                                setIsLoading(false);
                            })
                        })
                    }
                })
            } else {
                const subscription: any = httpsCallable(functions, 'createSubscription_ACC');
                tempSubscription.paymentMethodId = card.paymentMethodId;
                await subscription(tempSubscription).then((res: any) => {
                    if (res.data.error) {
                        alert("Ocurrio un error");
                        setIsLoading(false);
                    }
                    setSuccess(true);
                    setIsLoading(false);
                })
            }
        }
    }

    return (
        <div className="checkout-main-container">
            <SuccessModal success={success}></SuccessModal>
            <AlertModal title="Error de pago" message={message} show={show} onHide={() => { setShow(false) }}></AlertModal>
            <div className="left-content">
                <h2>Plan de pago:</h2>
                {type === "subscription" ? <div className="buttons-container">
                    <button className={selectedPlan === 0 ? "active" : ""} onClick={() => {
                        setSelectedPlan(0);
                        setPlan({ ...plan, price: powercoach?.precioCard, planId: powercoach?.priceId });
                        handlePaypal();
                    }}>Suscripcion PowerCoach Mensual</button>
                    <button className={selectedPlan === 1 ? "active" : ""} onClick={() => {
                        setSelectedPlan(1);
                        setPlan({ ...plan, price: powercoach?.precioCard_year, planId: powercoach?.priceId_year });
                        handlePaypal();
                    }}>Suscripcion PowerCoach Anual</button>
                </div> :
                    <div className="buttons-container">
                        <button className="active">Donación</button>
                    </div>
                }
                <div className="paymentMethods-container">
                    <h3>Total a pagar: {type === "subscription" ? <span>${plan.price / 100} MXN</span> : <span>${price} MXN</span>} </h3>
                    <div className="paymentMethods-buttons-container">
                        <div className={`paymentMethod-button ${selectedPm === 0 ? "active" : ""}`} onClick={() => {
                            setSelectedPm(0);
                            setPlan({ ...plan, paymentMethod: "card" });
                        }}>
                            <div className="cards">
                                <div className="visa">
                                    <img src="/assets/images/visa.png" alt="" />
                                </div>
                                <img src="/assets/images/master.png" alt="" />
                                <div className="express">
                                    <img src="/assets/images/express.png" alt="" />
                                </div>
                            </div>
                            <p>Tarjetas</p>
                        </div>
                        <div className={`paymentMethod-button ${selectedPm === 1 ? "active" : ""}`} onClick={() => {
                            setSelectedPm(1);
                            setPlan({ ...plan, paymentMethod: "oxxo" });
                        }}>
                            <div className="cards">
                                <img src="/assets/images/oxxo.jpg" alt="" />
                            </div>
                            <p>Oxxo Pay</p>
                        </div>
                        {(paypal && powercoach) && <PayPalScriptProvider options={{
                            "client-id": "AXn-e1UCYo0EoE0WZTj-_1KRHkk0xEA-ei_XWLYDWAq0qmfaIC_6LYdDMB0DNOEsfisf5A5G80_MxIbn",
                            currency: "MXN",
                            'vault': true,
                        }}
                        >
                            <button className={`paymentMethod-button ${selectedPm === 3 ? "active" : ""}`}>
                                {type == 'subscription' && <PayPalButtons
                                    className="paypal"
                                    style={{
                                        color: "blue",
                                        layout: 'horizontal',
                                        height: 55,
                                        label: "subscribe",
                                        // tagline: false,
                                    }}
                                    onClick={() => {
                                        if (userData === null) {
                                            // navigate("/login");
                                            navigate("/");
                                            return;
                                        }
                                    }}
                                    createSubscription={(data, actions) => {
                                        return actions.subscription.create({
                                            plan_id: selectedPlan === 1 ? powercoach.paypalPriceId_year : powercoach.paypalPriceId
                                        })
                                    }}
                                    onApprove={async (data: any, actions) => {
                                        let final_date = new Date();
                                        let sub = {
                                            sub_type: selectedPlan === 1 ? "year" : "month",
                                            stripe_id: data.subscriptionID,
                                            final_date: selectedPlan === 1 ? new Date(final_date.setDate(final_date.getDate() + 365)) : new Date(final_date.setDate(final_date.getDate() + 30)),
                                            cancel_at_period_end: selectedPlan === 1 ? true : false
                                        }
                                        let tempUser = {
                                            paypalEmail: userData.email,
                                            paypalSub: data.subscriptionID,
                                            id: userData.id
                                        }
                                        await updatePowerCoachCount(powercoach);
                                        updateUserInfo(tempUser);
                                        updateUserPowerCoachSubscription(userData.id, powercoach.id, sub);
                                        setSuccess(true);
                                        return data
                                    }}
                                />}
                            </button>
                        </PayPalScriptProvider>}
                    </div>
                </div>

                <div className="card-container">
                    {selectedPm === 0 && <div className="card-display">
                        <div className="red"></div>
                        <div className="yellow"></div>
                        <h4>Academia de Cecy</h4>
                        <img src="/assets/images/chip-card.png" alt="" />
                        <ReactInputMask placeholder="XXXX&emsp;XXXX&emsp;XXXX&emsp;XXXX" mask="9999 9999 9999 9999" value={card.number} maskChar={null} disabled></ReactInputMask>
                        <input type="text" placeholder="Nombre" disabled value={card.holder} />
                        <ReactInputMask className="date" placeholder="mm/yy" mask="99/99" maskChar={null} value={card.date} disabled></ReactInputMask>
                    </div>}
                    {selectedPm === 0 && <div className="form">
                        <select onChange={(e: any) => {
                            if (e.target.value) {
                                setCard(cards[e.target.value]);
                            } else {
                                setCard({});
                            }
                        }}>
                            <option value="">Seleccionar método de pago</option>
                            <option value="">Nuevo</option>
                            {cards.map((x: any, index: number) => {
                                return (
                                    <option key={"card" + index} value={index}>{x.brand} **** **** **** {x.last4}</option>
                                )
                            })}
                        </select>
                        {!card.paymentMethodId && <div className="input-container">
                            <FaUser />
                            <input type="text" placeholder="Titular de la tarjeta" defaultValue={card?.holder} onChange={(e) => {
                                setCard({ ...card, holder: e.target.value });
                            }} />
                        </div>}
                        {!card.paymentMethodId && <div className="input-container">
                            <FaCreditCard />
                            <ReactInputMask placeholder="Número de tarjeta" mask="9999 9999 9999 9999" maskChar={null} onChange={(e) => {
                                setCard({ ...card, number: e.target.value });
                            }}></ReactInputMask>
                        </div>}
                        <div className="two-fields">
                            {!card.paymentMethodId && <div className="input-container field">
                                <FaCalendar />
                                <ReactInputMask placeholder="Fecha de vencimiento" mask="99/99" maskChar={null} onChange={(e) => {
                                    setCard({ ...card, date: e.target.value });
                                }}></ReactInputMask>
                            </div>}
                            <div className="input-container field">
                                <FaKey />
                                <input type="password" placeholder="CVV" autoComplete="new-password" onChange={(e) => {
                                    setCard({ ...card, cvc: parseInt(e.target.value) });
                                }} />
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
            <div className="description">
                <h3>Aprende con Cecy</h3>
                <img src={plan.powerCoachPhoto} alt="" />
                <h3>{plan.powerCoachName}</h3>
                <div className="price-container">
                    <p>Total</p>
                    {type === "subscription" ? <p>${plan.price / 100}.00 MXN</p> :
                        <p>${price}.00 MXN</p>}
                </div>
                <p>{plan.about}</p>
                {!isLoading ? <button onClick={pay}>Continuar con el pago</button> :
                    <Spinner width={.5} size={4}></Spinner>
                }
            </div>
        </div>
    )

}