import { addDoc, collection, getDocs, query } from "firebase/firestore";
import { db } from "../firebase/FirebaseConfig";
import { newCommentData } from "../interfaces/ICourse";

export async function getCommentsByForumId(powerCoachId: string, forumId: string) {
	const q = query(collection(db, "powerCoaches", powerCoachId, "community", forumId));
	await getDocs(q);
}

export async function newCommentForForum(powerCoachId: string, forumId: string, commentData: newCommentData) {
	await addDoc(collection(db, "powerCoaches", powerCoachId, "community", forumId, "comments"), {
		...commentData,
	});

    return {message: "comentario agregado con exito!"}
}
