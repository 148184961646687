import { useEffect, useState } from "react";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import "./Admin.scss";

export function Admin() {
    const [path, setPath] = useState(useLocation().pathname);

    let location = useLocation();
    useEffect(() => {
        setPath(location.pathname);
    }, [location]);

    return (
        <div className="admin-main-container">
            <div className="side-bar">
                <ul>
                    <NavLink to="" >
                        <li className={`${path === "/admin" ? "active" : ""}`}>Usuarios</li>
                    </NavLink>
                    <NavLink to="/admin/powercoaches" >
                        <li className={`${path === "/admin/powercoaches" ? "active" : ""}`}>PowerCoaches</li>
                    </NavLink>
                </ul>
            </div>
            <Outlet />
        </div>
    )
}