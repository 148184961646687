import React, { useState } from 'react'
import './Section4.scss'
import { testimonies_array } from '../../../../utils/arrays';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
const user_review = '/assets/redesign/checkout/user-review.png';
type TDirection = 'left' | 'right';

export const Section4 = () => {
    const [position, setPosition] = useState(0)
    const changeTestimony = (direction: TDirection) => {
        if(direction === "left"){
            setPosition(p => p-1)
        }
        if(direction === "right"){
            setPosition(p => p+1)
        }
    }
    const SelectColor = (index: number) => {
        switch ( (index+1)%3 ) {
           case 1:
                 return "#160041";
                 break;
           case 2:
                 return "#935054";
                 break;
           case 0:
                 return "#FF5757";
                 break;
        }
    }
    const SelectClass = (index: number) => {
        switch ( (index+1)%3 ) {
           case 1:
                 return "scroll-1";
                 break;
           case 2:
                 return "scroll-2";
                 break;
           case 0:
                 return "scroll-3";
                 break;
        }
    }
  return (
    <div className='section-four'>
        <div className='main-contain'>
            <h2 className='title'>Testimonios</h2>
            <div className='testimony-contain'>
                {
                    testimonies_array.map((testimony: any, index: number)=> {
                        return (
                            <div 
                                className={'bottom-contain ' + (SelectClass(index))}
                                style={{translate: `${-100*position}%`}}
                                key={"testimonios-"+index}
                            >
                                <div className='row-data'>
                                    {/* <img src={back_ticks} className='back-ticks' /> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53 42" >
                                        <path d="M0 41.5V30.2221C0 26.8935 0.619204 23.3887 1.85761 19.7077C3.09602 16.0267 4.81818 12.5024 7.0241 9.13467C9.23001 5.76695 11.8229 2.88872 14.8028 0.5L23.3943 6.72635C21.0336 10.2116 19.0212 13.8534 17.3571 17.6519C15.693 21.4503 14.8609 25.5817 14.8609 30.0458V41.5H0ZM29.6057 41.5V30.2221C29.6057 26.8935 30.2249 23.3887 31.4633 19.7077C32.7017 16.0267 34.4239 12.5024 36.6298 9.13467C38.8357 5.76695 41.4286 2.88872 44.4085 0.5L53 6.72635C50.6393 10.2116 48.6269 13.8534 46.9628 17.6519C45.2986 21.4503 44.4666 25.5817 44.4666 30.0458V41.5H29.6057Z" fill={SelectColor(index)}/>
                                    </svg>
                                    <img src={testimony !== null ? testimony.img : user_review} className='user' />
                                </div>
                                <p 
                                    className="about"
                                >
                                    {testimony !== null && testimony.about}
                                </p>
                                <div className='row-data'>
                                    <p className='name'>{testimony !== null && testimony.name}</p>
                                    {/* <img src={back_ticks} className='back-ticks rotation' /> */}
                                    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 53 42" >
                                        <path d="M23.3875 0.5V11.7779C23.3875 15.1065 22.7669 18.6113 21.5258 22.2923C20.3235 25.9341 18.6169 29.4389 16.4061 32.8066C14.1954 36.1743 11.5968 39.0721 8.61032 41.5L0 35.2736C2.28833 31.9059 4.26637 28.3228 5.93414 24.5244C7.64069 20.7259 8.49396 16.5358 8.49396 11.9542V0.5H23.3875ZM53 0.5V11.7779C53 15.1065 52.3794 18.6113 51.1383 22.2923C49.936 25.9341 48.2294 29.4389 46.0187 32.8066C43.8079 36.1743 41.2093 39.0721 38.2228 41.5L29.6125 35.2736C31.9008 31.9059 33.8789 28.3228 35.5466 24.5244C37.2532 20.7259 38.1065 16.5358 38.1065 11.9542V0.5H53Z" fill={SelectColor(index)} />
                                    </svg>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
        <div className='button-contain'>
            <button 
                className={'arrow-contain ' + (position === 0 ? "disable": "")} 
                onClick={()=>changeTestimony('left')}
                disabled ={position === 0}
            >
                <FaChevronLeft/>
            </button>
            <button 
                className={'arrow-contain ' + (position === testimonies_array.length ? "disable": "")} 
                onClick={()=>changeTestimony('right')}
                disabled ={position === testimonies_array.length}
            >
                <FaChevronRight/>
            </button>
        </div>
    </div>
  )
}
