import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { db } from "../../firebase/FirebaseConfig";
import { forumCommentI, IForum } from "../../interfaces/IForum";
import { powerCoach } from "../../interfaces/PowerCoaches";
import { getUserImageByEmail } from "../../services/UserService";
import { LectureNewComment } from "../../components/LectureNewComment/NewComment";
import { ForumCommentCard } from "./components/ForumCommentCard";
import "./Forum.scss";

interface forumOutletContext {
	powerCoach: powerCoach;
	forum: IForum;
}

export function Forum() {
	const { powerCoach, forum }: forumOutletContext = useOutletContext();
	const [comments, setComments] = useState<Array<forumCommentI>>([]);

	const getCommentsInRealTime = async () => {
		const q = query(collection(db, "powerCoaches", powerCoach.id, "community", forum.id, "comments"), orderBy("createAt", "desc"));

		onSnapshot(q, async (qs) => {
			const commentsArray: Array<forumCommentI> = [];
			if (qs.size > 0) {
				await Promise.all(
					qs.docs.map(async (comment) => {
						const commentData = {
							id: comment.id,
							...comment.data(),
						} as forumCommentI;
						commentsArray.push(commentData);
					})
				);
			}
			setComments(commentsArray);
		});
	};

	useEffect(() => {
		getCommentsInRealTime();
	}, []);

	return (
		<div className="forum-component-main-container">
			<div className="go-back-btn-container">
				<button>Atrás</button>
			</div>
			<div className="title-container">
				<h1>Foro: {forum.socialNetwork}</h1>
			</div>
			<div className="text-container">
				<p>{forum.description}</p>
			</div>
			<div className="new-comment-container">
				<LectureNewComment forumId={forum.id} />
			</div>
			{comments.length > 0 && (
				<div className="comments-cards-container">
                    {comments.map((comment, i) => {
                        return(
                            <ForumCommentCard forumComment={comment} key={"forum-comment-card-" + i} /> 
                        )
                    })}
					<div className=""></div>
				</div>
			)}
		</div>
	);
}
