import { useEffect, useState } from "react"
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { Spinner } from "../../../../../../components/spinner/Spinner";
import { lecture } from "../../../../../../interfaces/ICourse";
import { getLecturesByModuleId } from "../../../../../../services/CourseService";
import { createLesson, deleteLesson, updateLessonInfo, uploadFile } from "../../../../../../services/PowerCoachService";
import { v4 as uuidv4 } from 'uuid';
import "./PowerCoachCourseLessons.scss";

export function PowerCoachCourseLessons({ setStep, courseId, powercoachId, module }: any) {
    const [lesson, setLesson] = useState({} as lecture);
    const [lessons, setLessons] = useState<Array<lecture>>([]);
    const [files, setFiles] = useState<any>([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [change, setChange] = useState(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [loadFiles, setLoadFiles] = useState(false);

    useEffect(() => {
        setLessons(module.lectures);
        console.log(lesson);

    }, [])

    const getLessons = () => {
        getLecturesByModuleId(powercoachId, courseId, module.id).then((res) => {
            setLessons(res);
        })
    }

    const reloadFiles = () => {
        setLoadFiles(true);
        setTimeout(() => {
            setLoadFiles(false);
        }, 200);
    }

    const getFiles = async (e: any) => {
        let tempFiles: any = lesson.files;
        const allFiles = e.target.files;
        for (let i = 0; i < allFiles.length; i++) {
            tempFiles.push(allFiles[i]);
        }
        setSelectedFiles(tempFiles);
        reloadFiles()
    }
    const removeFile = (index: number) => {
        const tempFiles: any = [...lesson.files.slice(0, index), ...lesson.files.slice(index + 1)];
        lesson.files = tempFiles
        setSelectedFiles(tempFiles);
        reloadFiles()
    }

    const deleteThisLesson = () => {
        setIsLoading(true);
        deleteLesson(powercoachId, courseId, module.id, lesson).then(() => {
            getLessons();
            alert("Lección Eliminada!");
            setChange(0);
            setIsLoading(false);
        })
    }

    const update = async () => {
        setIsLoading(true);
        let tempFiles: any = [];
        if (!lesson.title || !lesson.videoUrl || !lesson.description) {
            alert("Complete todos los campos!");
            setIsLoading(false);
            return;
        }
        if (lesson.id) {
            console.log(selectedFiles);

            await Promise.all(selectedFiles.map(async (x: any, index: number) => {
                if (!x.uuid) {
                    let id = uuidv4()
                    const url = await uploadFile(x, `admin/power-coaches/${id}`);
                    tempFiles.push({ url: url, uuid: id, name: x.name })
                } else {
                    tempFiles.push(x)
                }
            }))
            lesson.files = tempFiles;

            updateLessonInfo(powercoachId, courseId, module.id, lesson).then(() => {
                getLessons();
                alert("Lección Editada!");
                setChange(0);
                setIsLoading(false);
            })
        } else {
            createLesson(powercoachId, courseId, module.id, lesson).then(() => {
                getLessons();
                alert("Lección creada!");
                setChange(0);
                setIsLoading(false);
            })
        }
    }

    return (
        <div className="powercoach-course-lessons-container">
            <h2>Editar contenido</h2>
            {change === 0 && <div className="top">
                <div className="return"
                    onClick={() => { setStep(2) }}
                >
                    <MdOutlineArrowBackIosNew />
                    <p>Atras</p>
                </div>
                <h2>Lecciones</h2>
                <div className="buttons">
                    {/* {(lesson.id || change === 1) && <button className="delete" onClick={() => { setChange(0); setLesson({}); }}>Lección</button>} */}
                    <button onClick={() => {
                        setChange(1); setLesson({} as lecture);
                    }}>{lesson.id ? "Editar" : "Agregar"} lección</button>
                </div>
            </div>}
            {change === 0 && lessons.map((x, index: number) => {
                return (
                    <div className="lesson-card" key={"lesson" + index}>
                        <p>{x.title}</p>
                        <a onClick={() => { setChange(1); setLesson(x); setFiles([]); }}>Editar</a>
                    </div>
                )
            })}
            {change === 1 && <div className="lesson-container">
                <div className="return"
                    onClick={() => { setChange(0) }}
                >
                    <MdOutlineArrowBackIosNew />
                    <p>Atras</p>
                </div>
                <div className="form">
                    <div className="input-container">
                        <label>Titulo de lección:</label>
                        <input value={lesson.title} onChange={(e) => {
                            setLesson({ ...lesson, title: e.target.value })
                        }} />
                    </div>
                    <div className="input-container">
                        <label>https://player.vimeo.com/video/:</label>
                        <input type="number" value={lesson.videoUrl} onChange={(e) => {
                            setLesson({ ...lesson, videoUrl: e.target.value })
                        }} placeholder="661464420" />
                    </div>
                    <div className="input-container">
                        <label>Descripción:</label>
                        <input value={lesson.description} onChange={(e) => {
                            setLesson({ ...lesson, description: e.target.value })
                        }} />
                    </div>
                    <div className="input-container">
                        <label>Agregar archivos:</label>
                        <input type="file" onChange={(e: any) => { getFiles(e) }} multiple />
                    </div>
                    <div>
                        <div className="files-container">
                            {!loadFiles && lesson.files?.map((x: any, i: number) => {
                                return (
                                    <div className="file">
                                        <p>{x.name}</p>
                                        <i onClick={() => { removeFile(i) }}>x</i>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    {!isLoading ? <button onClick={update}>{lesson.id ? "Editar" : "Crear"} lección</button> :
                        <Spinner width={.5} size={4}></Spinner>
                    }
                    {lesson.id && <button className="delete" onClick={deleteThisLesson}>Eliminar lección</button>}
                </div>

            </div>}
        </div>
    )
}