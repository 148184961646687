import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { lecture } from "../../../../interfaces/ICourse";
import { getVideoData } from "../../../../services/CourseService";

interface otherLectureCardProps {
	moduleId: string;
	lecture: lecture;
}

export function OtherLectureCard(props: otherLectureCardProps) {
	const [imageUrl, setImageUrl] = useState<string>("");

	const getImage = async () => {
		getVideoData(props.lecture.videoUrl).then((res) => {
            if(res.thumbnail_url !== undefined) {
                setImageUrl(res.thumbnail_url);
            } 
		});
	};

	useEffect(() => {
        getImage();
    }, []);

	return (
		<Link to={`../${props.moduleId}/${props.lecture.id}`}>
			<div className="other-lecture-card">
                {imageUrl.length > 0 && (
                    <img src={imageUrl} alt="" />
                )}
				<div className="label-lecture-name">
					<h3>{props.lecture.title}</h3>
				</div>
			</div>
		</Link>
	);
}
