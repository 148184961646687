import { useContext, useEffect } from "react";
import "./Thanks.scss"

import { IconContext } from "react-icons";
import { IoIosArrowForward } from 'react-icons/io'
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import Vimeo from "@u-wave/react-vimeo";

export const Thanks = () => {
   const navigate = useNavigate();
   const user = useContext(UserContext);

   useEffect(() => {
      console.log(localStorage.getItem("language"));

   }, [])


   const goTo = () => {
      let english_id = "VoQIZfjMmBS5pXdUwfUhC4d2aot2";
      let french_id = "qFBYxqYXFZhyld4KvM5nzd7Z2ST2";
      if (user === null || localStorage.getItem("language") === null)
         navigate(`/academia`);
      if (localStorage.getItem("language") === "english")
         navigate(`/academia/CURSO DE INGLÉS`);
      if (localStorage.getItem("language") === "french")
         navigate(`/academia/CURSO DE FRANCÉS`);
   }

   return (
      <div className="Thanks_Page">
         <img className="imgBack1" src="assets/redesign/svg/Grad_05.svg" alt="aprende con cecy" />
         <img className="imgBack2" src="assets/redesign/svg/Grad_11.svg" alt="aprende con cecy" />
         <div className="main-container">
            <h2 className="title"> Llegó la hora de aprender </h2>
            <p className="words"> Antes que nada y sobre todo, tengo un súper mensaje de bienvenida para ti. ¡Nos vemos muy pronto!  </p>
            <div className="wrapImg">
               {/* <img className="cecy" src="assets/redesign/cecyVideo.png" alt="aprende con cecy" /> */}
               <Vimeo
                  id={"thankyou"}
                  className="video-cecy"
                  video={"https://vimeo.com/911324237?share=copy"}
                  controls={true}
                  volume={80}
                  // onReady={video}
                  // autoplay
               />
            </div>
            <div className="wrapBtn">
               <button className="empezarBtn" onClick={goTo}>
                  <span>
                     Empezar Clases
                  </span>
                  <div className={"iconoBoton"}>
                     <IconContext.Provider value={{ className: "sizeIcon" }}>
                        <IoIosArrowForward />
                     </IconContext.Provider>
                  </div>
               </button>
            </div>

         </div>
      </div>
   )
}
