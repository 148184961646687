import { httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import { MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos } from "react-icons/md";
import { useOutletContext } from "react-router-dom";
import { Spinner } from "../../../../components/spinner/Spinner";
import { functions } from "../../../../firebase/FirebaseConfig";
import { IUser } from "../../../../interfaces/IUser";
import { powerCoach } from "../../../../interfaces/PowerCoaches";
import { getAllUsers } from "../../../../services/AdminService";
import { getPowerCoachSubByUser, updateUserPowerCoachSubscription } from "../../../../services/UserService";
import "./PowerCoachUsers.scss";

export function PowerCoachUsers() {
    const powerCoach: powerCoach = useOutletContext();
    const [step, setStep] = useState(0);
    const [allUsers, setAllUsers] = useState<Array<IUser>>([]);
    const [user, setUser] = useState({} as IUser);
    const [userSubscription, setUserSubscription] = useState<any>({ stripe_id: "" });
    const [days, setDays] = useState<number>(0);
    const [daysLeft, setDaysLeft] = useState(0);
    const [pages, setPages] = useState<any>([]);
    const [page, setPage] = useState(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);

    const getPages = (users: any) => {
        let tempPages = Math.ceil(users.length / 50)
        let tempArray: any = [];
        for (let i = 0; i < tempPages; i++) {
            tempArray.push([]);
        }
        let total = 50;
        let count = 0;
        for (let i = 0; i < tempPages; i++) {
            for (let w = 0; w <= users.length; w++) {
                if (w >= count && tempArray[i].length < total) {
                    if (users[w] && tempArray[i].findIndex((e: any) => e === users[w]) === -1)
                        tempArray[i].push(users[w]);
                    count++;
                }
            }
        }
        return tempArray;
    }

    useEffect(() => {
        getAllUsers().then((res) => {
            setAllUsers(res);
            setPages(getPages(res));
            setIsLoading(false);
        })
    }, [])

    const filter = (search: string) => {
        let tempUsers = allUsers.filter((x) => (x.email?.includes(search)))
        setPages(getPages(tempUsers));
    }

    const handleUser = (data: any) => {
        getPowerCoachSubByUser(data.id, powerCoach.id).then((res) => {
            if (res) {
                setUserSubscription(res);
            }
            if (res?.initial_date && res.final_date) {
                const date = new Date();
                let tempFinalDate = new Date(res.final_date.seconds * 1000);
                if (res.final_date.seconds < date.getTime() / 1000) {
                    setDaysLeft(0)
                } else {
                    setDaysLeft((tempFinalDate.getTime() - date.getTime()) / (1000 * 60 * 60 * 24));
                }
            } else {
                setDaysLeft(0)
            }
        })
        setDays(0);
        setUser(data);
    }

    const updateUserDays = () => {
        setIsUpdating(true);
        let tempDate = new Date();
        let today = new Date();
        tempDate.setDate(tempDate.getDate() + days);
        let data = {};

        if (!userSubscription.stripe_id) {
            let addDays = httpsCallable(functions, 'createSubTrial');
            addDays({
                user: user,
                newDate: Math.floor(tempDate.getTime() / 1000),
            }).then((res: any) => {
                data = {
                    stripe_id: res.data.id,
                    final_date: tempDate,
                    initial_date: new Date(),
                    active: true,
                    days: days,
                    cancel_at_period_end: true,
                }
                updateUserPowerCoachSubscription(user.id, powerCoach.id, data).then(() => {
                    setDaysLeft((tempDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
                    alert("Suscripción actualizada");
                    setIsUpdating(false);
                })
            })
        } else {
            let lastDay = new Date();
            if (userSubscription.final_date.seconds > today.getTime() / 1000) {
                lastDay = new Date(Date.parse(userSubscription.final_date.toDate()));
            }
            lastDay.setDate(lastDay.getDate() + days);
            let updateDays = httpsCallable(functions, 'addTrialDays');
            updateDays({
                subId: userSubscription.stripe_id,
                newDate: Math.floor(lastDay.getTime() / 1000),
            }).then((res: any) => {
                if ("raw" in res.data) {
                    let addDays = httpsCallable(functions, 'createSubTrial');
                    addDays({
                        user: user,
                        newDate: Math.floor(tempDate.getTime() / 1000),
                    }).then((res: any) => {
                        data = {
                            stripe_id: res.data.id,
                            final_date: tempDate,
                            initial_date: new Date(),
                            active: true,
                            days: days,
                            cancel_at_period_end: true,
                        }
                        updateUserPowerCoachSubscription(user.id, powerCoach.id, data).then(() => {
                            setDaysLeft((tempDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
                            alert("Suscripción actualizada");
                            setIsUpdating(false);
                        })
                    })
                } else {
                    data = {
                        final_date: lastDay,
                        active: true,
                        days: days + userSubscription.days,
                        cancel_at_period_end: true,
                    }
                    updateUserPowerCoachSubscription(user.id, powerCoach.id, data).then(() => {
                        setDaysLeft((lastDay.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
                        alert("Suscripción actualizada");
                        setIsUpdating(false);
                    });
                }
            })
        }
    }

    return (
        <div className="powercoach-users-container">
            {step === 0 && <div className="page-one">
                <div className="top">
                    <h2>Usuarios</h2>
                    <input className="filter" type="text" placeholder="Buscar usuario" onChange={(e) => {
                        filter(e.target.value)
                    }} />
                </div>
                <div className="pagination">
                    {page > 0 && < MdOutlineArrowBackIosNew onClick={() => {
                        setPage(page - 1);
                    }} />}
                    <div className="page">
                        <p>{page + 1}</p>
                    </div>
                    <MdOutlineArrowForwardIos onClick={() => {
                        setPage(page + 1);
                    }} />
                </div>
                {!isLoading ? <div className="users-container">
                    {pages[page]?.map((user: IUser, index: number) => {
                        return (
                            <div className="user-card" key={"user" + index}>
                                <p>{user.email}</p>
                                <p>{user.name}</p>
                                <a onClick={() => {
                                    setStep(1);
                                    handleUser(user);
                                }}>Editar</a>
                            </div>
                        )
                    })}
                </div> :
                    <Spinner width={1} size={10}></Spinner>}
            </div>}
            {step === 1 && <div className="page-two">
                <div className="return"
                    onClick={() => { setStep(0); setPages(getPages(allUsers)); }}
                >
                    <MdOutlineArrowBackIosNew />
                    <p>Atras</p>
                </div>
                <h2>{user.name}</h2>
                <div className="top">
                    <p>Agregar días</p>
                    <p>Este usuario tiene {daysLeft.toFixed(0)} días de prueba</p>
                </div>
                <input type="number" placeholder="1" defaultValue={days} onChange={(e: any) => { setDays(parseInt(e.target.value)) }} />
                {!isUpdating ? <button onClick={updateUserDays}>Agregar</button> :
                    <Spinner width={.5} size={4}></Spinner>}
            </div>}
        </div>
    )
}