import { useEffect, useState } from "react";
import { powerCoach } from "../../../../interfaces/PowerCoaches";
import { getAllPowercoach, updatePowerCoachCommissions } from "../../../../services/AdminService";
import "./AdminPowerCoaches.scss";

export function AdminPowerCoaches() {
    const [powerCoaches, setPowerCoaches] = useState<Array<powerCoach>>([]);

    useEffect(() => {
        getAllPowercoach().then((res) => {
            setPowerCoaches(res);
        })
    }, [])

    const update = (value: number, index: number, type: string) => {
        if (type === "1") {
            powerCoaches[index].commission = value
        }
        if (type === "2") {
            powerCoaches[index].commissionPaypal = value
        }
        if (type === "3") {
            powerCoaches[index].commissionStripe = value
        }
    }

    const save = (powercoach: powerCoach) => {
        updatePowerCoachCommissions(powercoach).then(() => {
            alert("Power Coach actualizado!")
        })
    }

    return (
        <div className="admin-powercoach-main-container">
            <div className="table-header">
                <p>Correo electrónico</p>
                <p>Nombre asignado</p>
                <p>Cien Potencia</p>
                <p>Paypal</p>
                <p>Stripe</p>
                <p>Ver</p>
            </div>
            <div className="user-table">
                {powerCoaches.map((powercoach: powerCoach, index: number) => {
                    return (
                        <div className="user-container" key={"user" + index}>
                            <p>{powercoach.email}</p>
                            <p>{powercoach.name}</p>
                            <input type="number" defaultValue={powercoach?.commission} onChange={(e: any) => {
                                update(parseInt(e.target.value), index, "1")
                            }} />
                            <input type="number" defaultValue={powercoach.commissionPaypal} onChange={(e: any) => {
                                update(parseInt(e.target.value), index, "2")
                            }} />
                            <input type="number" defaultValue={powercoach.commissionStripe} onChange={(e: any) => {
                                update(parseInt(e.target.value), index, "3")
                            }} />
                            <p onClick={() => { save(powercoach) }}>Guardar</p>
                        </div>
                    )
                })}
            </div>
        </div>
    )
} 