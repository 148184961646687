import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { lecture } from "../../../../interfaces/ICourse";
import { getVideoData } from "../../../../services/CourseService";
import "./LessonCard.scss";

interface CourseCardPropsI {
	lecture: lecture;
	moduleId: string;
}

export function LectureCard(props: CourseCardPropsI) {
	const [imageUrl, setImageUrl] = useState<string>("");

	const getPreviewImage = async () => {
		await getVideoData(props.lecture.videoUrl).then((res) => {
			setImageUrl(res.thumbnail_url);
		});
	};

	useEffect(() => {
		if(imageUrl === "") {
			getPreviewImage();
		}
	}, [imageUrl]);

	useEffect(() => {
		if(imageUrl !== "") {
			setImageUrl("");
		}
	}, [props.lecture]);

	return (
		<Link className="lesson-link-card" to={`${props.moduleId}/${props.lecture.id}`}>
			<div className="card lesson-card">
				<div className="image-container">{imageUrl !== "" && <img src={imageUrl} alt="" />}</div>
				<div className="lesson-info-container">
					<div className="title">
						<h2>{props.lecture.title}</h2>
					</div>
					<div className="description">
						<p>{props.lecture.description}</p>
					</div>
				</div>
			</div>
		</Link>
	);
}
