import "./PaymentMethodModal.scss";
import { FaUser, FaCreditCard, FaCalendar, FaKey } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { useState } from "react";
import { functions } from "../../../firebase/FirebaseConfig";
import { httpsCallable } from "firebase/functions";
import { IUser } from "../../../interfaces/IUser";

interface PaymentModalProps {
    user: IUser,
    handleClick: () => void;
    setIsLoading: any
}

export function PaymetnMethodModal(props: PaymentModalProps) {
    const [card, setCard] = useState({
        holder: "",
        number: "",
        cvc: "",
        exp_month: "",
        exp_year: "",
    });

    const addCard = async () => {
        props.setIsLoading(true);
        const data = {
            stripeid: props.user.stripe_id,
            userid: props.user.id,
            card: card
        }
        const addPm: any = httpsCallable(functions, 'createPaymentMethod');
        await addPm(data).then((res: any) => {
            if (res.data.error) {
                alert("Su tarjeta fue declinada, intente con otra tarjeta, gracias!");
                props.setIsLoading(false);
            } else {
                alert("Método de pago añadido!");
                props.handleClick();
            }
        })
    }

    return (
        <div className="modal payment-modal fade" id="paymentMethod" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body">
                        <ImCross className="close" id="close-pm" data-bs-dismiss="modal" />
                        <div className="content">
                            {/* Payment form */}
                            <div className="form">
                                <div className="row-c">
                                    <div className="input-container">
                                        <FaUser />
                                        <input type="text" placeholder="Titular de la tarjeta" defaultValue={card.holder}
                                            onChange={(e) => { setCard({ ...card, holder: e.target.value }) }} />
                                    </div>
                                </div>
                                <div className="row-c">
                                    <div className="input-container">
                                        <FaCreditCard />
                                        <input type="text" placeholder="Número de la tarjeta" defaultValue={card.number}
                                            onChange={(e) => { setCard({ ...card, number: e.target.value }) }} />
                                    </div>
                                </div>
                                <div className="row-c">
                                    <div className="input-container">
                                        <FaCalendar />
                                        <input type="text" placeholder="Mes de vencimiento (MM)" defaultValue={card.exp_month}
                                            onChange={(e) => { setCard({ ...card, exp_month: e.target.value }) }} />
                                    </div>
                                    <div className="input-container">
                                        <FaCalendar />
                                        <input type="text" placeholder="Año de vencimiento (AA)" defaultValue={card.exp_year}
                                            onChange={(e) => { setCard({ ...card, exp_year: e.target.value }) }} />
                                    </div>
                                </div>
                                <div className="row-c">
                                    <div className="input-container custom">
                                        <FaKey />
                                        <input type="password" placeholder="CVC" defaultValue={card.cvc}
                                            onChange={(e) => { setCard({ ...card, cvc: e.target.value }) }} />
                                    </div>
                                </div>
                            </div>
                            {/* Security info */}
                            <div className="security-container">
                                <h4>¿CÓMO PROTEGEMOS TU INFORMACIÓN?</h4>
                                <p>Academia de Cecy procesa sus pagos de forma 100% segura respaldada por instituciones
                                    bancarias y pasarelas digitales
                                </p>
                            </div>
                        </div>
                        <button onClick={addCard}>Guardar</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
