import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey:            "AIzaSyCiRG2Z-7SMoZphzZuV3ANq8cdT9dCYv8M",
  authDomain:        "cien-potencia-bd693.firebaseapp.com",
  projectId:         "cien-potencia-bd693",
  storageBucket:     "cien-potencia-bd693.appspot.com",
  messagingSenderId: "1092658098829",
  appId:             "1:1092658098829:web:22850e9c64119eef7a68c4",
  measurementId:     "G-1DSKE5LV0J",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const functions = getFunctions(app);
