import { Outlet } from "react-router-dom";
import { Footer } from "../components/footer/Footer";
import { Navbar } from "../components/navbar/Navbar";
import "./MainLayout.scss";
import Modals from "../components/modals/Modals";

export function MainLayout(props: any) {
  return (
    <div className="main-layout">
      <Navbar />
      <Outlet />
      {/* loading */}
      <Modals />
      <Footer />
    </div>
  );
}
