import style from "./Footer.module.scss";

export function Footer() {
    return (
        <footer className={ style.footer } >
            <img src="/assets/logos/logo-color.svg" />
            <p>Aprende con Cecy© 2024 Todos los Derechos Reservados MX</p>
            <p>Servicio al cliente +52 81 3572 8156</p>
        </footer>
    )
}